import { Link } from '@tanstack/react-router';
import { _createBaseButtonClasses as createBaseButtonClasses } from 'components/Buttons/_BaseButton/createClasses';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import RequestLoader from 'components/Loaders/Request';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private Component that renders a button with a variant
 */
const _BaseButton: React.FC<BaseButtonNamespace.Props> = ({
    variant = 'SolidButton',
    to,
    type,
    label,
    children,
    onClick,
    className,
    size,
    color,
    href,
    target,
    download,
    onMouseOver,
    isLoading = false,
    disabled = false,
    width = 'full',
    shape,
    style
}) => {
    /***** RENDER HELPERS *****/
    const isDisabled = isLoading ? isLoading : disabled;
    const buttonClassNames = createBaseButtonClasses({
        className,
        color,
        disabled,
        isLoading,
        shape,
        size,
        variant,
        width
    });

    const content = isLoading ? <RequestLoader width={40} height={40} /> : label ?? children;

    const baseButtonProps = {
        className: buttonClassNames,
        onMouseOver,
        onFocus: onMouseOver,
        disabled: isDisabled,
        style
    };

    /***** RENDER *****/
    if (type) {
        switch (type) {
            case 'submit':
                return (
                    <button onClick={onClick} type={type} {...baseButtonProps}>
                        {content}
                    </button>
                );

            case 'onClick':
                return (
                    <button type="button" onClick={onClick} {...baseButtonProps}>
                        {content}
                    </button>
                );

            case 'anchor': {
                const conditionalProps = {};
                if (target) conditionalProps.target = target;
                if (download) conditionalProps.download = download;

                return (
                    <a href={href} {...conditionalProps} {...baseButtonProps}>
                        {content}
                    </a>
                );
            }
            default:
                return (
                    <button {...baseButtonProps} style={style}>
                        {content}
                    </button>
                );
        }
    }

    if (to) {
        return (
            <Link to={to} onClick={onClick} {...baseButtonProps}>
                {content}
            </Link>
        );
    }

    return (
        <button className={buttonClassNames} onClick={onClick} disabled={isDisabled} style={style}>
            {content}
        </button>
    );
};

export const BaseButton = Object.assign(_BaseButton, {
    createClasses: createBaseButtonClasses
});
