import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { katanaDataUtils } from 'containers/katana/queries/methods/dataUtils';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    serviceID: KatanaNamespace.ServiceID;
    sectionDefinitionID?: KatanaNamespace.SectionDefinitionID;
};

type MutationFnParams = {
    sectionID: KatanaNamespace.SectionID;
    attributes: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ModifiedTypes.PartialData;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation({ serviceID, sectionDefinitionID }: Params) {
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, sectionDefinitionID });

    function mutationFn({ sectionID, attributes }: MutationFnParams) {
        const updatedAttributes = handleModifyEntireAttributeObject(attributes);
        const hasName = katanaDataUtils.section.hasName(updatedAttributes);
        const hasProperties = katanaDataUtils.section.hasProperties(updatedAttributes);

        if (hasName || hasProperties) {
            sections.optimistic.setWith(serviceID, 'data', (sections) => {
                for (const section of sections) {
                    if (section.id === sectionID) {
                        if (hasName) {
                            section.name = updatedAttributes.name;
                        }
                        if (hasProperties) {
                            section.properties = updatedAttributes.properties;
                        }
                        break;
                    }
                }

                return sections;
            });
        }

        return KATANA_API.katana.site.service_id.section.section_id.PUT({ serviceID, sectionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn,
        onError: handleDefaultErrorNotification,
        onSuccess: (response) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
        },
        onSettled: (data, error, params) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            const pageID = section.getQueryData({ serviceID, sectionID: params.sectionID })?.data?.site_page_id;
            if (!pageID) return;

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID }),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections.ID({ serviceID, pageID, sectionID: params.sectionID }),
                exact: true
            });
        }
    });
}

export const updateSection = Object.freeze({
    useMutation: _useMutation
});
