/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxTabs.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxTabs = React.FC<NXBoxTabsProps>;
type NXBoxTabsProps = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXBoxTabs: NXBoxTabs = ({ children }) => {
    /***** RENDER *****/
    return (
        <div className="NXBoxTabs" role="tablist">
            {children}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
