import { useMutation } from '@tanstack/react-query';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { pageSections } from 'containers/katana/queries/serviceID/pageSections';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import _ from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.POST.Params;
type BaseParams = Pick<Params, 'serviceID' | 'pageID' | 'sectionDefinitionID'>;

type MutationParams = Pick<Params, 'attributes'>;

function createMutationKey(params: BaseParams) {
    return katanaQueryKeys.katana.service.ID.pages.ID.sections.add(params);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(params: BaseParams) {
    const { serviceID, sectionDefinitionID, pageID } = params;

    /***** HOOKS *****/
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, sectionDefinitionID });

    /***** FUNCTIONS *****/
    function mutationFn({ attributes }: MutationParams) {
        const updatedAttributes = handleModifyEntireAttributeObject(attributes);
        return KATANA_API.katana.site.service_id.pages.page_id.sections.POST({ ...params, attributes: updatedAttributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationKey: createMutationKey(params),
        mutationFn,
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);

            service.invalidateQueries(serviceID);

            if (!_.has(response, 'data')) return;

            const correctResponse = section.createDataObject(response, {
                meta: {
                    media_attachments: []
                }
            });

            newSectionTrackerData.addNewID(correctResponse.data.id);

            const sectionID = correctResponse.data.id;

            section.setQueryData({ serviceID, sectionID }, () => correctResponse);
            pageSections.optimistic.concat({ serviceID, pageID }, 'data', [{ ...correctResponse.data }]);
            sections.optimistic.concat(serviceID, 'data', [{ ...correctResponse.data }]);
            service.optimistic.concat(serviceID, 'data.sections', [{ ...correctResponse.data }]);
        }
    });
}

export const addPageSection = Object.freeze({
    useMutation: _useMutation
});
