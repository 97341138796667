import { zodResolver } from '@hookform/resolvers/zod';
import card from 'assets/images/cvv_shot.png';
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { schema } from 'containers/billing/forms/creditCardForm/consts';
import { CreditCardExpiryInput } from 'containers/billing/forms/creditCardForm/CreditCardExpiryInput';
import { CreditCardNumberInput } from 'containers/billing/forms/creditCardForm/CreditCardNumberInput';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import type { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const { CARD_CVV, CARD_HOLDER_NAME, CARD_MONTH, CARD_NUMBER, CARD_YEAR } = CREDIT_CARD_FIELD_DATA;

type NullableTypes = {
    [CARD_MONTH.name]: z.ZodNumber | null;
    [CARD_YEAR.name]: z.ZodNumber | null;
    [CARD_CVV.name]: z.ZodEffects<z.ZodNumber, number, number> | null;
};

type CreditCardFormSchema = Omit<z.infer<typeof schema>, typeof CARD_MONTH.name | typeof CARD_YEAR.name | typeof CARD_CVV.name> & NullableTypes;

type NXCreditCardFormProps = React.FC<{
    onSubmit: (data: CreditCardFormSchema) => void;
    isPending?: boolean;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXCreditCardForm: NXCreditCardFormProps = ({ onSubmit, isPending = false }) => {
    /***** HOOKS *****/
    const methods = useForm({
        defaultValues: {
            [CARD_HOLDER_NAME.name]: '',
            [CARD_NUMBER.name]: '',
            [CARD_MONTH.name]: null,
            [CARD_YEAR.name]: null,
            [CARD_CVV.name]: null
        },
        resolver: zodResolver(schema),
        reValidateMode: 'onChange'
    });

    /***** RENDER *****/
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="ConfirmDefaultPaymentForm">
                <Input.HookForm
                    label="Card Holder Name"
                    name={CARD_HOLDER_NAME.name}
                    intrinsicProps={{
                        autoComplete: CARD_HOLDER_NAME.autoComplete
                    }}
                />
                <CreditCardNumberInput />
                <Padding bottom={2}>
                    <Grid columns="1fr 1fr" alignItems--start>
                        <CreditCardExpiryInput />
                        <Input.HookForm
                            label={
                                <Flex fullWidth gap={0}>
                                    <Text>CVV</Text>
                                    <Tooltip info={<img width={150} src={card} alt="cvv card" />} />
                                </Flex>
                            }
                            name={CARD_CVV.name}
                            intrinsicProps={{
                                autoComplete: CARD_CVV.autoComplete
                            }}
                        />
                    </Grid>
                </Padding>
                <HookFormButton loading={isPending}>Confirm Default Payment</HookFormButton>
            </form>
        </FormProvider>
    );
};
