import { _NXBoxBottom } from 'components/NXBox/Bottom';
import { NXBoxBottomColumns } from 'components/NXBox/Bottom/Columns';
import NXBoxDefaultPadding from 'components/NXBox/DefaultPadding';
import { NXBoxDescription } from 'components/NXBox/Description';
import { NXBoxDropdown } from 'components/NXBox/Dropdown';
import NXBoxFooter from 'components/NXBox/Footer';
import { NXBoxInfoPair } from 'components/NXBox/InfoPair';
import { NXBoxOverview } from 'components/NXBox/Overview';
import { NXBoxOverviewRow } from 'components/NXBox/Overview/Row';
import _NXBoxRefreshableError from 'components/NXBox/RefreshableError';
import { NXBoxSplit } from 'components/NXBox/Split';
import _NXBoxSubHeading from 'components/NXBox/SubHeading';
import { NXBoxTabs } from 'components/NXBox/Tabs';
import { _NXBoxTab } from 'components/NXBox/Tabs/Tab';
import { _NXBoxTabContent } from 'components/NXBox/Tabs/TabContent';
import { _NXBoxTabContentContainer } from 'components/NXBox/Tabs/TabsContentContainer';
import { _NXBoxTabManager } from 'components/NXBox/Tabs/TabsManager';
import { NXBoxTitle } from 'components/NXBox/Title';
import { NXBoxToolTip } from 'components/NXBox/ToolTip';
import NXBoxTop from 'components/NXBox/Top';
import { _NXBoxTopAccordion } from 'components/NXBox/TopAccordion';
import _NXBox from 'components/NXBox/_NXBox';

const NXBox = Object.assign(_NXBox, {
    Bottom: _NXBoxBottom,
    BottomColumns: NXBoxBottomColumns,
    DefaultPadding: NXBoxDefaultPadding,
    Description: NXBoxDescription,
    Dropdown: NXBoxDropdown,
    SubHeading: _NXBoxSubHeading,
    Footer: NXBoxFooter,
    InfoPair: NXBoxInfoPair,
    Overview: NXBoxOverview,
    OverviewRow: NXBoxOverviewRow,
    Split: NXBoxSplit,
    Tabs: Object.assign(NXBoxTabs, {
        Manager: _NXBoxTabManager,
        Tab: _NXBoxTab,
        ContentContainer: _NXBoxTabContentContainer,
        Content: _NXBoxTabContent
    }),
    Title: NXBoxTitle,
    TopAccordion: _NXBoxTopAccordion,
    Top: NXBoxTop,
    ToolTip: NXBoxToolTip,
    RefreshableError: _NXBoxRefreshableError
});

export default NXBox;
