import { configureDNSMode } from 'containers/domain/queries/domainID/configureDNSMode';
import { getDNSPresetPreview } from 'containers/domain/queries/domainID/getDNSPresetPreview';
import { getDomainInfo } from 'containers/domain/queries/domainID/getDomainInfo';
import { domainsList } from 'containers/domain/queries/getDomainsList';
import { domainsSearch } from 'containers/domain/queries/searchDomainsList';

export const domainQuery = Object.freeze({
    domainsList,
    domainsSearch,
    domainID: {
        getDomainInfo,
        getDNSPresetPreview,
        configureDNSMode
    }
});
