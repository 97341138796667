import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { useComponentDebugTrackerCount, useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { NavigationItemsPage } from 'containers/katana/containers/contentEditorModules/navigationLabels/NavigationItemsPage';
import { SortableNavigationItemsList } from 'containers/katana/containers/contentEditorModules/navigationLabels/SortableNavigationItemsList';
import type { NavigationLabelsNamespace } from 'containers/katana/containers/contentEditorModules/navigationLabels/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import _ from 'lodash';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import type { KatanaAPI } from 'utilities/api/katana/types';

const NavigationItemsDevButtons = lazy(() =>
    import('containers/katana/containers/contentEditorModules/navigationLabels/NavigationItemsDevButtons').then(({ NavigationItemsDevButtons }) => ({
        default: NavigationItemsDevButtons
    }))
);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationItemsManager = () => {
    /***** STATE *****/
    const [sortableNavigationItems, setSortableNavigationItems] = useState<NavigationLabelsNamespace.SortableEntry[]>([]);
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { field } = useController<{ navigation_items: KatanaAPI.Katana.ServiceID.Navigation.Items.POST.NavigationItem[] }, 'navigation_items'>({
        name: 'navigation_items'
    });

    /***** QUERIES *****/
    const { data: pages_data } = katanaQuery.serviceID.pages.useQuery(katanaServiceId);
    const { data: sections_data } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);

    useComponentDebugTrackerCount('NAVIGATION_ITEMS_MANAGER');
    const { value: isEnabled } = useSuperUserActionKey('NAVIGATION_ITEMS_MANAGER');

    /***** EFFECTS *****/
    useEffect(() => {
        const formattedNavigationItems = sortableNavigationItems.map((item, index) => {
            const isPage = 'path' in item;
            if (isPage) {
                return {
                    targetable_type: 'site_page',
                    targetable_id: item.id,
                    order: index
                };
            }

            return {
                targetable_type: 'site_section',
                targetable_id: item.id,
                order: index
            };
        });

        field.onChange(formattedNavigationItems);
    }, [sortableNavigationItems]);

    useEffect(() => {
        if (field.value?.length) {
            const formattedNavigationItems = field.value
                .map((item) => {
                    const isPage = item.targetable_type === 'site_page';
                    if (isPage) {
                        return pages_data?.find((page) => page.id === item.targetable_id);
                    }

                    return sections_data?.find((section) => section.id === item.targetable_id);
                })
                .filter((v) => !!v);

            setSortableNavigationItems(formattedNavigationItems);
        }
    }, []);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Menu items" description="Enable your website visitors to navigate to different sections of your website.">
            <NXBox.DefaultPadding>
                <Flex direction="column">
                    <SortableNavigationItemsList navigationItems={sortableNavigationItems} setNavigationItems={setSortableNavigationItems} />

                    <Text semiBold>Your Pages and Sections:</Text>
                    {_.map(pages_data, (page) => {
                        return (
                            <NavigationItemsPage
                                key={page.id}
                                page={page}
                                onAdd={(entry) => {
                                    setSortableNavigationItems([...sortableNavigationItems, entry]);
                                }}
                                navigationItems={sortableNavigationItems}
                            />
                        );
                    })}

                    {isEnabled && (
                        <Suspense fallback={<RequestLoader />}>
                            <NavigationItemsDevButtons setSortableNavigationItems={setSortableNavigationItems} />
                        </Suspense>
                    )}
                </Flex>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
