import { useIsMutating, useMutation } from '@tanstack/react-query';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { page } from 'containers/katana/queries/serviceID/page';
import { pages } from 'containers/katana/queries/serviceID/pages';
import _ from 'lodash';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.PUT.Params;

function createMutationKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.pages.ID.update(params);
}

function _useIsMutating(params: Params) {
    useIsMutating({ mutationKey: createMutationKey(params) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    /***** FUNCTIONS *****/
    function handleMutation(params: Params) {
        return KATANA_API.katana.site.service_id.pages.page_id.PUT(params);
    }

    function onMutate({ pageID, serviceID, attributes }: Params) {
        const { restore: restorePageMerge } = page.optimistic.merge({ pageID, serviceID }, 'data', attributes);
        const { restore: restorePagesMerge } = pages.optimistic.setWith(serviceID, 'data', (value) => {
            return value.map((page) => {
                if (page.id === pageID) {
                    return _.merge(page, attributes);
                }
                return page;
            });
        });

        newPageTrackerData.removeID(pageID);

        return {
            restore: () => {
                restorePageMerge();
                restorePagesMerge();
            }
        };
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        onMutate,
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: (e, variables, context) => {
            context?.restore();
            handleDefaultErrorNotification(e);
        },

        onSettled: (data, error, { serviceID, pageID }) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID({ serviceID, pageID }),
                exact: true
            });
        }
    });
}

export const updatePage = Object.freeze({
    useMutation: _useMutation,
    useIsMutating: _useIsMutating
});
