/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import vipsitesBannerLogo from 'assets/images/katana/vipsiteslogo.svg';
import './_softlockVIPSitesBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SoftlockVIPSiteBanner = () => {
    /***** HOOKS *****/
    const isTabletOrMobile = useAppViewport(['md', 'sm', 'xs']);

    /***** QUERIES *****/
    const { data: trialDomains } = katanaQuery.getTrialDomains.useQuery();

    /***** FUNCTIONS *****/
    const handleSetupClick = () => {
        katanaQuery.getTrialDomains.optimisticallyRemoveFirstDomian();
    };

    /***** RENDER HELPERS *****/
    const isDisabled = useMemo(() => !trialDomains?.length, [trialDomains]);

    /***** RENDER *****/
    return (
        <Flex
            className="softlockVIPSitesBanner__container"
            direction={isTabletOrMobile ? 'column' : 'row'}
            justify={isTabletOrMobile ? 'center' : 'between'}
            items="center"
            gap={10}
        >
            <img src={vipsitesBannerLogo} alt="Katana Logo" />
            <Flex direction="column" gap={1} className="softlockVIPSitesBanner__textContainer">
                <Text white size--xxxl bold className="softlockVIPSitesBanner__heading">
                    Finish Your Website Setup
                </Text>
                <Text white size--xl className="softlockVIPSitesBanner__text">
                    Complete the setup now to publish your website online.
                </Text>
            </Flex>
            <SolidButton
                className="softlockVIPSitesBanner__button"
                color="white"
                to={`/my-services/vip-sites/trial/${trialDomains?.[0]}`}
                onClick={handleSetupClick}
                disabled={isDisabled}
            >
                <Text black medium>
                    Setup
                </Text>
            </SolidButton>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
