import content from 'assets/images/promo/summerOfDiscounts/content.png';
import logo from 'assets/images/promo/summerOfDiscounts/logo.png';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_summerOfDiscountsBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SummerOfDiscountsBanner = () => {
    /***** HOOKS *****/
    const isDesktop = useAppViewport(['lg', 'xl']);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Flex direction={isDesktop ? 'row' : 'column'} justify="between" items="center" className="summerOfDiscountsBanner">
            <img alt="Summer of Discounts Logo" src={logo} className="summerOfDiscountsBanner__logo" />
            <Flex
                direction={isMobile ? 'column' : 'row'}
                fullHeight
                gap={isMobile ? 0 : undefined}
                justify="between"
                items="center"
                className="summerOfDiscountsBanner__section"
            >
                <img alt="Up to 75% off SITEWIDE" src={content} className="summerOfDiscountsBanner__content" />
                <Flex direction={isMobile ? 'column' : 'row'} justify="end" className="summerOfDiscountsBanner__rightSection">
                    <SolidButton
                        type="anchor"
                        target="_blank"
                        href="https://ventraip.com.au/blog/summer-sale-2025/?utm_source=vipcontrol&utm_medium=banner&utm_campaign=sod2025#article-render"
                        className="summerOfDiscountsBanner__button"
                    >
                        Learn More
                    </SolidButton>
                    <Text className="summerOfDiscountsBanner__terms">{`*T's & C's Apply`}</Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const SummerOfDiscountsBanner = Object.assign(_SummerOfDiscountsBanner, {
    DISMISS_KEY: promoBannerDismissKeys.SUMMER_OF_DISCOUNTS_DISMISS_KEY
});
