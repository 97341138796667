import { queryOptions, useQuery } from '@tanstack/react-query';
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import type { KatanaNamespace } from 'containers/katana/types';
import { has, keys } from 'lodash';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createUseQuerySelectorMethod } from 'utilities/methods/tanstack/createUseQuerySelectorMethod';
import type { NXUtils } from 'utilities/NXUtils';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.meta.sections.GET>>;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.meta.sections();
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: Infinity,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceID, TData>(createQueryKey);

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function useIsFetching(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

function ensureQueryData(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.ensureQueryData(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets section definitions for katana site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}

const useQuerySelector = createUseQuerySelectorMethod<KatanaNamespace.ServiceID, TData, typeof createQueryOptions>(createQueryOptions);

/**
 * Gets section definitions by their route key for katana site
 */
function useByRouteKeyQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery({
        ...createQueryOptions(serviceID),
        select: (response) => {
            if (response.status !== 200) {
                return [];
            }

            const get_katana_section_definitions = response.data;
            type ReduceResult = Record<string, NXUtils.ValueOf<typeof get_katana_section_definitions>>;

            const definitionKeys = keys(get_katana_section_definitions) as Array<keyof typeof get_katana_section_definitions>;

            return definitionKeys.reduce<ReduceResult>((acc, sectionDefinitionKey) => {
                const sectionDefinition = get_katana_section_definitions[sectionDefinitionKey];
                const routeKey = getSectionNameRouteKey(sectionDefinition.name);
                acc[routeKey] = sectionDefinition;
                return acc;
            }, {});
        }
    });
}

/**
 * Gets section definitions ordered
 */
function useOrderedQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery({
        ...createQueryOptions(serviceID),
        select: (response) => {
            if (response.status !== 200) {
                return response;
            }

            return sortSectionIntoCorrectOrder(Object.values(response.data));
        }
    });
}

function useDefinitionIDbyRouteKey(serviceID: KatanaNamespace.ServiceID, routeKey: string) {
    const { data: get_katana_section_definitions_by_route_key_data } = useByRouteKeyQuery(serviceID);

    return get_katana_section_definitions_by_route_key_data?.[routeKey]?.id ?? null;
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * Method to get the route key based on the definition ID using existing query data.
 *
 * Does not fetch new data.
 */
function getRouteKeyByDefinitionID(serviceID: KatanaNamespace.ServiceID, definitionID: KatanaNamespace.SectionDefinitionID) {
    const queryData = getQueryData(serviceID);
    if (!queryData || queryData.status !== 200) {
        return '';
    }

    const definitionData = queryData.data;
    if (!has(definitionData, definitionID)) {
        return '';
    }

    return getSectionNameRouteKey(definitionData[definitionID].name);
}

export const sectionDefinitions = Object.freeze({
    useQuery: _useQuery,
    useQuerySelector,
    useByRouteKeyQuery,
    useOrderedQuery,
    useDefinitionIDbyRouteKey,
    useIsFetching,
    getRouteKeyByDefinitionID,
    getQueryData,
    prefetchQuery,
    createQueryKey,
    createQueryOptions,
    ensureQueryData
});
