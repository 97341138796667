import NXBox from 'components/NXBox';
import { Padding } from 'components/Utils/Padding';
import { RenderForBrands } from 'config/brandRenderer/component';
import { serviceNav } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { getVPSList } from 'containers/services/action';
import DomainTable from 'containers/services/modules/domains';
import EmailHostingTable from 'containers/services/modules/email';
import GSuiteTable from 'containers/services/modules/gsuite';
import HostingTable from 'containers/services/modules/hosting';
import KatanaServiceListTable from 'containers/services/modules/katanaServicesList/katanaServiceListTable';
import { Microsoft365Table } from 'containers/services/modules/ms365';
import { SSLTable } from 'containers/services/modules/ssl';
import { VPSTable } from 'containers/services/modules/vps';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isRouteActive } from 'router/utils/isRouteActive';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { ExpressServiceManagerPurchaseLink } from './purchaseLink';
import { ExpressServiceManagerTabs } from './serviceTabs';

/**
 * @typedef {import("utilities/types").NXUtils.ValueOf<typeof serviceNav>['key']} ServiceNavKey
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ExpressServiceManager = () => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    const isMobile = useAppViewport(['xs', 'sm']);

    const vps_list_meta = useSelector((state) => state.services.vps_list_meta);

    /***** STATE *****/
    const [view, setView] = useState(/** @type {ServiceNavKey} */ (serviceNav['Domain Names'].key));

    /***** EFFECTS *****/
    useEffect(() => {
        // For Intaserve, fetch VPS list if it hasn't been fetched already. So that we can determine whether or not to show the VPS tab
        if (isRouteActive({ path: '/my-services/vps', params: {} }) && activeBrand === 'intaserve' && !vps_list_meta) {
            getVPSList();
        }
    }, []);

    /***** RENDER HELPERS *****/
    const tables = {
        [serviceNav['cPanel Hosting'].key]: <HostingTable isExpressServiceManager />,
        [serviceNav['Email Hosting'].key]: <EmailHostingTable isExpressServiceManager />,
        [serviceNav['Google Workspace'].key]: <GSuiteTable isExpressServiceManager />,
        [serviceNav['VPS'].key]: <VPSTable isExpressServiceManager />,
        [serviceNav['SSL Certificates'].key]: <SSLTable isExpressServiceManager />,
        [serviceNav['Microsoft 365'].key]: <Microsoft365Table isExpressServiceManager />,
        [serviceNav['VIPsites'].key]: <KatanaServiceListTable isExpressServiceManager />,
        [serviceNav['Domain Names'].key]: <DomainTable isExpressServiceManager />
    };

    const paddingProps = /** @satisfies {Record<string, Omit<PaddingProps, 'children'>>} */ ({
        outer: {
            x: isMobile ? 0 : 6,
            y: isMobile ? 0 : 5
        },
        title: {
            bottom: isMobile ? 0 : 3,
            xy: isMobile ? 5 : 0
        }
    });

    /***** RENDER *****/
    return (
        <div className="dashboardExpressServiceManager">
            <NXBox>
                {/* Header */}
                <Padding {...paddingProps.outer}>
                    <Padding className="dashboardExpressServiceManager__titleRow" {...paddingProps.title}>
                        <NXBox.Title title="Express Service Manager" />
                        <RenderForBrands brands={['ventra']}>
                            <ExpressServiceManagerPurchaseLink view={view} />
                        </RenderForBrands>
                    </Padding>
                    <ExpressServiceManagerTabs view={view} setView={setView} />
                </Padding>

                {/* Render Tables */}
                {tables[view] ?? tables[serviceNav['Domain Names'].key]}
            </NXBox>
        </div>
    );
};
