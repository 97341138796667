import { useMutation } from '@tanstack/react-query';
import { domainQuery } from 'containers/domain/queries/queryTree';
import { launchSiteDNSModePropagationTracker } from 'containers/katana/components/launchWebsite/consts';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { API } from 'utilities/api/domain';
import type { DomainAPI } from 'utilities/api/domain/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    function mutationFn({ id, attributes }: DomainAPI.Domain.DomainID.DNS.Put.Params) {
        return API.domain.PUT.configuration(id, attributes);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onError: (e, { id }) => {
            launchSiteDNSModePropagationTracker.setState((state) => state.filter((_id) => _id !== id));
            console.log('e', e);
            handleDefaultErrorNotification(e);
        },
        onSuccess: (data, { id }) => {
            launchSiteDNSModePropagationTracker.setState((current) => [...current, id]);
            if (page !== 'setup') {
                handleDefaultSuccessNotification(data);
            }
        },
        onSettled: (data, error, { id }) => {
            katanaQuery.serviceID.domainServiceDetails.resetQueriesByDomainID(id);
            domainQuery.domainID.getDNSPresetPreview.resetQueries({ id, preset: 'katana' });
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const configureDNSMode = Object.freeze({
    useMutation: _useMutation
});
