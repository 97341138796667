/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import SimpleTable from 'components/SimpleTable';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/
import { renderButton, renderCheckboxField, requiredAcceptedValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { brandStore, useBrandStore } from 'config/hooks/useBrandStore';

/**********************************************************************************************************
 *   CONSTS - JSX
 **********************************************************************************************************/
const renderDialogueNotification = (closeServiceUnsuspendLightbox, service_unsuspension_reason_error) => (
    <>
        <DialogNotification tail={{ pos: 'top' }} outline={false} type="warning">
            {service_unsuspension_reason_error?.code === 'ERR_UNSUSPEND_BLOCKED' && service_unsuspension_reason_error?.errors?.[0]?.details ? (
                <>
                    PLEASE NOTE: You cannot unsuspend this service at this time. Please&nbsp;
                    <Anchor to="/support/tickets/submit">submit an eTicket</Anchor>&nbsp; through {brandStore.state.application} and our team will be
                    happy to assist.
                </>
            ) : (
                <>
                    PLEASE NOTE: You have exceeded the number of times you can unsuspend this service. Please&nbsp;
                    <Anchor to="/support/tickets/submit">submit an eTicket</Anchor>
                    <br />
                    &nbsp; through {brandStore.state.application} and our team will be happy to assist.
                </>
            )}
        </DialogNotification>

        <Anchor onClick={closeServiceUnsuspendLightbox} color="primary" className="unsuspendReturn__button">
            Go Back
        </Anchor>
    </>
);

const unsuspensionReasonRequestParser = (service_unsuspension_reason_data, service_unsuspension_reason_error, service_unsuspension_reason_status) => {
    let requestData;

    if (service_unsuspension_reason_error && service_unsuspension_reason_status === 'error') {
        requestData = service_unsuspension_reason_error;
    } else if (service_unsuspension_reason_data && service_unsuspension_reason_status === 'success') {
        requestData = service_unsuspension_reason_data;
    }

    return Object.prototype.hasOwnProperty.call(requestData, 'data') ? requestData.data : requestData;
};

/**********************************************************************************************************
 * COMPONENT START
 **********************************************************************************************************/
let UnsuspensionForm = (props) => {
    const { application, company } = useBrandStore();

    const {
        service_unsuspension_reason_data,
        service_unsuspension_reason_error,
        service_unsuspension_reason_status,
        closeServiceUnsuspendLightbox,
        primary_domain,
        service_name,
        handleSubmit,

        pristine,
        submitting,
        valid
    } = props;

    if (
        service_unsuspension_reason_error &&
        Object.prototype.hasOwnProperty.call(service_unsuspension_reason_error, 'code') &&
        service_unsuspension_reason_error.code === 'ERR_SERVICE_NOT_FOUND'
    ) {
        return (
            <div className="UnsuspensionForm">
                <p>Unfortunately, this service with us has been suspended.</p>

                {renderDialogueNotification(closeServiceUnsuspendLightbox)}
            </div>
        );
    }

    const { instructions, reason } = unsuspensionReasonRequestParser(
        service_unsuspension_reason_data,
        service_unsuspension_reason_error,
        service_unsuspension_reason_status
    );

    const unsuspendValidError = service_unsuspension_reason_error
        ? ['ERR_UNSUSPEND_BLOCKED', 'ERR_UNSUSPEND_MAX_LIMIT_REACHED'].includes(service_unsuspension_reason_error.code)
        : null;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="UnsuspensionForm">
            <p>
                Unfortunately, this service with us has been suspended. <br />
                The details of the suspension are below:
            </p>

            <SimpleTable
                rows={[
                    [<h5 key="PRIMARY_DOMAIN">PRIMARY DOMAIN</h5>, <p key={primary_domain}>{primary_domain}</p>],
                    [<h5 key="PLAN_SERVICE">PLAN/SERVICE</h5>, <p key={service_name}>{service_name}</p>],
                    [<h5 key="SUSPENSION_REASON">SUSPENSION REASON</h5>, <p key="reason">{reason}</p>],
                    [<h5 key="SUSPENSION_INFORMATION">SUSPENSION INFORMATION</h5>, <p key="instruction">{instructions}</p>],
                    [
                        <p key="staff_name" className="ventra-text--primary text--center__important">
                            This suspension information was supplied by the {company} support team.
                        </p>
                    ]
                ]}
            />

            <p className="additional-support-copy">
                If you need any further information or assistance, please &nbsp;
                <Anchor to="/support/tickets/submit" className="submitTicket__button">
                    Submit an eTicket
                </Anchor>
                <br />
                &nbsp; through {application} and our team will be happy to assist.
            </p>

            {service_unsuspension_reason_error && service_unsuspension_reason_error.status === 403 && unsuspendValidError ? (
                <div className="unsuspensionActionForm">
                    {renderButton(pristine, true, false, 'Unsuspend Service', 'primary')}

                    {renderDialogueNotification(closeServiceUnsuspendLightbox, service_unsuspension_reason_error)}
                </div>
            ) : (
                <form className="unsuspensionActionForm" onSubmit={handleSubmit}>
                    <div className="unsuspensionActionForm__container">
                        <h5 className="confirmation__heading">Confirmation</h5>

                        <div className="unsuspensionActionForm__input">
                            <Field
                                name="fixConfirm"
                                label="I confirm that I am aware of the issue mentioned above and will resolve the issue immediately, otherwise the service will be at risk of being suspended again."
                                component={renderCheckboxField}
                                validate={[requiredAcceptedValidation]}
                                type="checkbox"
                                className="form__textfield"
                            />
                        </div>
                    </div>

                    {renderButton(pristine, submitting, valid, 'Unsuspend Service', 'primary')}
                </form>
            )}
        </div>
    );
};
/**********************************************************************************************************
 * COMPONENT END
 **********************************************************************************************************/

UnsuspensionForm = reduxForm({
    form: 'unsuspend'
})(UnsuspensionForm);

const mapStateToProps = (state) => ({
    service_unsuspension_reason_status: state.services.service_unsuspension_reason_status,
    service_unsuspension_reason_data: state.services.service_unsuspension_reason_data,
    service_unsuspension_reason_error: state.services.service_unsuspension_reason_error
});

UnsuspensionForm = connect(mapStateToProps, null)(UnsuspensionForm);

export default UnsuspensionForm;
