/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ShowMore } from '../helpers/showMore';
import { Microsoft365TableBody } from './components/body';
import { Microsoft365Banner } from './components/ms365';
import { Microsoft365TableTitleBar } from './components/titlebar';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { reverseMs365DeleteRequest } from 'components/Cancel/action';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import NXTable from 'components/NXTable';
import { Border } from 'components/Utils/Border';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { ShowAll } from '../helpers/showAll';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { cancelListMS365 } from 'utilities/api/services';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useSearchState } from '../helpers/useSearchState';
import { useServiceLightboxState } from '../helpers/useServiceLightboxState';
import { useSort } from '../helpers/useSort';
import { Microsoft365TableContext, useMicrosoft365TableSelector } from './own';

/*   ACTIONS
 *****************************************************/
import { getMs365List } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Microsoft365Table.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').NMicrosoft365Table.TMicrosoft365Table} TMicrosoft365Table
 * @typedef {import('./types').NMicrosoft365Table.TContext} TContext
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TMicrosoft365Table}
 */
export const Microsoft365Table = ({ isExpressServiceManager = false }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const dispatch = useDispatch();
    const { ms365_list_data, ms365_search_data, ms365_list_status, ms365_search_status, ms365_list_more_status, ms365_list_meta } =
        useMicrosoft365TableSelector((state) => state.services);
    const { service_cancel_status, ms365_reverse_delete_service_status } = useSelector(({ cancel }) => cancel);

    /***** STATE *****/
    const [cancelServiceLightbox, setCancelServiceLightbox] = useServiceLightboxState();
    const [keepServiceLightbox, setKeepServiceLightbox] = useServiceLightboxState();
    const [search, setSearch] = useSearchState(ms365_search_status);
    const [filters, , { handleSort }] = useSort({ disabled: search.isSearching });
    const [numberOfRows, setNumberOfRows] = useState(/** @type {number | null} */ (null));

    /***** FUNCTIONS *****/
    const getErrorMessage = () => {
        const isError = ms365_list_status === 'error' || ms365_search_status === 'error';
        switch (true) {
            case isExpressServiceManager:
                return 'You have no Microsoft 365 subscriptions.';
            case search.isSearching:
                return 'No Microsoft 365 subscriptions matched your search.';
            case isError:
                return 'Something went wrong. Please try again later or contact support.';
            default:
                return "You have no Microsoft 365 subscriptions. Why don't you purchase some below to get started!";
        }
    };

    const getNumberOfRecords = () => {
        switch (true) {
            case isExpressServiceManager:
                return 5;
            case typeof numberOfRows === 'number':
                return numberOfRows;
            default:
                return 10;
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        getMs365List({
            ...filters,
            record_per_page: getNumberOfRecords()
        })(dispatch);

        return cancelListMS365;
    }, [filters, numberOfRows]);

    useEffect(() => {
        if (ms365_reverse_delete_service_status === 'success') {
            setKeepServiceLightbox();
        }
    }, [ms365_reverse_delete_service_status]);

    useEffect(() => {
        if (service_cancel_status === 'success') {
            setCancelServiceLightbox();
        }
    }, [service_cancel_status]);

    /***** RENDER HELPERS *****/
    const listData = (search.isSearching ? ms365_search_data : ms365_list_data) ?? [];
    const isLoading = ms365_list_status === 'loading' || ms365_search_status === 'loading' || ms365_list_more_status === 'loading';
    const isError = ms365_list_status === 'error' || ms365_search_status === 'error' || listData.length === 0;

    const classes = /** @type {const} */ ({
        table: classNames('Microsoft365Table', {
            'Microsoft365Table--express': isExpressServiceManager
        }),
        error: 'Microsoft365Table__errorMessage',
        footer: 'Microsoft365Table__footer'
    });

    const context = /** @type {TContext} */ ({
        isExpressServiceManager,
        numberOfRows,
        search,
        setNumberOfRows,
        setSearch,
        setCancelService: setCancelServiceLightbox,
        setKeepService: setKeepServiceLightbox,
        initialized: true
    });

    /***** RENDER *****/
    return (
        <div className={classes.table}>
            {/* Main Component */}
            <Microsoft365TableContext.Provider value={context}>
                {!isExpressServiceManager && <Microsoft365TableTitleBar title="Manage Microsoft 365" />}

                <WrapWithComponent component={Border} radius="S_border-radius_default" wrap={!isExpressServiceManager} all>
                    {isMobile ? (
                        <NXTable.Body isLoading={isLoading}>
                            {isError ? (
                                <NXTable.ErrorMessage className={classes.error} message={getErrorMessage()} />
                            ) : (
                                <Microsoft365TableBody data={listData} />
                            )}
                        </NXTable.Body>
                    ) : (
                        <NXTable columns="2fr 1fr 1fr NXActions" onSort={handleSort}>
                            <NXTable.Header>
                                <NXTable.Header.SortableTitle disabled={search.isSearching} sortKey="name">
                                    DOMAIN / PLAN
                                </NXTable.Header.SortableTitle>
                                <NXTable.Header.SortableTitle disabled={search.isSearching} sortKey="status">
                                    STATUS
                                </NXTable.Header.SortableTitle>
                                <NXTable.Header.Title>SEATS</NXTable.Header.Title>
                                <NXTable.Header.Title></NXTable.Header.Title>
                            </NXTable.Header>
                            <NXTable.Body isLoading={isLoading}>
                                {isError ? (
                                    <NXTable.ErrorMessage className={classes.error} message={getErrorMessage()} />
                                ) : (
                                    <Microsoft365TableBody data={listData} />
                                )}
                            </NXTable.Body>
                        </NXTable>
                    )}

                    <ShowAll
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={ms365_list_meta?.total_all_records}
                        numberOfRows={numberOfRows}
                        onClick={() => setNumberOfRows(ms365_list_meta?.total_all_records)}
                    />

                    <ShowMore
                        isExpressServiceManager={isExpressServiceManager}
                        isLoading={isLoading}
                        totalRows={ms365_list_meta?.total_all_records}
                        link="/my-services/microsoft-365"
                        title="show all microsoft 365"
                    />
                </WrapWithComponent>
            </Microsoft365TableContext.Provider>

            {/* Lightboxes */}
            {cancelServiceLightbox.show && (
                <OverlayLightbox
                    title="Cancel Service"
                    onOpen={cancelServiceLightbox.show}
                    onClose={() => setCancelServiceLightbox()}
                    cancel={{
                        id: cancelServiceLightbox.id,
                        service: 'ms365',
                        closeAction: () => setCancelServiceLightbox()
                    }}
                    loading={service_cancel_status}
                />
            )}

            {keepServiceLightbox.show && (
                <OverlayLightbox
                    title="Keep Service"
                    onOpen={keepServiceLightbox.show}
                    onClose={() => setKeepServiceLightbox()}
                    loading={isLoading ? 'loading' : 'success'}
                    confirm={{
                        desc: "Are you sure you'd like to cancel this request and keep your service?",
                        buttonText: 'Keep Service',
                        buttonAction: () => reverseMs365DeleteRequest(keepServiceLightbox.id)(dispatch),
                        closeText: 'No, Go Back',
                        closeAction: () => setKeepServiceLightbox(),
                        loading: ms365_reverse_delete_service_status
                    }}
                />
            )}

            {/* Purchase MS365 Banner */}
            {!isExpressServiceManager && <Microsoft365Banner />}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
