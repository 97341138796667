import bryanAdamsTourImg from 'assets/images/promo/tiles/bryanAdamsTour.jpg';
import drakeTourImg from 'assets/images/promo/tiles/drakeTour.jpg';
import Text from 'components/Utils/Text';
import { _OneClickCard as Card } from 'containers/vipRewards/modules/rewardCard/cards/oneClickCard/oneClickCard';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OneClickCards = {
    DrakeTour: () => (
        <Card
            promoId="drake-anita-max-tour-2025"
            tag="GIVEAWAY"
            title="Win tickets to Drake, The Anita Max Win Tour"
            description="Win 2 tickets to Drake's Anita Max Win Tour at Rod Laver Arena, Melbourne, on Sunday, 9 February 2025! Ten lucky winners will enjoy event tickets and box access."
            image={drakeTourImg}
            terms
            modalContent={
                <>
                    <Text align--center secondary>
                        You have successfully entered for your chance to win tickets to Drake, The Anita Max Win Tour.
                    </Text>
                    <Text align--center secondary>
                        Our successful winners will be contacted via phone and email on 30 January 2025.
                    </Text>
                    <Text italic align--center secondary size--s>
                        <Text bold secondary italic size--s>
                            Please Note:{' '}
                        </Text>
                        {`The prize consists of two corporate box tickets to Drake's "The Anita Max Win Tour" at Rod Laver Arena on 9 February 2025. Travel, accommodation, and food are not included.`}
                    </Text>
                </>
            }
        />
    ),
    BryanAdamsTour: () => (
        <Card
            promoId="bryan-adams-so-happy-it-hurts-tour-2025"
            tag="GIVEAWAY"
            title="Win tickets to Bryan Adams, So Happy It Hurts Tour"
            description="Don't miss your chance to see Bryan Adams live! We're giving away 10 pairs of tickets to his So Happy It Hurts Tour at Rod Laver Arena, Melbourne, on 15 February 2025."
            image={bryanAdamsTourImg}
            terms
            modalContent={
                <>
                    <Text align--center secondary>
                        You have successfully entered for your chance to win tickets to Bryan Adams, So Happy It Hurts Tour
                    </Text>
                    <Text align--center secondary>
                        Our successful winners will be contacted via phone and email on 6 February 2025.
                    </Text>
                    <Text italic align--center secondary size--s>
                        <Text bold secondary italic size--s>
                            Please Note:{' '}
                        </Text>
                        {`The prize consists of two corporate box tickets to Bryan Adams' “So Happy It Hurts Tour" at Rod Laver Arena on 15 February 2025. Travel, accommodation, and food are not included.`}
                    </Text>
                </>
            }
        />
    )
};
