/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQueryClient } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { useStore } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AppMounting } from 'App/components/AppMounting';
import { RouterInnerWrapper } from 'components/Router/InnerWrap';
import ToastNotification from 'components/Toast';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { router } from 'router/router';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Router component. This component sites inside Providers, but above the Application and provides global components (toast, alerts, etc) and fills the Router Context.
 */
export const RouterContextProvider = () => {
    /***** DEPENDENCIES *****/
    const queryClient = useQueryClient();
    const store = useStore();

    /***** RENDER *****/
    return (
        <>
            <ToastNotification />
            <AppMounting>
                <RouterProvider InnerWrap={RouterInnerWrapper} router={router} context={{ queryClient, store }} />
            </AppMounting>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
