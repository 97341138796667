import { HookForm } from 'components/Form/HookForm';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { Controller, useFormContext } from 'react-hook-form';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import './_expiryInput.scss';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const cardMonth = CREDIT_CARD_FIELD_DATA.CARD_MONTH;
const cardYear = CREDIT_CARD_FIELD_DATA.CARD_YEAR;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function CreditCardExpiryInput() {
    /***** HOOKS *****/
    const methods = useFormContext();
    const monthMeta = HookForm.getMeta(methods.getFieldState(cardMonth.name));
    const yearMeta = HookForm.getMeta(methods.getFieldState(cardYear.name));

    /***** FUNCTIONS *****/
    function handleMonthChange(e: string) {
        if (e.length === 2) {
            methods.setFocus(cardYear.name);
        }
    }

    function handleYearChange(e: React.ChangeEvent<HTMLInputElement>) {
        const inputEvent = e.nativeEvent;
        if (e.target.value === '' && inputEvent instanceof InputEvent && inputEvent.inputType === 'deleteContentBackward') {
            methods.setFocus(cardMonth.name);
        }
    }

    /***** RENDER *****/
    return (
        <div className="CreditCardExpiryInput">
            <Text secondary size--s>
                Expiry
            </Text>
            <Flex className="CreditCardExpiryInput__input" items="center">
                <Controller
                    control={methods.control}
                    name={cardMonth.name}
                    render={({ field }) => (
                        <input
                            {...field}
                            className={methods.formState.errors[cardMonth.name] ? 'error' : ''}
                            placeholder="MM"
                            autoComplete={cardMonth.autoComplete}
                            onChange={(e) => {
                                const value = e.target.value;
                                field.onChange(value);
                                handleMonthChange(value);
                            }}
                        />
                    )}
                />

                <Text secondary>/</Text>

                <Controller
                    control={methods.control}
                    name={cardYear.name}
                    render={({ field }) => (
                        <input
                            {...field}
                            className={methods.formState.errors[cardYear.name] ? 'error' : ''}
                            placeholder="YY"
                            autoComplete={cardYear.autoComplete}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                                handleYearChange(e);
                            }}
                        />
                    )}
                />
            </Flex>
            <ValidationMessage.HookForm.Default {...monthMeta} />
            <ValidationMessage.HookForm.Default {...yearMeta} />
        </div>
    );
}
