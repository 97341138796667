/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Icons from 'components/Icons';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import AppStore from 'config/containers/promotions/vipcontrolApp/assets/app-store.png';
import PlayStore from 'config/containers/promotions/vipcontrolApp/assets/play-store.png';
import QRCode from 'config/containers/promotions/vipcontrolApp/assets/qr-code.png';
import './_vipcontrolAppNavItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const VipControlAppNavItem = () => {
    /***** HOOKS *****/
    const { application } = useBrandStore();
    const vipcontrolAppRef = useRef();

    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** STATE *****/
    const [showVIPControlAppDropdown, setShowVIPControlAppDropdown] = useState(false);

    // Clickaway
    useClickAway([vipcontrolAppRef], () => setShowVIPControlAppDropdown(false));

    /***** RENDER HELPERS *****/
    const renderDropdownContents = () => {
        return (
            <div className="vipControlAppNavItem__container">
                <Text size--xl semiBold className="vipControlAppNavItem__container--heading">
                    Try our new {application} App
                </Text>
                <Text size--m medium>
                    Secure your account with app based Two-Factor Authentication, check notifications, pay bills and renew your domain names. Have a
                    look and let us know what you think!
                </Text>
                <div className="vipControlAppNavItem__stores">
                    <a className="" href="https://apps.apple.com/us/app/vipcontrol/id6448714430" target="_blank" rel="noreferrer">
                        <img className="vipControlAppNavItem__store" src={AppStore} alt="Download on the App Store" />
                    </a>
                    <a
                        className="vipControlAppNavItem__store"
                        href="https://play.google.com/store/apps/details?id=com.vipcontrol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img className="vipControlAppNavItem__store" src={PlayStore} alt="GET IT ON Google Play" />
                    </a>
                </div>
                <img className="vipControlAppNavItem__qrCode" src={QRCode} alt="QR Code" />
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <button
            type="button"
            ref={vipcontrolAppRef}
            className="vipControlAppNavItem"
            onClick={() => {
                setShowVIPControlAppDropdown(!showVIPControlAppDropdown);
            }}
            onKeyDown={(e) => {
                // Open menu with spacebar
                if (e.key === ' ' && !showVIPControlAppDropdown) {
                    e.preventDefault();
                    setShowVIPControlAppDropdown(!showVIPControlAppDropdown);
                }
                // Close menu with escape key
                if (['Escape', 'Esc'].includes(e.key) && showVIPControlAppDropdown) {
                    e.preventDefault();
                    setShowVIPControlAppDropdown(!showVIPControlAppDropdown);
                }
            }}
        >
            <div className="vipControlAppNavItem__dropdown">
                <Icons.Mobile />
                <div className="vipControlAppNavItem__text">Try our{app_viewport === 'lg' ? <br /> : ' '}new app</div>
            </div>
            <Icons.ChevronDown />
            {showVIPControlAppDropdown ? renderDropdownContents() : ''}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default VipControlAppNavItem;
