/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import katanaPromoGreenLine from 'assets/images/katana/free-promo-green-line.svg';
import katanaPromoDashboardIcon from 'assets/images/katana/katana-promo-dashboard-icon.png';
import katanaPromoSeperatorLine from 'assets/images/katana/katana-promo-seperator-line.svg';
import vipsitesBannerLogo from 'assets/images/katana/vipsiteslogo.svg';

import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import './_promotionalVIPSiteBanner.scss';

const getStartedLink = 'https://vip.ventraip.com.au/my-services/vip-sites';
const learnMoreLink = 'https://ventraip.com.au/website-builder/';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _PromotionalVIPSiteBanner = () => {
    /***** HOOKS *****/
    const isDesktop = useAppViewport(['lg', 'xl']);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <div className="promotionalVIPSiteBanner_gridContainer">
            <div className="promotionalVIPSiteBanner_gridItemA">
                <div className="promotionalVIPSiteBanner_headingContainer">
                    <img src={vipsitesBannerLogo} alt="vipsites banner logo" className="promotionalVIPSiteBanner__logo" />
                    {isDesktop && (
                        <img src={katanaPromoSeperatorLine} alt="heading seperator" className="promotionalVIPSiteBanner__whiteLineSeperator" />
                    )}
                    <section className="promotionalVIPSiteBanner__headingSection">
                        <Text white bold lead--1 align--center className="promotionalVIPSiteBanner__heading">
                            Build your{' '}
                            <Text bold span uppercase>
                                free{' '}
                            </Text>
                            website with <br /> our new website builder.
                        </Text>
                        <img src={katanaPromoGreenLine} alt="Free green underlineline" className="promotionalVIPSiteBanner__greenLineIcon" />
                    </section>
                </div>
                {!isMobile && (
                    <Text white>
                        Create a stunning, professional website in minutes. <br /> No cost, no coding, perfect for anyone getting started online.
                    </Text>
                )}
            </div>
            <div className="promotionalVIPSiteBanner_gridItemB">
                <img src={katanaPromoDashboardIcon} alt="fitness icon" className="promotionalVIPSiteBanner__fitnessImage" />
            </div>
            <div className="promotionalVIPSiteBanner_gridItemC">
                <Flex direction="column" justify="center">
                    <SolidButton type="anchor" href={getStartedLink} className="promotionalVIPSiteBanner__button">
                        <Text size--m bold>
                            Get Started
                        </Text>
                    </SolidButton>
                    <OutlineButton
                        type="anchor"
                        href={learnMoreLink}
                        className="promotionalVIPSiteBanner__button promotionalVIPSiteBanner__button-outlined"
                    >
                        <Text size--m bold>
                            Learn More
                        </Text>
                    </OutlineButton>
                </Flex>{' '}
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const PromotionalVIPSiteBanner = Object.assign(_PromotionalVIPSiteBanner, {
    DISMISS_KEY: promoBannerDismissKeys.PROMOTIONAL_VIPSITES_DISMISS_KEY
});
