import type { LinkComponent } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import { BaseButton } from 'components/Buttons/_BaseButton';
import type { BaseButtonNamespace } from 'components/Buttons/_BaseButton/types';
import { Padding } from 'components/Utils/Padding';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IntrinsicProps = Omit<React.HTMLProps<'a'>, 'children' | 'preload'>;

type Props = Omit<BaseButtonNamespace.ClassProps, 'variant'> & {
    intrinsicProps?: Omit<IntrinsicProps, 'onClick'>;
    onClick?: IntrinsicProps['onClick'];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SolidButtonLink: LinkComponent<React.FC<Props>> = ({
    children,
    className,
    color,
    isLoading,
    disabled,
    size,
    width,
    shape,
    intrinsicProps,
    ...props
}) => {
    /***** RENDER HELPERS *****/
    const classes = classNames(BaseButton.createClasses({ variant: 'SolidButton', color, isLoading, disabled, size, width, shape }), className);

    /***** RENDER *****/
    return (
        <Padding x={4} inject>
            <Link {...props} {...intrinsicProps} className={classes} as="a">
                {children}
            </Link>
        </Padding>
    );
};
