/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FONT_META_TYPES, fontMetaTypeValues } from './consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

type FontStyles = {
    body: string;
    heading: string;
    brand: string;
};
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function getFontFamilyByFontMetaData(fontsMetaData: Artah.Katana.Site.ID.Meta.Fonts.GET._200['data'], fontStyles?: FontStyles) {
    /***** STATE *****/
    const fontFamilyData: Partial<FontStyles> = {};
    fontMetaTypeValues.forEach((fontType) => {
        const selectedFont = fontStyles?.[fontType] ?? '';
        const fontFamily = fontsMetaData?.[selectedFont]?.font_family ?? '';
        fontFamilyData[fontType] = fontFamily;
    });

    return fontFamilyData;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function getSelectedFontsFromFontsMeta(fontsMetaData: Artah.Katana.Site.ID.Meta.Fonts.GET._200['data'], fontStyles: FontStyles) {
    const selectedFonts = Object.values(fontStyles)
        .filter((selectedFontID) => fontsMetaData?.[selectedFontID])
        .map((selectedFontID) => ({
            font: fontsMetaData?.[selectedFontID]?.name ?? ''
        }));

    /***** RENDER *****/
    return selectedFonts;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const mapFontOptions = ({ name, google_fonts_key, font_family }: KatanaAPI.Katana.Site.ServiceID.Meta.Fonts.GET.FontData) => ({
    label: <Text style={{ fontFamily: font_family }}>{name}</Text>,
    value: google_fonts_key
});

export const fontBodyHeading = [FONT_META_TYPES.BODY, FONT_META_TYPES.HEADING];

export function filterBodyOrHeadingFonts(fonts) {
    return fonts.filter(({ types }) => {
        return fontBodyHeading.some((type) => types.includes(type));
    });
}
