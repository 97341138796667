/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getLoggedInAccountData } from 'containers/dashboard/modules/accounts/methods';
import { vipRewardsSignup } from 'containers/vipRewards/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { vipRewardsReadableName } from 'containers/vipRewards/consts';

import './_vipRewardsSignupButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsSignupButton({ onSuccess }) {
    /***** HOOKS *****/
    const rewards_signup_status = useSelector((state) => state.rewards.rewards_signup_status);
    const login_account_list_data = useSelector((state) => state.login.login_account_list_data);
    const app_check_token_data = useSelector((state) => state.app.app_check_token_data);
    const app_user_data = useSelector((state) => state.app.app_user_data);

    /***** RENDER *****/
    if (typeof app_user_data?.role !== 'string' || app_user_data.role.toLowerCase() !== 'account holder') {
        return (
            <>
                <InactiveButton className="vipRewardsSignupButton__nonAccountHolderButton">SIGN UP TODAY</InactiveButton>
                <Text className="vipRewardsSignupButton__nonAccountHolderText" secondary size--s align--center>
                    Only the account holder can sign up for {vipRewardsReadableName}
                </Text>
            </>
        );
    }

    if (rewards_signup_status === 'loading') {
        return (
            <InactiveButton>
                <RequestLoader />
            </InactiveButton>
        );
    }

    return (
        <SolidButton
            type="onClick"
            onClick={() =>
                vipRewardsSignup({
                    onSuccess,
                    accountId: getLoggedInAccountData(login_account_list_data, app_check_token_data)?.id
                })
            }
        >
            SIGN UP TODAY
        </SolidButton>
    );
}

export default VipRewardsSignupButton;
