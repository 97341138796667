/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Header from 'components/Header';
import { LiveChat } from 'components/LiveChat';
import PostLoginChecklist from 'containers/dashboard/Components/postLoginChecklist';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IntaserveUserRouteTemplateComponent = React.FC<{
    outlet: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders User related components when the route is authenticated.
 */
export const IntaserveUserRouteTemplate: IntaserveUserRouteTemplateComponent = ({ outlet }) => {
    /***** HOOKS *****/
    const isLoginRoute = useChildMatchById('/login');

    /***** RENDER *****/
    return (
        <div className="UserLayout">
            {/* Refer to Comment 1. at bottom of file */}
            {!isLoginRoute && <Header />}
            {outlet}
            <LiveChat />
            <PostLoginChecklist />
        </div>
    );
};

/**
 * @fileoverview
 *
 * 1. There is currently the niche situation where you have additional users and are on the login route still after logging in, where it will ask you to select which user to login as.
 *    I found that there was some instances with HMR where the header unreliably rendered on this page at the top of the page. This may be due to some race condition with app_user_data being
 *    Set in the additional user details so I've disabled this based on the route instead to handle this, Most likely, if we refactor the routing and components around the login in the future to
 *    Better handle login and simplify what is happening, then we can likely solve this at that time.
 */
