import type { AllTokens, ComponentTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import type { ComponentKey, PrimitiveKey, SemanticKey } from 'config/tokens/types';

export function getFlatComponentTokens(componentTokens: Record<string, Record<ComponentKey, PrimitiveKey | SemanticKey>>) {
    const flatComponentTokens: Record<ComponentKey, PrimitiveKey | SemanticKey> = {};
    Object.values(componentTokens).forEach((val) => {
        Object.entries(val).forEach(([key, iVal]) => {
            const actualKey = key as ComponentKey;

            flatComponentTokens[actualKey] = iVal;
        });
    });

    return flatComponentTokens as Record<ComponentTokens, ExcludePrimitiveToken<AllTokens>>;
}

export function getAllSemanticAndComponentTokens(tokensObject: any): Record<ExcludePrimitiveToken<AllTokens>, string> {
    return { ...tokensObject.semantic, ...getFlatComponentTokens(tokensObject.component) };
}

export function getAllTokens(tokensObject: any): Record<AllTokens, string> {
    return { ...tokensObject.primitive, ...tokensObject.semantic, ...getFlatComponentTokens(tokensObject.component) };
}
