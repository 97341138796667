/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import { serviceNav } from 'config/config';
import React from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FreeOnlineNotificationTooltip } from 'components/Promo/FreeDomain/FreeOnlineNotificationTooltip';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import { OutlineDropdown } from 'components/Dropdowns/OutlineDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { VIPsitesIcon } from 'components/Icons/VIPsites';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';

/*   ACTIONS
 *****************************************************/
import { truncateText } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import cpanelImg from 'assets/images/support/cpanel.svg';
import { showNavigation } from 'utilities/consts';
import './_Title.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      serviceTitle: React.ReactElement;
 *      serviceSubTitle?: string;
 *      serviceType: (string & {}) | 'domain' | 'legacy-domain' | 'web-hosting' | 'email-hosting' | 'vps' | 'ssl' | 'gsuite' | 'microsoft-365' | 'VIPsites';
 *      useSubtitle?: boolean;
 *      action: React.ReactNode | Array<unknown>;
 *      subtitleData?: {
 *          title: string;
 *          icon?: string;
 *          action?: {
 *              label: string;
 *              onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
 *          };
 *      };
 *      isLoading?: boolean;
 * }>}
 */
let Title = ({ app_viewport, serviceTitle, serviceSubTitle, serviceType, action, useSubtitle, subtitleData, isLoading }) => {
    /***** RENDER HELPERS *****/
    let icon;
    let iconClass;
    let backUrl = '/my-services';

    switch (serviceType) {
        case 'domain':
            iconClass = 'icon-domain-names';
            backUrl = '/my-services/domains';
            break;
        case 'legacy-domain':
            iconClass = 'icon-domain-names';
            backUrl = '/my-services/domains';
            break;

        case serviceNav.VIPsites.key:
            icon = <VIPsitesIcon secondary />;
            backUrl = serviceNav.VIPsites.link;
            break;

        case 'web-hosting':
            iconClass = 'icon-cloud';
            backUrl = '/my-services/hosting';
            break;

        case 'email-hosting':
            iconClass = 'icon-email';
            backUrl = '/my-services/email-hosting';
            break;

        case 'vps':
            iconClass = 'icon-vps';
            backUrl = '/my-services/vps';
            break;

        case 'ssl':
            iconClass = 'icon-ssl';
            backUrl = '/my-services/ssl';
            break;

        case 'gsuite-user':
        case 'gsuite':
            iconClass = 'icon-gsuite';
            backUrl = '/my-services/google';
            break;

        case 'microsoft-365':
            iconClass = 'icon-microsoft';
            backUrl = '/my-services/microsoft-365';
            break;

        default:
            iconClass = '';
            backUrl = `/my-services/${serviceType ?? ''}`;
            break;
    }

    function renderAction(button, index) {
        if (React.isValidElement(button)) {
            return button;
        }

        /**
         * @deprecated Do not use object based buttons, use React components instead
         */
        if (button.list) {
            return (
                <OutlineDropdown
                    title={button.label}
                    color={button.color ? button.color : 'primary'}
                    type={button.type}
                    size={button.size ? button.size : 'small'}
                    titleOnClick={(e) => {
                        button.onClick(e);
                    }}
                    className={button.className ? button.className : ''}
                    list={button.list}
                />
            );
        }

        return (
            <SolidButton
                key={index}
                status={button.status}
                color={button.color}
                type={button.type}
                onClick={button.onClick}
                className={`TitleShared__button ${button.className ? button.className : ''}${
                    button.mobile && (app_viewport === 'xs' || app_viewport === 'sm') ? 'mobile' : ''
                }`}
            >
                {button.mobile && (app_viewport === 'xs' || app_viewport === 'sm') ? <img src={button.mobile} alt={button.label} /> : button.label}
            </SolidButton>
        );
    }

    const renderSubAction = () => {
        return (
            <Anchor
                onClick={subtitleData.action.onClick}
                className={`TitleShared__button--subAction ${subtitleData.className ? subtitleData.className : ''}`}
            >
                {subtitleData.action.label}
            </Anchor>
        );
    };

    const renderActionRow = () => {
        if (Array.isArray(action) && action.length > 0) {
            return action.map((button, index) => renderAction(button, index));
        }
        return renderAction(action, 0);
    };

    const renderServiceTitle = () => {
        if (React.isValidElement(serviceTitle)) return serviceTitle;

        let numOfChars = 70;

        if (app_viewport === 'xs' || app_viewport === 'sm') {
            numOfChars = 35;
        } else if (app_viewport === 'md') {
            numOfChars = 45;
        }

        return truncateText(serviceTitle, numOfChars, '...');
    };

    function renderServiceType() {
        if (serviceType === 'web-hosting') {
            return <img className="service__icon-subaccount" src={cpanelImg} alt="cPanel Account" />;
        }
        if (subtitleData && subtitleData.icon) {
            return <i className={`icon icon-${subtitleData.icon}`} />;
        }

        return '';
    }

    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);

    const renderTitleContent = () => {
        if (isLoading) return <RequestLoader />;

        return (
            <>
                <div className="TitleShared__container">
                    <div className="TitleShared__information">
                        <div className="title__back">
                            <div className="back__container">
                                <Link className="back__button" to={backUrl}>
                                    <PhosphorIcons.Caret.Left secondary />
                                </Link>
                            </div>
                        </div>
                        <div className="text__container">
                            <div className="service__icon">{icon ? icon : <i className={`icon ${iconClass}`} />}</div>
                            <Flex direction={isMobileOrTablet ? 'column' : 'row'} items={isMobileOrTablet ? 'start' : 'center'} gap={0}>
                                <div className="title__text">
                                    {renderServiceTitle()} {serviceType === 'domain' && <FreeOnlineNotificationTooltip />}
                                </div>

                                {serviceType !== 'domain' && serviceSubTitle ? <div className="title__split">/</div> : ''}
                                {serviceType !== 'domain' ? <div className="title__subtext">{serviceSubTitle}</div> : ''}
                            </Flex>
                        </div>
                    </div>

                    {action ? <div className="TitleShared__actions">{renderActionRow()}</div> : ''}

                    {useSubtitle ? (
                        <div className="TitleShared__container subtitle">
                            <div className="text__container">
                                <div className="service__icon">{renderServiceType()}</div>
                                <div className="title__text">{subtitleData.title}</div>
                            </div>
                            {['web-hosting', 'email-hosting'].includes(serviceType) ? renderSubAction() : ''}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );
    };

    /***** RENDER *****/
    return (
        <div className={`TitleShared${useSubtitle ? ' hasSubtitle' : ''}`}>
            <div className="container">{renderTitleContent()}</div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

Title = connect(mapStateToProps)(Title);

// Render nothing instead of the Title if showNavigation is false
if (!showNavigation) Title = () => '';

export default Title;
