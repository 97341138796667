import { PhosphorIcons } from 'components/Icons/Phosphor';
import { validatorCardTypeAMEX, validatorCardTypeMASTERCARD, validatorCardTypeVISA } from 'utilities/methods/validators';
import './_CreditCardIcon.scss';
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CreditCardIconProps = {
    input: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CreditCardIcon = ({ input }: CreditCardIconProps) => {
    /***** RENDER HELPERS *****/
    function renderIcon() {
        const noSpacesInput = input.split(' ').join('');
        if (validatorCardTypeVISA(noSpacesInput)) return <PhosphorIcons.Visa size={26} />;
        if (validatorCardTypeMASTERCARD(noSpacesInput)) return <PhosphorIcons.MasterCard size={26} />;
        if (validatorCardTypeAMEX(noSpacesInput)) return <PhosphorIcons.Amex size={26} />;

        return <PhosphorIcons.CreditCard size={26} />;
    }

    /***** RENDER *****/
    return <div className="CreditCardIcon">{renderIcon()}</div>;
};
