import { pictureInPictureStates } from 'components/PictureInPicture/consts/pictureInPictureStates';
import type { PromoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { promoBannerDismissKeyValues } from 'containers/dashboard/Components/consts';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const ZodUnixTimestamp = z.number().int().min(0);
export const historicMigrationsSchema = z.record(z.string(), z.number());

// prettier-ignore
/**
 * These keys are used to track what local storage keys should exist, any that are not in this record will get cleaned up
 *
 * Please note that no every data structure is currently supported in a type-safe manner.
 *
 * Only primitives and records have typescript support.
 */
export const userPreferencesDictionary = {
    IS_STAFF_MENU_OPEN: z.boolean(),
    REMIND_LATER_2FA_CURRENT_DATE: ZodUnixTimestamp,
    IS_FEEDBACK_BANNER_DISMISSED: z.boolean(),
    PROMO_BANNER_DISMISSAL_RECORD: z.record(
        z.enum(promoBannerDismissKeyValues as [`${PromoBannerDismissKeys}`]),
        z.boolean()
    ),
    IS_2FA_PROMPT_DISMISSED: z.boolean(),
    PICTURE_IN_PICTURE_STATES: z.record(
        z.string(),
        z.enum(pictureInPictureStates)
    ),
    ONLINE_GIFT_NOTIFICATION: z.record(
        z.coerce.number(),
        z.boolean()
    ),

    // Dev/Staff related LocalStorage keys
    SUPER_USER_ACTION_VIEWED: z.array(z.string()),

    // These keys are used for unit tests
    _TEST: z.boolean(),
    _TEST_RECORD: z.record(z.string(), z.boolean()),
} as const;

export const userPreferencesDictionaryKeys = Object.keys(userPreferencesDictionary);
