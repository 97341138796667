import { SolidButton } from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useEnterDrawMutation, useGetDrawEligibilityQuery } from 'config/containers/promotions/queries';
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';
import React from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OneClickCardProps = React.FC<{
    promoId: string;
    tag: string;
    image: string;
    title: string;
    description: string;
    modalContent: React.ReactNode;
    terms?: boolean;
    modalTitle?: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * oneClickCard is a generic RewardCard that handles the entire field in a single click.
 * This allows cards that have no extra questions to be made without too much code changing.
 */
export const _OneClickCard: OneClickCardProps = ({ promoId, tag, image, title, description, terms = false, modalTitle, modalContent }) => {
    /***** QUERIES ******/
    const {
        isError: isGetDrawEligibilityError,
        error: get_draw_eligibility_error,
        isPending: isPromoQueryPending
    } = useGetDrawEligibilityQuery(promoId);
    const { mutate: enterDraw, isPending: isEnterDrawPending } = useEnterDrawMutation(promoId);
    const { value: isModalOpen, toggle: toggleModal } = useBoolean(false);

    /***** RENDER HELPERS *****/
    function renderButtonContents() {
        if (!isGetDrawEligibilityError) return 'Enter Now';

        const errorData = get_draw_eligibility_error?.data;
        if (!errorData) return 'Something Went Wrong';
        if (errorData.is_promo_finished) return 'Promo Finished';
        if (errorData.already_redeemed) return 'Entered';
        if (!errorData.is_promo_started) return 'Promo Not Started';
        if (!errorData.is_vip_reward_member) return 'Not a VIP Member';

        return 'Something Went Wrong';
    }

    /***** RENDER *****/
    return (
        <RewardCard>
            <Modal isOpen={isModalOpen} onClose={toggleModal} ariaLabel="Confirmation Modal">
                <Modal.Title>{modalTitle ?? `You're in!`}</Modal.Title>
                <Modal.Content>
                    <Flex direction="column" items="center" gap={5}>
                        {typeof modalContent === 'string' ? (
                            <Text secondary className="oneClickLightbox">
                                {modalContent}
                            </Text>
                        ) : (
                            modalContent
                        )}
                        <SolidButton className="oneClickLightbox__button" onClick={toggleModal}>
                            Go Back
                        </SolidButton>
                    </Flex>
                </Modal.Content>
            </Modal>
            <RewardCard.Image tag={tag}>
                <img width="278px" className="rewardCard__bg rewardCard__bg--overflowHidden" src={image} alt={title} />
            </RewardCard.Image>
            <RewardCard.Body
                title={title}
                description={description}
                subDescription={
                    terms && (
                        <Padding top={1} bottom={6}>
                            <Text secondary italic span size--xs>
                                Terms and conditions apply.
                            </Text>
                        </Padding>
                    )
                }
            >
                <SolidButton
                    color="primary"
                    disabled={isGetDrawEligibilityError}
                    isLoading={isPromoQueryPending || isEnterDrawPending}
                    onClick={() => {
                        enterDraw(undefined, { onSuccess: toggleModal });
                    }}
                >
                    {renderButtonContents()}
                </SolidButton>
            </RewardCard.Body>
        </RewardCard>
    );
};
