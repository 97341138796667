import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import { API } from 'utilities/api/services';
import { MINUTE } from 'utilities/consts';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const createQueryKey = () => createBaseQueryKey(['domains']);

/***** FUNCTIONS *****/
function createQueryOptions() {
    return queryOptions({
        queryKey: createQueryKey(),
        queryFn: API.domain.list,
        staleTime: MINUTE * 5
    });
}

function invalidateQueries() {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey()
    });
}

function prefetchQuery() {
    queryClient.prefetchQuery(createQueryOptions());
}

function _useSuspenseQuery() {
    return useSuspenseQuery(createQueryOptions());
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery() {
    return useQuery(createQueryOptions());
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const domainsList = Object.freeze({
    useQuery: _useQuery,
    useSuspenseQuery: _useSuspenseQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions,
    prefetchQuery
});
