/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';
import { checkCodeCancelTokens } from 'config/containers/promotions/api';
import { batchActions } from 'redux-batched-actions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { cancelDashboardServices } from 'utilities/api/dashboard';

/*   ACTIONS
 *****************************************************/
import { APP_USER_RESET } from 'App/actionTypes';
import { PROMOTION_PROMO_CODE_STATUS_RESET } from 'config/containers/promotions/actionTypes';
import { ACCOUNT_INITIAL_STATE } from 'containers/account/actionTypes';
import { LOGIN_INITIAL_STATE } from 'containers/login/actionTypes';
import { DOMAIN_LIST_RESET, SERVICES_INITIAL_STATE } from 'containers/services/actionTypes';

export const logoutApp = () => {
    return (dispatch) => {
        return axios({
            method: 'delete',
            url: '/api/logout'
        }).then(() => {
            cancelDashboardServices?.();
            if (checkCodeCancelTokens) {
                for (const promo_code in checkCodeCancelTokens) {
                    if (Object.hasOwnProperty.call(checkCodeCancelTokens, promo_code)) {
                        checkCodeCancelTokens[promo_code]();
                        delete checkCodeCancelTokens[promo_code];
                    }
                }
            }
            dispatch(
                batchActions([
                    {
                        type: DOMAIN_LIST_RESET
                    },
                    {
                        type: LOGIN_INITIAL_STATE
                    },
                    {
                        type: APP_USER_RESET
                    },
                    {
                        type: ACCOUNT_INITIAL_STATE
                    },
                    {
                        type: SERVICES_INITIAL_STATE
                    },
                    {
                        type: PROMOTION_PROMO_CODE_STATUS_RESET
                    }
                ])
            );
        });
    };
};
