export const ventraSvg = (
    <svg width="100%" height="100%" viewBox="0 0 660 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="134" y="134" width="392" height="392" rx="10" stroke="url(#paint0_linear_0_1)" strokeWidth="20" mask="url(#path-2-inside-1_0_1)" />
        <rect x="221" y="199" width="20" height="263" rx="8" fill="#CA3C78" />
        <rect x="422" y="199" width="20" height="263" rx="8" fill="#CA3C78" />
        <rect x="320" y="199" width="20" height="263" rx="8" fill="#CA3C78" />

        <circle cx="231" cy="330" r="25" fill="white" stroke="#CA3C78" strokeWidth="20">
            <animate
                attributeName="cy"
                dur="4s"
                begin="0s"
                values="330.5; 250; 410; 330.5; 330.5"
                repeatCount="indefinite"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                keySplines="
    .42,0,.58,1;
    .42,0,.58,1;
    .42,0,.58,1;
    .42,0,.58,1;"
            />
        </circle>

        <circle cx="330" cy="405" r="25" fill="white" stroke="#CA3C78" strokeWidth="20">
            <animate
                attributeName="cy"
                dur="4s"
                begin="0s"
                values="410; 410; 250; 410; 410"
                repeatCount="indefinite"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                keySplines="
.42,0,.58,1;
.42,0,.58,1;
.42,0,.58,1;
.42,0,.58,1;"
            />
        </circle>

        <circle cx="432" cy="405" r="25" fill="white" stroke="#CA3C78" strokeWidth="20">
            <animate
                attributeName="cy"
                dur="4s"
                begin="0s"
                values="410; 300.5; 300.5; 250; 410"
                repeatCount="indefinite"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                keySplines="
    .42,0,.58,1;
    .42,0,.58,1;
    .42,0,.58,1;
    .42,0,.58,1;"
            />
        </circle>

        <defs>
            <linearGradient id="paint0_linear_0_1" x1="330" y1="134" x2="330" y2="526" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F4AD26" />
                <stop offset="1" stopColor="#CA3C78" />
            </linearGradient>
        </defs>
    </svg>
);
