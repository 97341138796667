import _ from 'lodash';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**
 * @refactor change this to a zod schema to validate the object
 */
export function isKatanaFileAttachment(value: any): value is KatanaAPI.Katana.ServiceID.Uploads.POST.AttachmentData {
    return (
        (value &&
            _.keys(value).length === 6 &&
            _.has(value, 'file') &&
            _.has(value, 'id') &&
            _.has(value, 'mime_type') &&
            _.has(value, 'name') &&
            _.has(value, 'url') &&
            _.has(value, 'hash')) ||
        (value && _.keys(value).length === 2 && _.has(value, 'attachment') && _.has(value, 'type')) ||
        (value && _.keys(value).length === 2 && _.has(value, 'type') && _.has(value, 'url') && value.url.includes('katana'))
    );
}
