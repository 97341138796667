import { Outlet, createRoute, redirect, useLocation } from '@tanstack/react-router';
import { AppRoute } from 'App';
import { actionButtonWidth } from 'components/NXTable/hooks/useTableActions';
import Subnav from 'components/Subnav';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { getVPSList } from 'containers/services/action';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isRouteActive } from 'router/utils/isRouteActive';
import { routerMiddleware } from 'router/utils/middleware';
import { useStyle } from 'utilities/hooks/useStyle';
import { activePromotion } from 'utilities/methods/commonActions';
import './_services.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'my-services',
    beforeLoad(opts) {
        const paths: Array<string> = ['/my-services', '/my-services/'];

        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);

        // Refer to https://github.com/TanStack/router/issues/1882 why we need the cause check
        if (paths.includes(opts.location.pathname) && opts.cause !== 'preload') {
            throw redirect({ to: '/my-services/domains', replace: true });
        }
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const MyServices = () => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    const { pathname } = useLocation();
    const styles = useStyle({
        '--actionButtonWidth': actionButtonWidth
    });

    const vps_list_meta = useSelector((state) => state.services.vps_list_meta);

    /***** EFFECTS *****/
    useEffect(() => {
        // For Intaserve, fetch VPS list if it hasn't been fetched already. So that we can determine whether or not to show the VPS tab
        if (isRouteActive({ path: '/my-services/vps', params: {} }) && activeBrand === 'intaserve' && !vps_list_meta) {
            getVPSList();
        }
    }, []);

    //Hacky way to change the size of the Hosting action buttons while we are adding the 'Renew' promo button
    const myServiceClassName = activePromotion('cyberMondayVipRewards2024') ? 'ServicePage--promo' : 'ServicePage';

    /***** RENDER *****/
    return (
        <div className={myServiceClassName} style={styles}>
            <Subnav pathname={pathname} />

            {/* Animated; 600ms; ccpPageFade */}
            <Outlet />
        </div>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
MyServicesRoute.update({
    component: React.memo(MyServices)
});
