import Anchor from 'components/Anchor';
import { Modal } from 'components/Lightboxes/Modal';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { NXCreditCardForm } from 'containers/billing/forms/creditCardForm';
import type { Schema } from 'containers/billing/forms/creditCardForm/consts';
import { useCreatePaymentMethodDataMutation } from 'containers/billing/queries';
import React from 'react';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import './_confirmDefaultPayment.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfirmDefaultPaymentProps = React.FC<{
    isOpen: boolean;
    toggleModal: () => void;
    id: string;
    enableAutoRenew: (id: string) => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfirmDefaultPayment: ConfirmDefaultPaymentProps = ({ isOpen, toggleModal, id, enableAutoRenew }) => {
    /***** HOOKS *****/
    const { mutate: mutatePaymentMethodData, isPending: isPaymentMethodPending } = useCreatePaymentMethodDataMutation();
    /***** EFFECTS *****/
    function onSubmit(data: Schema) {
        const { CARD_HOLDER_NAME, CARD_NUMBER, CARD_MONTH, CARD_YEAR, CARD_CVV } = CREDIT_CARD_FIELD_DATA;
        const month = data[CARD_MONTH.name];
        const mutationData = {
            is_default: true,
            data: {
                card_holder: data[CARD_HOLDER_NAME.name],
                card_number: data[CARD_NUMBER.name].replaceAll(' ', ''),
                expiry: `${month.toString().length === 2 ? month : `0${month}`}/${data[CARD_YEAR.name]}`,
                cvv: data[CARD_CVV.name].toString()
            }
        };
        mutatePaymentMethodData(mutationData, {
            onSuccess: () => {
                const successNotification = (
                    <span>
                        Successfully enabled auto-renew.
                        <br />
                        <br />
                        <Text bold>
                            <span>Please note: </span>
                        </Text>
                        If this domain is due for renewal, please allow some time for the renewal invoice to be generated.
                    </span>
                );
                pushNotification({ status: 200, details: successNotification });
                enableAutoRenew(id);
                toggleModal();
            }
        });
    }

    /***** RENDER *****/
    return (
        <Modal isOpen={isOpen} onClose={toggleModal} ariaLabel="Confirm Default Payment">
            <Modal.Title>Confirm Default Payment</Modal.Title>
            <Modal.Content>
                <Text secondary semiBold align--center>
                    To enable auto-renew, you must have a default payment selected. Please enter your credit card details to enable auto-renew.
                    <br />
                    <br /> This will be saved as your default payment. If you cancel, auto-renew will be disabled.
                </Text>

                <Padding y={5}>
                    <NXCreditCardForm onSubmit={onSubmit} isPending={isPaymentMethodPending} />
                </Padding>
                <Flex fullWidth justify="center">
                    <Anchor color="primary" onClick={toggleModal}>
                        <Text size--m uppercase align--center>
                            Go back
                        </Text>
                    </Anchor>
                </Flex>
            </Modal.Content>
        </Modal>
    );
};
