/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Border } from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import copy from 'assets/images/promo/brooksAUDiscount/copy.svg';
import tileImage from 'assets/images/promo/brooksAUDiscount/viprewards-brooks.jpg';
import '../_rewardCard.scss';

const couponCode = 'VIPREWARDS';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BrooksAUDiscountCard = () => {
    /***** STATE *****/
    const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);

    /***** HOOKS *****/
    const dispatch = useDispatch();

    /***** RENDER HELPERS *****/
    function renderCouponLightBox() {
        return (
            <Modal isOpen={isCouponModalOpen} onClose={() => setIsCouponModalOpen(false)} ariaLabel="Contest Entry Confirmation">
                <Padding top={5}>
                    <Flex direction="column" justify="center" items="center" gap={4}>
                        <Text size--h2 medium lead--none>
                            Claim your coupon
                        </Text>
                        <Text color="S_color_text_secondary_base" size--m medium lead--none>
                            20% OFF Full Price Styles* with Brooks
                        </Text>
                        <Padding y={1}>
                            <Flex direction="column" gap={3}>
                                <Border all className="brooksAUDiscountLightBox__couponContainer">
                                    <Padding xy={3}>
                                        <Flex items="center" gap={6}>
                                            <div>
                                                <Text color="S_color_text_secondary_base" size--xs medium>
                                                    USE COUPON
                                                </Text>
                                                <Text color="S_color_text_primary_base" size--h2>
                                                    VIPREWARDS
                                                </Text>
                                            </div>
                                            <button type="button" onClick={() => copyToClipboard(couponCode)(dispatch)}>
                                                <Flex items="center">
                                                    <Text color="S_color_text_info_base" size--s>
                                                        Copy coupon
                                                    </Text>
                                                    <img src={copy} alt="copy" height={14} width={14} />
                                                </Flex>
                                            </button>
                                        </Flex>
                                    </Padding>
                                </Border>
                                <SolidButton
                                    type="anchor"
                                    target="_blank"
                                    href="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=brooks-running&mtm_medium=viprewards&siteId=8&redirect=https://www.brooksrunning.com.au"
                                >
                                    Shop Brooks
                                </SolidButton>
                            </Flex>
                        </Padding>
                        <Padding x={2}>
                            <Text size--xs color="S_color_text_secondary_base" align--center className="brooksAUDiscountLightBox__tcsText">
                                *Offer valid until 11:59 PM AEDT on 23 November 2024. Available exclusively online at brooksrunning.com.au. Not
                                applicable to sale items or in conjunction with any other promotions. To redeem, enter the promo code VIPREWARDS at
                                the shopping cart before checkout. Free shipping applies to orders over $50.00 AUD. Offer is open to both new and
                                existing customers, with no limit on order quantity.
                            </Text>
                        </Padding>
                    </Flex>
                </Padding>
            </Modal>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="SPECIAL OFFER">
                    <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                        <img src={tileImage} alt="Brooks running" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="20% OFF Full Price Styles* with Brooks"
                    description="Discover the perfect blend of comfort and performance with Brooks Running – gear up today and experience the difference on every run!"
                >
                    <SolidButton color="primary" onClick={() => setIsCouponModalOpen(true)}>
                        Get Discount
                    </SolidButton>
                </RewardCard.Body>
            </RewardCard>

            {/* renders on promo tile button click */}
            {renderCouponLightBox()}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
