import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { Field, change, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { RenderRadioButtonDropdown } from 'components/Form/RenderRadioButtonDropdown';
import { TextArea } from 'components/Form/TextArea';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import Table from 'components/Table';
import Tip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import { BankDetails, RefundMethods } from './refundMethods';
import { cancelVPS, checkCancellationInfo, submitCancellationRequest } from 'components/Cancel/action';
import { getEmailMailboxInformation } from 'containers/email/action';
import { getGSuiteService } from 'containers/gsuite/action';
import { getHostingInformation } from 'containers/hosting/state/accountActions';
import { getMs365Service } from 'containers/ms365/action';
import { getDomainsList, getEmailList, getGSuiteList, getHostingList, getMs365List, getVPSList } from 'containers/services/action';
import { getSelectedVPS } from 'containers/vps/action';
import {
    RenderSelectField,
    TableRenderSelectField,
    renderCheckboxField,
    requiredAcceptedValidation,
    requiredFieldValidation
} from 'utilities/methods/form';
import './_CancelForm.scss';
import { withBrandStore } from 'config/hooks/useBrandStore';

const formName = 'overlayCancel';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class OverlayForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'multiple',
            cancelationRequestStatus: null,
            additionalServicesSelected: false,
            showBulkOverrideArea: false,
            multipleServicesOptions: {},
            relatedServicesRefundable: null
        };
        this.propegateFormChange = this.propegateFormChange.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
        this.checkService = this.checkService.bind(this);
        this.loadedService = this.loadedService.bind(this);
    }

    propegateFormChange = (value, name) => {
        const { form } = this.props;
        this.props.dispatch(change(form, name, value));
    };

    /************** SUBMIT FUNCTIONS **************/
    handleCancelSubmit() {
        const { history, formValues, cancel, cancelVPS, getGSuiteList, getMs365List, getEmailList, getDomainsList, submitCancellationRequest } =
            this.props;

        const { additionalServicesSelected } = this.state;

        const {
            reason,
            reason_comment = '',
            confirm_delete,
            confirm_backup,
            confirm_dns,
            refund_method = 'none',
            services,
            account_name,
            account_number,
            branch_number
        } = formValues;
        // Additional Services Request Collation
        const additional = [];
        if (additionalServicesSelected && services) {
            Object.keys(services).forEach((value) => {
                const { checkbox = false, refund_method = 'none', id = Number(value.split("'")[1]) } = services[value];

                if (checkbox) {
                    const additionalCheckbox = {
                        type: 'service',
                        id,
                        refund_method
                    };

                    if (refund_method === 'gateway' || refund_method === 'manual') {
                        additionalCheckbox.account_name = account_name;
                        additionalCheckbox.account_number = account_number;
                        additionalCheckbox.branch_number = branch_number;
                    }

                    additional.push(additionalCheckbox);
                }
            });
        }

        const getVPSAttributes = () => ({
            reason,
            reason_comment,
            type: 'End of Billing Period',
            confirm_delete: confirm_delete ? 'yes' : 'no',
            confirm_backup: confirm_backup ? 'yes' : 'no'
        });

        const getStandardAttributes = () => ({
            reason,
            reason_comment,
            type: 'immediate',
            confirm_delete,
            confirm_backup,
            refund_method,
            account_name,
            account_number,
            branch_number
        });

        switch (cancel.service) {
            case 'vps':
                cancelVPS(Number(cancel.id), getVPSAttributes(), null, {
                    list: getVPSList,
                    history: () => history.push('/my-services/vps')
                });
                break;
            case 'webHosting':
                submitCancellationRequest(Number(cancel.id), { ...getStandardAttributes(), confirm_dns }, additional, {
                    list: getHostingList,
                    history: () => history.push('/my-services/hosting')
                });
                break;
            case 'gsuite':
                submitCancellationRequest(Number(cancel.id), getStandardAttributes(), additional, {
                    list: getGSuiteList,
                    history: () => history.push(cancel.fromDashboard ? '/dashboard' : '/my-services/google')
                });
                break;
            case 'ms365':
                submitCancellationRequest(Number(cancel.id), getStandardAttributes(), additional, {
                    list: getMs365List,
                    history: () => history.push(cancel.fromDashboard ? '/dashboard' : '/my-services/microsoft-365')
                });
                break;
            case 'emailHosting':
                submitCancellationRequest(Number(cancel.id), getStandardAttributes(), additional, {
                    list: getEmailList,
                    history: () => history.push(cancel.fromDashboard ? '/dashboard' : '/my-services/email-hosting')
                });
                break;
            default:
                submitCancellationRequest(Number(cancel.id), getStandardAttributes(), additional, {
                    list: getDomainsList,
                    history: () => history.push('/my-services')
                });
                break;
        }
    }

    loadedService() {
        const {
            cancel,
            hosting_information_data,
            email_information_mailbox_data,
            vps_information_data,
            gsuite_service_data,
            ms365_information_data
        } = this.props;

        switch (cancel.service) {
            case 'vps':
                return vps_information_data?.id && Number(vps_information_data.id) === Number(cancel.id);
            case 'webHosting':
                return hosting_information_data?.id && Number(hosting_information_data.id) === Number(cancel.id);
            case 'emailHosting':
                return email_information_mailbox_data?.id && Number(email_information_mailbox_data.id) === Number(cancel.id);
            case 'gsuite':
                return gsuite_service_data?.id && Number(gsuite_service_data.id) === Number(cancel.id);
            case 'ms365':
                return ms365_information_data?.id && Number(ms365_information_data.id) === Number(cancel.id);
            default:
                return false;
        }
    }

    checkService() {
        const { cancel } = this.props;
        const { getSelectedVPS, getHostingInformation, getEmailMailboxInformation, getMs365Service } = this.props;

        const { loadedService } = this;

        if (!loadedService()) {
            switch (cancel.service) {
                case 'vps':
                    getSelectedVPS(cancel.id);
                    break;
                case 'webHosting':
                    getHostingInformation(cancel.id);
                    break;
                case 'emailHosting':
                    getEmailMailboxInformation(cancel.id);
                    break;
                case 'gsuite':
                    getGSuiteService(cancel.id);
                    break;
                case 'ms365':
                    getMs365Service(cancel.id);
                    break;
                default:
                    break;
            }
        }
    }

    additionalServicesToggleClicked = (_bool) => {
        this.setState({
            additionalServicesSelected: _bool
        });
    };

    showBulkOverrideAreaToggle = (toggle = null) => {
        this.setState({
            showBulkOverrideArea: toggle !== null ? toggle : !this.state.showBulkOverrideArea
        });
    };

    calculateEstimated = () => {
        // Also does refund eligibility check on related services
        const { service_check_cancel_data, formValues } = this.props;
        const { additionalServicesSelected } = this.state;
        let estimated = 0.0;
        const getPrices = (multipleServiceInfo) => {
            const prices = {};
            if (multipleServiceInfo) {
                multipleServiceInfo.forEach((service, index) => {
                    const { refund_amount, service_id } = service;
                    prices[index === 0 ? 'primary' : service_id] = refund_amount;
                });
            }
            return prices;
        };
        const prices = service_check_cancel_data ? getPrices(service_check_cancel_data) : null;
        const relatedServicesInfo = [];

        if (!prices) return estimated;

        additionalServicesSelected &&
            formValues.services &&
            Object.keys(formValues.services).forEach(function (item) {
                if (!formValues.services[item].checkbox) return;

                const itemPrice = parseFloat(prices[item.split("'")[1]]);
                if (itemPrice > 0) {
                    relatedServicesInfo.push(formValues.services[item]);
                }
                estimated += itemPrice;
            });

        if ('primary' in prices) {
            estimated += parseFloat(prices.primary);
        }
        let relatedServicesRefundable = false;
        relatedServicesInfo.forEach((item) => {
            if (!(item.refund_method && item.refund_method.length > 0)) return;

            const { refund_method: e } = item;
            if ((e === 'gateway' || e === 'manual') && !relatedServicesInfo) {
                relatedServicesRefundable = true;
            }
        });
        if (this.state.relatedServicesRefundable !== relatedServicesRefundable) {
            this.setState({ relatedServicesRefundable });
        }

        return estimated;
    };

    componentDidMount() {
        const { cancel, checkCancellationInfo } = this.props;
        const { checkService } = this;

        checkService();

        if (cancel.service !== 'vps') {
            checkCancellationInfo(Number(cancel.id));
        }

        let confirmation = {};
        let type = '';
        switch (cancel.service) {
            case 'vps':
                type = 'multiple';
                confirmation = {
                    heading: 'Cancel VPS Service',
                    description: <Fragment>Are you sure you want to cancel this service?</Fragment>,
                    terms_description: 'I understand that closing my account will remove all data for this currently setup.',
                    action_label: 'Cancel Service',
                    cancel_label: 'NO, KEEP SERVICE'
                };
                break;
            case 'emailHosting':
                type = 'multiple';
                confirmation = {
                    heading: 'Cancel Email Service',
                    description: <Fragment>Are you sure you want to cancel this service?</Fragment>,
                    terms_description:
                        'I understand that closing my account will remove all emails, contacts, calendar entries and tasks for ALL Email accounts currently setup.',
                    action_label: 'Cancel Service',
                    cancel_label: 'NO, KEEP SERVICE'
                };
                break;
            case 'gsuite':
                type = 'multiple';
                confirmation = {
                    heading: 'Cancel Google Workspace Service',
                    description: <Fragment>Are you sure you want to cancel this service?</Fragment>,
                    terms_description:
                        'Please note that this will disassociate your Google Workspace account with us. We will no longer manage your account and all support will be required to go through Google.',
                    action_label: 'Cancel Service',
                    cancel_label: 'NO, KEEP SERVICE'
                };
                break;
            case 'ms365':
                type = 'multiple';
                confirmation = {
                    heading: 'Cancel Microsoft 365 Subscription',
                    description: <Fragment>Are you sure you want to cancel this service?</Fragment>,
                    terms_description:
                        'Please note that this will disassociate your Microsoft 365 Subscription with us. We will no longer manage your subscription and all support will be required to go through Microsoft.',
                    action_label: 'Cancel Service',
                    cancel_label: 'NO, KEEP SERVICE'
                };
                break;
            case 'webHosting':
            default:
                type = 'multiple';
                confirmation = {
                    heading: 'Cancel Web Hosting Service',
                    description: <Fragment>Are you sure you want to cancel this service?</Fragment>,
                    terms_description: 'I understand that closing my account will remove all data for this currently setup.',
                    action_label: 'Cancel Service',
                    cancel_label: 'NO, KEEP SERVICE'
                };
                break;
        }

        this.setState({
            type,
            confirmation
        });
    }

    render() {
        const {
            cancel,
            handleSubmit,
            formValues,
            form,
            service_check_cancel_data,
            hosting_information_data,
            email_information_mailbox_data,
            vps_information_data,
            gsuite_service_data,
            ms365_information_data,
            company
        } = this.props;

        const { type, additionalServicesSelected } = this.state;

        const { loadedService, propegateFormChange, handleCancelSubmit, additionalServicesToggleClicked, calculateEstimated } = this;

        if (formValues && 'services' in formValues && 'bulkRefundServices' in formValues) {
            let primary = '';
            if (!formValues['bulkRefundServices']) {
                Object.keys(formValues.services).forEach((key, index) => {
                    const val = formValues.services[key].refund_method;
                    if (index === 0) return (primary = val);
                    if (primary !== val) this.props.dispatch(change(form, 'bulkRefundServices', true));
                });
            }
        }

        const formTypeFieldRender = (id, methods = null) => {
            const options = [];
            methods.forEach((value) => {
                switch (value.toLowerCase()) {
                    case 'account_credit':
                        options.push({
                            label: 'Account Credit',
                            value
                        });
                        break;
                    case 'donation':
                        options.push({
                            label: 'Donate',
                            value
                        });
                        break;
                    case 'manual':
                        options.push({
                            label: 'Cash Refund',
                            value
                        });
                        break;
                    case 'gateway':
                        options.push({
                            label: 'Cash Refund',
                            value
                        });
                        break;
                    case 'synergy':
                        options.push({
                            value: 'synergy',
                            label: 'Synergy Wholesale Account Credit'
                        });
                        break;
                    case 'none':
                        break;
                    default:
                        break;
                }
            });

            return (
                <Field
                    key={`${id}-refund_method`}
                    name={`services.'${id}'.refund_method`}
                    component={TableRenderSelectField}
                    validate={[requiredFieldValidation]}
                    attributes={{
                        type: `select`
                    }}
                    settings={{
                        isTag: true
                    }}
                    options={options}
                />
            );
        };

        const renderDomainField = (id, domain, type) => {
            const domainFieldClass = 'cancelService__domainField';
            const domainInfoClass = 'cancelService__domainInfo';
            return (
                <div className={domainFieldClass}>
                    <Field
                        name={`services.'${id}'.checkbox`}
                        component={renderCheckboxField}
                        type="checkbox"
                        className="form__textfield"
                        onClick={(e) => {
                            e.preventDefault();
                            try {
                                propegateFormChange(!formValues.services[`'${id}'`].checkbox, `services.'${id}'.checkbox`);
                            } catch (e) {
                                propegateFormChange(true, `services.'${id}'.checkbox`);
                            }
                        }}
                    />
                    <div className={domainInfoClass}>
                        <div className={`${domainInfoClass}__domain`}>{domain}</div>
                        <div className={`${domainInfoClass}__serviceType`}>{type}</div>
                    </div>
                </div>
            );
        };

        const toolTipProRata = () => {
            const info = 'This amount is calculated based on the unused portion of your remaining billing period';
            return <Tip className="top__right__balloon" info={info} />;
        };

        const getProperRefundTableMatrix = () => {
            if (service_check_cancel_data && service_check_cancel_data.length > 0) {
                const multipleServiceInfo = service_check_cancel_data.slice(0);
                multipleServiceInfo.shift();
                const multipleServiceMatrix = Array(multipleServiceInfo.length).fill();

                multipleServiceInfo.forEach((value, index) => {
                    const { id, name, domain, refund_methods, refund_amount, refundable, money_back_period } = value;
                    const amount = refundable ? (
                        <div className="cancelService__textRightColumn-head">
                            {refund_amount > 0 ? (
                                <>
                                    {' '}
                                    {`$${refund_amount}`} {!money_back_period ? toolTipProRata() : <> </>}{' '}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <> </>
                    );

                    const refund =
                        !(refund_methods.length === 1 && refund_methods[0] === 'none') || refundable
                            ? formTypeFieldRender(id, refund_methods)
                            : 'Service not eligible for a refund';

                    multipleServiceMatrix[index] = {
                        domain: renderDomainField(id, domain, name),
                        amount,
                        refund
                    };
                });
                return multipleServiceMatrix;
            }
            return null;
        };

        const renderAdditionalServicesFields = () => {
            let methods = null;
            const cancelInfo = service_check_cancel_data;
            let _min = Infinity;
            if (cancelInfo) {
                let _methods = null;
                cancelInfo.forEach((service, index) => {
                    if (index < cancelInfo.length && service.refund_methods.length > 0 && service.refund_methods.length < _min) {
                        _min = service.refund_methods.length;
                        _methods = service.refund_methods;
                    }
                });
                methods = _methods;
            }
            const refundList = [];

            if (methods) {
                methods.forEach((method) => {
                    switch (method) {
                        case 'account_credit':
                            refundList.push({
                                value: 'account_credit',
                                label: 'Account Credit'
                            });
                            break;

                        case 'donation':
                            refundList.push({
                                value: 'donation',
                                label: 'Donate'
                            });
                            break;

                        case 'manual':
                            refundList.push({
                                value: 'manual',
                                label: 'Cash Refund'
                            });
                            break;

                        case 'gateway':
                            refundList.push({
                                value: 'gateway',
                                label: 'Cash Refund'
                            });
                            break;

                        case 'synergy':
                            refundList.push({
                                value: 'synergy',
                                label: 'Synergy Wholesale Account Credit'
                            });
                            break;

                        case 'none':
                        default:
                            break;
                    }
                });
            }
            const list = [
                {
                    label: 'Apply one refund method to all related services',
                    value: false,
                    options: refundList,
                    checked: false
                },
                {
                    label: 'Choose refund method for each service',
                    value: true,
                    checked: true
                }
            ];

            return (
                <>
                    <div className="cancelService__servicesTableArea">
                        <div className="cancelService__description cancelService__information">
                            Our system has detected that this service&apos;s most recent invoice also included additional services. Would you like to
                            review and cancel other services on this invoice?
                        </div>
                        <div className="cancelService__toggleDropdownArea">
                            <div className="cancelService__toggle">
                                <button
                                    type="button"
                                    className={`cancelService__toggleButton cancelService__toggleButton--left  
                                ${additionalServicesSelected ? 'cancelService__toggleButton--active' : ''}`}
                                    onClick={() => {
                                        additionalServicesToggleClicked(true);
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className={`cancelService__toggleButton cancelService__toggleButton--right 
                                ${!additionalServicesSelected ? 'cancelService__toggleButton--active' : ''}`}
                                    onClick={() => {
                                        additionalServicesToggleClicked(false);
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    {additionalServicesSelected ? (
                        <div className="cancelService__bulkOverrideArea">
                            {0 in list && list[0].options.length > 0 ? (
                                <>
                                    <div className="cancelService__subheading cancelService__subheading--primary">
                                        Select the other service you wish to cancel below
                                    </div>
                                    <Field
                                        component={RenderRadioButtonDropdown}
                                        itemlist={list}
                                        name="bulkRefundServices"
                                        className="TicketForm__radio-button"
                                        selectOnChange={onClickBulkChange}
                                        ellipsized={{
                                            chars: 14
                                        }}
                                    />{' '}
                                </>
                            ) : (
                                <></>
                            )}

                            <Table
                                className=""
                                header={[
                                    {
                                        title: `Primary Domain / Plan`
                                    },
                                    {
                                        title: `Amount Refunded`,
                                        className: 'cancelService__textRightColumn'
                                    },
                                    {
                                        title: `Refund Method`,
                                        className: 'cancelService__textRightColumn'
                                    }
                                ]}
                                error="Error, cannot find additional services"
                                stacked={true}
                                embedded={true}
                                matrix={properRefundTableMatrix}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        };

        const onClickBulkChange = (inputname = 'index-method', name = this.props.formValues['index-method']) => {
            if (inputname === '' || name === '') return;

            const multipleServiceInfo = service_check_cancel_data.slice(0);
            multipleServiceInfo.shift();

            if (!(multipleServiceInfo && multipleServiceInfo.length > 0)) return;

            let value = name;
            multipleServiceInfo.forEach((service) => {
                const { id, refund_methods } = service;
                const optionals = ['gateway', 'manual'];
                if (!refund_methods.includes(name) && name === optionals[0]) {
                    value = refund_methods.includes(optionals[1]) ? optionals[1] : name;
                } else if (!refund_methods.includes(name) && name === optionals[1]) {
                    value = refund_methods.includes(optionals[0]) ? optionals[0] : name;
                } else {
                    // If "name" is not equal to either of the first two optionals, do nothing
                }
                propegateFormChange(value, `services.'${id}'.refund_method`);
            });
            propegateFormChange(false, `bulkRefundServices`);
        };

        const isPrimaryCashRefundable = (services) => {
            if (services && services.length > 0) {
                const { refund_methods, refundable } = services[0]; // Primary Service
                if (
                    refund_methods.some((e) => {
                        return e === 'gateway' || e === 'manual';
                    })
                ) {
                    return refundable;
                }
            }
            return false;
        };

        const isAdditionalsCashRefundSelected = () => {
            let returnValue = false;
            if (formValues?.services) {
                const { services } = formValues;
                Object.keys(services).forEach((key) => {
                    if ((services[key].refund_method === 'gateway' || services[key].refund_method === 'manual') && services[key].checkbox) {
                        returnValue = true;
                    }
                });
            }
            return returnValue;
        };

        const isCashAllowedOrSelected = (formValues) => {
            const { refund_method } = formValues || {};
            const { additionalServicesSelected } = this.state;
            const { service_check_cancel_data } = this.props;
            if (additionalServicesSelected && isAdditionalsCashRefundSelected() && isPrimaryCashRefundable(service_check_cancel_data)) {
                return true;
            }
            return refund_method === 'gateway' || refund_method === 'manual';
        };

        const handleFormRender = () => {
            const reasonOptions = [
                {
                    label: 'No longer need this service',
                    value: 'No longer need this service'
                },
                {
                    label: `Transferring to another ${company} service`,
                    value: `Transferring to another ${company} service`
                },
                {
                    label: 'Unhappy with the performance of the hosting service',
                    value: 'Unhappy with the performance of the hosting service'
                },
                {
                    label: 'The quality of support provided was unsatisfactory',
                    value: 'The quality of support provided was unsatisfactory'
                },
                {
                    label: 'The price and inclusions are no longer competitive',
                    value: 'The price and inclusions are no longer competitive'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ];

            if (
                service_check_cancel_data &&
                0 in service_check_cancel_data &&
                service_check_cancel_data[0]?.refund_methods?.length > 0 &&
                service_check_cancel_data[0].refund_methods.includes('synergy')
            ) {
                reasonOptions.splice(2, 0, {
                    label: `Moving service to Synergy Wholesale`,
                    value: `Moving service to Synergy Wholesale`
                });
            }

            if (type === 'multiple')
                return (
                    <Fragment>
                        <div className="form__row">
                            <div className="form__column full">
                                <div className="cancelService__heading">{cancel.heading}</div>
                                <div className="cancelService__description">{cancel.description}</div>
                            </div>
                        </div>

                        <div className="form__row">
                            <div className="form__column full">
                                <Field
                                    label="Primary Reason for Cancelling Service"
                                    name="reason"
                                    component={RenderSelectField}
                                    type="select"
                                    validate={[requiredFieldValidation]}
                                    className="form__dropdown"
                                    options={reasonOptions}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            <div className="form__column full">
                                <Field
                                    label="Additional Comments (Optional)"
                                    name="reason_comment"
                                    component={TextArea.ReduxForm}
                                    className="form__textfield"
                                />
                            </div>
                            <p className="cancelService__additionalCommentsNote">
                                PLEASE NOTE: Additional comments are not actively monitored and are used for reference only. Any further requests made
                                may not be actioned by our team. If you require any additional assistance, please contact our{' '}
                                <Anchor to="/support/tickets/submit">Support Team</Anchor>.
                            </p>
                        </div>
                        {(Boolean(service_check_cancel_data?.[0]?.refundable) || Boolean(this.state.relatedServicesRefundable)) && (
                            <>
                                <div className="cancelService__subheading cancelService__subheading--primary">You are eligible for a refund!</div>
                                <div className="cancelService__subheading cancelService__subheading--secondary">
                                    Select how you wish to receive your refund:
                                </div>
                                <div className="cancelService__refundMethods form__row">
                                    <div className="form__column full">
                                        <Field
                                            component={RefundMethods}
                                            name="refund_method"
                                            type="hidden"
                                            validate={[requiredFieldValidation]}
                                            className="form__textfield"
                                            primary={true}
                                            cancelInfo={service_check_cancel_data}
                                        />
                                    </div>
                                </div>

                                {isCashAllowedOrSelected(formValues) ? (
                                    <div className="cancelService__bankDetails__header">
                                        <div className="cancelService__bankDetails__heading">Backup Bank Account Details</div>
                                        <Tip
                                            className="cancelService__bankDetails__tooltip"
                                            info="These details are required in case your preferred refund method doesn't go through or if any of your services were paid via Bank Account"
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}

                        {cancel.service !== 'vps' ? (
                            <>
                                {!!service_check_cancel_data?.[0] && (isCashAllowedOrSelected(formValues) || this.state.relatedServicesRefundable) ? (
                                    <>
                                        <BankDetails requiredFields={['account_name']} />
                                        <DialogNotification type="warning">
                                            PLEASE NOTE: Refunded amounts will be paid on a transaction basis rather than a lump sum. Please allow 3-5
                                            business days for the funds to reach your bank account.
                                        </DialogNotification>{' '}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {properRefundTableMatrix && service_check_cancel_data.length > 1 ? <> {renderAdditionalServicesFields()} </> : <></>}
                        <div className="cancelService__tooltip">
                            {cancel.service !== 'vps' && calculateEstimated() > 0 ? (
                                <Tooltip
                                    direction="up"
                                    distance={15}
                                    arrowSize={5}
                                    content={
                                        <div className="cancelService__tooltip--container">
                                            <div className="cancelService__tooltip--content" style={{ width: '300px' }}>
                                                Note: The price below may not include any discounts that were applied, our team will verify and adjust
                                                accordingly. Please ensure you are referring to your original invoices when performing your own
                                                calculations.
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="cancelService__estimatedPrice">Estimated Refund Amount: ${calculateEstimated().toFixed(2)}</div>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </div>

                        <AcknowledgementBox title="Confirmation" titleNoPadding>
                            <Flex direction="column" gap={4}>
                                <Field
                                    name="confirm_delete"
                                    component={CheckBoxList.Item.ReduxForm}
                                    validate={[requiredAcceptedValidation]}
                                    type="checkbox"
                                    className="form__textfield"
                                >
                                    I confirm all data stored as part of this service, including backups, will be deleted.
                                </Field>
                                <Field
                                    name="confirm_backup"
                                    component={CheckBoxList.Item.ReduxForm}
                                    validate={[requiredAcceptedValidation]}
                                    type="checkbox"
                                    className="form__textfield"
                                >
                                    I confirm any data I require is backed up externally
                                </Field>
                                {cancel && cancel.service && cancel.service === 'webHosting' && (
                                    <Field
                                        name="confirm_dns"
                                        component={CheckBoxList.Item.ReduxForm}
                                        validate={[requiredAcceptedValidation]}
                                        type="checkbox"
                                        className="form__textfield"
                                    >
                                        I understand that any nameservers / DNS linked to this hosting service will no longer work
                                    </Field>
                                )}
                            </Flex>
                        </AcknowledgementBox>
                    </Fragment>
                );

            return '';
        };

        const properRefundTableMatrix = getProperRefundTableMatrix();
        /*   RENDER COMPONENT
         **********************************************************************************************************/

        const primaryTitle = () => {
            let product = null,
                name = null;
            switch (cancel.service) {
                case 'vps':
                    product = vps_information_data.included[0].attributes.name;
                    name = vps_information_data.attributes.name;
                    break;
                case 'emailHosting':
                    product = email_information_mailbox_data.included[0].attributes.name;
                    name = email_information_mailbox_data.attributes.domain || 'Unconfigured';
                    break;
                case 'gsuite':
                    product = gsuite_service_data.included[0].attributes.name;
                    name = gsuite_service_data.attributes.domain;
                    break;
                case 'ms365':
                    product = ms365_information_data.included[0].attributes.name;
                    name = ms365_information_data.attributes.name;
                    break;
                case 'webHosting':
                default:
                    product = hosting_information_data.attributes.product.name;
                    name = hosting_information_data.attributes.name;
                    break;
            }
            return (
                <div className="cancelService__info">
                    <span className="cancelService__primary--name">{name} / </span>
                    <span className="cancelService__primary--product">{product}</span>
                </div>
            );
        };

        return loadedService() ? (
            <>
                {primaryTitle()}
                <form className="cancelService__form cancelServiceForm" onSubmit={handleSubmit}>
                    <Fragment>
                        {handleFormRender()}

                        <ReduxFormButton form={formName} onClick={handleCancelSubmit}>
                            Continue
                        </ReduxFormButton>
                    </Fragment>
                </form>
            </>
        ) : (
            <RequestLoader />
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
OverlayForm = reduxForm({
    form: formName,
    enableReinitialize: true,
    touchOnChange: true
})(OverlayForm);

const mapStateToProps = (state, props) => {
    const selector = formValueSelector(formName);
    const primaryReason = selector(state, 'primaryReason');
    const formValues = getFormValues(formName)(state);

    const initialValues = {
        type: 'Immediate',
        confirm_delete: false,
        confirm_backup: false
    };

    if (props.cancel && props.cancel.service && props.cancel.service === 'webHosting') initialValues.confirm_dns = false;

    return {
        form: formName,
        primaryReason,
        formValues,
        initialValues,
        service_check_cancel_data: state.cancel.service_check_cancel_data,
        hosting_information_data: state.hosting.hosting_information_data,
        gsuite_service_data: state.gsuite.gsuite_service_data,
        email_information_mailbox_data: state.email.email_information_mailbox_data,
        vps_information_data: state.vps.vps_information_data,
        ms365_information_data: state.ms365.ms365_information_data
    };
};

export default withBrandStore(
    withRouter(
        connect(mapStateToProps, {
            cancelVPS,
            checkCancellationInfo,
            submitCancellationRequest,
            getSelectedVPS,
            getHostingInformation,
            getEmailMailboxInformation,
            getMs365Service,
            getGSuiteList,
            getEmailList,
            getDomainsList,
            getMs365List
        })(OverlayForm)
    )
);
