/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams, useRouter, useSearch } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import React, { Suspense, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { SectionModuleContext } from 'utilities/methods/sectionRendering';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Store } from '@tanstack/react-store';
import type { DevelopmentModuleObject, ModuleObject } from 'components/SideBarPage/utilities';

import { ModuleErrorBoundary } from 'components/Errors/ModuleErrorComponent';
import { useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';
import Text from 'components/Utils/Text';

import { DevelopmentDebugModuleRenderer } from 'components/ModuleRenderer/DevelopmentDebugModuleRenderer';
import { ModuleStoreContext } from 'components/ModuleRenderer/context';
import { getRouteConfig } from 'router/utils/getRouteConfig';
import { resolveRouteConfig } from 'router/utils/resolveRouteConfig';
import './_ModuleRenderer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type ModuleRenderer<TStore extends Store<any, any>> = React.FC<{
    /**
     * Component to render if the conditions (based on the condition and pathname) are met.
     */
    component: React.ComponentType;

    /**
     * Callback to select a ModuleObject from the store passed to useModuleRenderer. The enabled property
     * is used to determine if the module should be rendered.
     *
     * Note that the pathname is passed as a separate prop because at runtime, the pathname in the store will be modified by runtime conditions
     */
    condition: (state: NoInfer<TStore>['state']) => ModuleObject;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const createModuleRenderer = <TStore extends Store<any, any>>(store: TStore) => {
    /**
     * ModuleRenderer - A component for rendering modules on a page based on the passed props.
     */
    const ModuleRenderer: ModuleRenderer<TStore> = ({ component: Component, condition: selector }) => {
        /***** HOOKS *****/
        const { enabled, to, hash, reason } = useStore<any, DevelopmentModuleObject, any>(store, selector);
        const { params, matchesPathParams } = useParams({
            strict: false,
            select: (params) => ({
                params,
                matchesPathParams: to
                    .split('/')
                    .filter((param) => param.startsWith('$'))
                    .map((param) => param.replace('$', ''))
                    .every((param) => Object.keys(params).includes(param))
            })
        });
        const { module: visibleModule } = useSearch({ from: '/' });
        const { buildLocation } = useRouter();
        const { value: isDevTabEnabled } = useSuperUserActionKey('TOGGLE_MODULE_REASON');

        /***** MEMOIZATION *****/
        // buildLocation ~ 0.5ms - optimized re-calculation
        const { href } = useMemo(() => buildLocation({ to, hash, params }), [hash, params, params]);

        const isRouteActive = () => {
            const retrievedConfig = getRouteConfig({ path: to, hash });
            const resolvedConfig = resolveRouteConfig(retrievedConfig, params);

            return resolvedConfig.active;
        };

        /***** RENDER *****/
        return (
            <ModuleStoreContext.Provider value={{ hash, href, reason }}>
                {(() => {
                    switch (true) {
                        case !isRouteActive():
                            return <DevelopmentDebugModuleRenderer.BuiltLocationDisabledInConfig />;
                        case !matchesPathParams:
                            return <DevelopmentDebugModuleRenderer.MismatchedPathParams />;
                        case !enabled:
                            return <DevelopmentDebugModuleRenderer.StoreCondition />;
                        case !!visibleModule && visibleModule !== hash:
                            return <DevelopmentDebugModuleRenderer.SearchParamModule />;
                        default: {
                            return (
                                <ModuleErrorBoundary>
                                    <Suspense fallback={<NXBox initialStatus="loading" />}>
                                        {isDevTabEnabled && (
                                            <Text white size--xs bold className="DevelopmentDebugModuleRenderer__tab">
                                                #{hash}
                                            </Text>
                                        )}
                                        <SectionModuleContext.Provider value={{ path: href }}>
                                            {/* Inject path in case legacy components still expect this to be coming from the props */}
                                            {/* @ts-ignore */}
                                            <Component path={to} />
                                        </SectionModuleContext.Provider>
                                    </Suspense>
                                </ModuleErrorBoundary>
                            );
                        }
                    }
                })()}
            </ModuleStoreContext.Provider>
        );
    };

    /***** RENDER *****/
    return ModuleRenderer;
};
