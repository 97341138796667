/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useEffect } from 'react';

const _newPageTrackerData = new Store<Record<KatanaNamespace.PageID, true>>({});

/**
 * This hook checks if the provided id is in the new data and returns a boolean
 */
const useIsNewPageData = (id: KatanaNamespace.PageID | null) => useStore(_newPageTrackerData, (state) => id && Boolean(state[id]));

function addNewPageTrackerID(id: KatanaNamespace.PageID) {
    _newPageTrackerData.setState((value) => ({ ...value, [id]: true }));
}

function removeNewPageTrackerID(id: KatanaNamespace.PageID) {
    _newPageTrackerData.setState((value) => ({ ...value, [id]: false }));
}

/**
 * When the hook mounts it checks if the id is in the "newPageTrackerData" writable, if it is, when the component unmounts, the value gets removed.
 */
function useNewPageVisitedEffect(id: KatanaNamespace.PageID | null) {
    /***** HOOKS *****/
    const isIdNew = useIsNewPageData(id);

    /***** EFFECTS *****/
    useEffect(() => {
        if (isIdNew && id) {
            return () => {
                _newPageTrackerData.setState((data) => {
                    const cloned = _.clone(data);
                    delete cloned[id];
                    return cloned;
                });
            };
        }
    }, [isIdNew, id]);
}

export const newPageTrackerData = Object.assign(_newPageTrackerData, {
    useIsNew: useIsNewPageData,
    addNewID: addNewPageTrackerID,
    removeID: removeNewPageTrackerID,
    useVisited: useNewPageVisitedEffect
});
