import { useMutation } from '@tanstack/react-query';
import { pageOrganisersOpenState } from 'containers/katana/components/pageOrganiser/consts';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { page } from 'containers/katana/queries/serviceID/page';
import { pages } from 'containers/katana/queries/serviceID/pages';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.DELETE.Params;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: Params['serviceID']) {
    /***** FUNCTIONS *****/
    async function onMutate(pageID: Params['pageID']) {
        await Promise.all([
            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages(serviceID)
            }),
            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID)
            })
        ]);

        const { restore: restorePages } = pages.optimistic.filter(serviceID, 'data', (data) => data.id !== pageID);

        newPageTrackerData.removeID(pageID);

        pageOrganisersOpenState.setState((prevState) => {
            const newState = {
                ...prevState
            };

            delete newState[pageID];

            return newState;
        });

        page.removeQueries({ serviceID, pageID });

        return {
            restore: () => {
                restorePages();
            }
        };
    }

    function handleMutation(pageID: Params['pageID']) {
        return KATANA_API.katana.site.service_id.pages.page_id.DELETE({ serviceID, pageID });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        onMutate,
        mutationFn: handleMutation,
        onSuccess: (e, pageID) => {
            newPageTrackerData.removeID(pageID);
            handleDefaultSuccessNotification(e);
        },
        onError: handleDefaultErrorNotification,

        onSettled: (data, error, pageID) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID({ serviceID, pageID }),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.navigation(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true
            });
        }
    });
}

export const deletePage = Object.freeze({
    useMutation: _useMutation
});
