/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { Radio } from 'components/Form/Radio';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useApplyCreditToInvoiceMutation } from 'containers/billing/queries/invoice';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDataFromSuccessResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectedPaymentMethodProps } from './types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import WalletIcon from 'assets/images/account/wallet.svg';
import { PAYMENT_METHODS } from './consts';

const defaultInvoiceData = {
    attributes: {
        amount_due: '0.00',
        credit: '0.00',
        wallet: {
            account_credit: '0.00',
            used_account_credit: null,
            referral_credit: '0.00',
            used_referral_credit: '0.00'
        }
    }
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ApplyCreditPaymentUtility: React.FC<SelectedPaymentMethodProps> = ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    invoiceID,
    mutationOptions
}) => {
    /***** QUERIES *****/
    const {
        data: get_invoice_data,
        isLoading: isGetInvoiceLoading,
        isFetching: isGetInvoiceFetching
    } = useGetInvoiceQuery(invoiceID, { initialData: defaultInvoiceData });

    const { status: apply_credit_to_invoice_status, mutate: mutateApplyCreditToInvoice } = useApplyCreditToInvoiceMutation({
        onSuccess: (response) => {
            const paymentData = getDataFromSuccessResponse(response);
            if (paymentData?.has_outstanding_balance) {
                pushNotification(response);
                return;
            }
            mutationOptions.onSuccess(response);
        }
    });

    /***** FUNCTIONS *****/

    if (isGetInvoiceLoading || !get_invoice_data) {
        return <RequestLoader />;
    }

    /***** RENDER HELPERS *****/

    const { amount_due, credit } = get_invoice_data?.attributes || defaultInvoiceData.attributes;
    const { account_credit, used_account_credit, referral_credit, used_referral_credit } =
        get_invoice_data?.attributes?.wallet ?? defaultInvoiceData.attributes.wallet;

    const walletFunds = Number(account_credit) + Number(referral_credit);

    const isApplyCreditSelected = selectedPaymentMethod === PAYMENT_METHODS.APPLY_CREDIT;

    function renderCreditButton() {
        if (apply_credit_to_invoice_status === 'pending') {
            return (
                <div className="walletMethod__lineItem">
                    <RequestLoader height={50} message="Applying credit to invoice..." />
                </div>
            );
        }

        return (
            <SolidButton type="onClick" onClick={() => mutateApplyCreditToInvoice(invoiceID)} color="primary">
                {walletFunds >= Number(amount_due) ? 'Pay Invoice' : 'Apply To Invoice'}
            </SolidButton>
        );
    }

    function renderApplyCreditSelected() {
        return (
            <div className="method__bottom">
                <div className="walletMethod__bottomContainer">
                    {isGetInvoiceFetching ? (
                        <RequestLoader />
                    ) : (
                        <>
                            <div className="walletMethod__lineItem">
                                <p className="walletMethod__lineItemTitle">Wallet balance available</p>
                                <p className="walletMethod__lineItemAmount">${walletFunds.toFixed(2)} AUD</p>
                            </div>

                            <div className="walletMethod__lineItem">
                                <p className="walletMethod__lineItemTitle">Account credit</p>
                                <p className="walletMethod__lineItemAmount">
                                    ${account_credit} AUD
                                    {used_account_credit > 0 ? <span>($-{used_account_credit})</span> : ''}
                                </p>
                            </div>

                            <div className="walletMethod__lineItem">
                                <p className="walletMethod__lineItemTitle">Referral cash</p>
                                <p className="walletMethod__lineItemAmount">
                                    ${referral_credit} AUD
                                    {used_referral_credit > 0 ? <span>($-{used_referral_credit})</span> : ''}
                                </p>
                            </div>

                            {renderCreditButton()}
                        </>
                    )}
                </div>
            </div>
        );
    }

    if (Number(credit) && walletFunds <= 0) {
        return (
            <div className="method">
                <div className="method__top method__top--locked">
                    <Flex items="center" gap={4}>
                        <PhosphorIcons.CheckCircle.Fill size={25} confirm />
                        <Text bold black size--s>
                            Account Credit
                        </Text>
                    </Flex>
                    <div className="method__top--img">
                        {isGetInvoiceFetching ? (
                            <RequestLoader className="creditAppliedLoader" height={17} width={30} fillHeight={17} />
                        ) : (
                            <Text size--s bold secondary align--right lead--none>
                                ${credit} applied
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    if (walletFunds <= 0) {
        return null;
    }

    return (
        <div key="credit" className={classNames('method', 'walletMethod', { 'method--selected': isApplyCreditSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isApplyCreditSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.APPLY_CREDIT)}
            >
                <div className="method__top--title">
                    <div className="label">VentraIP Wallet</div>
                </div>
                <img src={WalletIcon} alt="VentraIP Wallet" className="walletMethod__icon" />
            </Radio.Circle.Button>
            {isApplyCreditSelected ? renderApplyCreditSelected() : ''}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
