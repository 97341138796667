import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.ServiceID.Navigation.Items.POST.Params;
// type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.navigation.items.POST>>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: Params['serviceID']) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    function mutationFn(attributes: Params['attributes']) {
        return KATANA_API.katana.service_id.navigation.items.POST({ serviceID, attributes });
    }

    return useMutation({
        mutationFn,
        onError: handleDefaultErrorNotification,
        onSuccess: (response, variables, context) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.navigation(serviceID)
            });
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const replaceNavigationItems = Object.freeze({
    useMutation: _useMutation
});
