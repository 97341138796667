import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Filters = KatanaAPI.Katana.domains.GET.Filters;

const baseFilter: Filters = {
    page: 1,
    sort: 'desc'
};

function createQueryKey(filters: Filters) {
    return katanaQueryKeys.katana.domains(filters);
}

function createQueryOptions(filters: Filters) {
    /**
     * The order to which you pass the keys in MATTERS!!!
     * 1. queryKey
     * 2. queryFn
     * 3. initialPageParam
     * 4. getNextPageParam
     * 5. select
     */
    return infiniteQueryOptions({
        queryKey: createQueryKey(filters),
        queryFn: (queryFunctionContext) => {
            const { pageParam = filters } = queryFunctionContext;
            return KATANA_API.katana.domains.GET(pageParam).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });
        },
        initialPageParam: filters,
        getNextPageParam: (lastPage) => {
            return getNextPageParamDefault({ lastPage, filters });
        },
        select: (data) => {
            return {
                ...data,
                pages: data.pages.flatMap((page) => {
                    return page.data;
                })
            };
        }
    });
}

function invalidateQueries(filters: Filters = baseFilter) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(filters)
    });
}

function prefetchQuery() {
    queryClient.prefetchQuery(createQueryOptions(baseFilter));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * List Katana Domains
 */
function _useInfiniteQuery(filters: Filters = baseFilter) {
    return useInfiniteQuery(createQueryOptions(filters));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDomainsList = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery,
    invalidateQueries,
    prefetchQuery,
    createQueryKey,
    createQueryOptions
});
