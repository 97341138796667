/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NXUtils.PropsFrom<typeof SolidButton>>}
 */
export const _ServiceOverviewBannerButton = ({ className, children, ...props }) => {
    /***** HOOKS *****/
    const isMedium = useAppViewport(['xs', 'sm', 'md']);

    /***** RENDER HELPERS *****/
    const _className = classNames('NXServiceOverviewBannerButton', className);

    const buttonTextColor = (props.color || 'white') === 'white' ? 'S_color_text_default_base' : 'S_color_text_inverse_base';

    /***** RENDER *****/
    return (
        <SolidButton className={_className} type="anchor" width="auto" color="white" size={isMedium ? 'medium' : 'large'} {...props}>
            <Text color={buttonTextColor} medium>
                {children}
            </Text>
        </SolidButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
