import Anchor from 'components/Anchor';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import './_contactSupport.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function ContactSupport() {
    /***** HOOKS *****/
    const { supportEmail, company } = useBrandStore();

    /***** RENDER *****/
    return (
        <Padding top={10} x={4}>
            <Flex direction="column" items="center">
                <Text align--center>Send us an email and a friendly member of the {company} team will get back to you.</Text>
                <Anchor className="contactSupport__anchor" to={`mailto:${supportEmail}`}>
                    {supportEmail}
                </Anchor>
            </Flex>
        </Padding>
    );
}
