/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationBanners } from 'config/config';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import UnsuspensionForm from '../forms/unsuspensionForm';
import { HostingBanner } from './banners/hosting';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import Cancel from 'components/Cancel';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { StatusTag } from 'components/StatusTag';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { API as HostingAPI } from 'utilities/api/hosting';
import { cancelSearchHosting } from 'utilities/api/services';
import {
    createPopup,
    getDataFromSuccessResponse,
    getDaysBetween,
    getErrorFromFailResponse,
    getIncludedObjBasedOnType,
    textLowerCase,
    toLuxonDate,
    truncateText
} from 'utilities/methods/commonActions';

import { cancelServiceRequest } from 'components/Cancel/action';
import { clearSubAccountData, forceResetHostingInfo, resetHostingAccount, resetHostingState } from 'containers/hosting/state/accountActions';
import { cancelHosting, resetHostingAdmin } from 'containers/hosting/state/adminActions';
import { resetHostingConfiguration } from 'containers/hosting/state/configActions';
import { resetResellerState } from 'containers/hosting/state/resellerActions';
import { resetHostingSecurity } from 'containers/hosting/state/securityActions';
import { servicesMethods } from 'containers/services/methods';
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';
import { getHostingList, hostingSearch, resetHostingSearch, serviceUnsuspensionReason, unsuspendService } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RenderForBrands } from 'config/brandRenderer/component';
import { renderForBrands } from 'config/brandRenderer/helper';
import { getPurchaseLink } from '../consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class HostingOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_page: null,
            last_page: null,
            total_all_records: null,
            total_records: null,
            current_list_view: null,
            hosting_search_status: null,
            hosting_search_data: null,
            hosting_search_error: null,
            sortBy: null,
            orderBy: null,
            record_per_page: undefined,
            showInvoiceLightbox: false,
            invoiceId: null,
            serviceId: null,
            domain_name: null,
            showServiceRestoreLightbox: false,
            showCancelLightbox: false,
            showServiceUnsuspendLightbox: false,
            cancelServiceID: null,
            cancelServiceMeta: null,
            cancelFormFields: null,
            showCancelConfirmLightbox: false,
            currentKeyword: null,
            product: null
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.openServiceRestore = this.openServiceRestore.bind(this);
        this.closeServiceRestore = this.closeServiceRestore.bind(this);
        this.closeServiceRestoreSuccess = this.closeServiceRestoreSuccess.bind(this);

        this.openServiceUnsuspend = this.openServiceUnsuspend.bind(this);
        this.closeServiceUnsuspendLightbox = this.closeServiceUnsuspendLightbox.bind(this);

        this.openCancelLightbox = this.openCancelLightbox.bind(this);
        this.closeCancelLightbox = this.closeCancelLightbox.bind(this);
        this.openCancelConfirmLightbox = this.openCancelConfirmLightbox.bind(this);
        this.closeCancelConfirmLightbox = this.closeCancelConfirmLightbox.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
        this.showMore = this.showMore.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setShow = this.setShow.bind(this);
        this.monitorKeyword = this.monitorKeyword.bind(this);
    }

    /************ SETUP SEARCH HANDLER ***********/
    monitorKeyword(keyword = null) {
        this.setState({
            currentKeyword: keyword
        });
    }
    /********************************************/

    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceId: id
        });
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false,
            invoiceId: null
        });
    }

    closePayInvoiceSuccess() {
        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceId: null
            },
            () => {
                getHostingList();
            }
        );
    }

    /********* OPEN/CLOSE RESTORE LIGHTBOX *********/

    openServiceRestore(id, domain) {
        this.setState({
            showServiceRestoreLightbox: true,
            serviceId: id,
            domain_name: domain
        });
    }

    closeServiceRestore() {
        this.setState({
            showServiceRestoreLightbox: false,
            serviceId: null,
            domain_name: null
        });
    }

    closeServiceRestoreSuccess() {
        this.setState(
            {
                showServiceRestoreLightbox: false,
                serviceId: null,
                domain_name: null
            },
            () => {
                getHostingList();
            }
        );
    }

    /************** OPEN/CLOSE SUSPENSION LIGHTBOX **************/

    openServiceUnsuspend(id, product, domain) {
        const { serviceUnsuspensionReason } = this.props;
        // Request the service unsuspension reason
        serviceUnsuspensionReason(id);

        this.setState({
            showServiceUnsuspendLightbox: true,
            serviceId: id,
            domain_name: domain,
            product: product
        });
    }

    closeServiceUnsuspendLightbox() {
        this.setState({
            showServiceUnsuspendLightbox: false,
            serviceId: null,
            domain_name: null,
            product: null
        });
    }

    /************** OPEN/CLOSE KEEP LIGHTBOX **************/

    openCancelLightbox(id, date) {
        this.setState({
            showCancelLightbox: true,
            cancelServiceID: id,
            cancelServiceMeta: {
                check: true,
                date: date
            }
        });
    }

    closeCancelLightbox() {
        this.setState({
            showCancelLightbox: false,
            cancelServiceID: null
        });
    }

    openCancelConfirmLightbox(values) {
        const { cancelServiceMeta } = this.state;
        let displayDate = 'As soon as possible';
        if (values && values.type === 'End of Billing Period') {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const newDate = new Date(cancelServiceMeta.date);
            if (newDate.toLocaleDateString('en-GB', options) === 'Invalid Date') {
                displayDate = '';
            } else {
                displayDate = newDate.toLocaleDateString('en-GB', options);
            }
        }

        this.setState({
            showCancelLightbox: false,
            showCancelConfirmLightbox: true,
            cancelFormFields: {
                ...values,
                displayDate
            }
        });
    }

    closeCancelConfirmLightbox() {
        this.setState(
            {
                showCancelLightbox: false,
                showCancelConfirmLightbox: true
            },
            () => {
                this.setState({
                    cancelServiceID: null,
                    cancelServiceMeta: null,
                    cancelFormFields: null
                });
            }
        );
    }

    handleCancelSubmit() {
        const { cancelHosting } = this.props;
        const { cancelServiceID, cancelFormFields } = this.state;
        const attributes = {
            ...cancelFormFields
        };
        cancelHosting(cancelServiceID, attributes);
    }

    setSort(sort) {
        let params;

        this.setState(
            {
                sortBy: sort
            },
            () => {
                const { sortBy, orderBy } = this.state;
                params = {
                    sort_by: sortBy,
                    sort: orderBy
                };
                getHostingList(params);
            }
        );
    }

    setOrder(order) {
        let params;

        this.setState(
            {
                orderBy: order
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getHostingList(params);
            }
        );
    }

    setShow(recordsPerPage) {
        let params;

        this.setState(
            {
                recordsPerPage: recordsPerPage
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getHostingList(params);
            }
        );
    }

    showMore() {
        const { hosting_list_data } = this.props;
        const { current_page, last_page, sortBy, orderBy } = this.state;

        let next = current_page;
        if (current_page < last_page) {
            next++;
        }
        const params = {
            page: next,
            sort_by: sortBy,
            sort: orderBy
        };

        getHostingList(params, hosting_list_data);
    }

    componentDidMount() {
        const { checkRequiresMigration } = this.props;

        getHostingList();

        if (ventraSynMigrationBanners) checkRequiresMigration();
    }

    componentDidUpdate(prevProps) {
        const {
            hosting_list_status,
            hosting_list_meta,
            hosting_list_more_status,
            service_restore_invoice_data,
            service_restore_invoice_status,
            service_unsuspend_status
        } = this.props;

        const { closeServiceUnsuspendLightbox, openPayInvoice } = this;

        if (service_unsuspend_status === 'success' && prevProps.service_unsuspend_status === 'loading') {
            closeServiceUnsuspendLightbox();
            getHostingList();
        }

        if (hosting_list_status === 'success' && prevProps.hosting_list_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = hosting_list_meta;

            this.setState({
                current_page,
                last_page: last_page,
                total_all_records,
                total_records
            });
        }

        if (service_restore_invoice_status === 'success' && prevProps.service_restore_invoice_status === 'loading') {
            this.closeServiceRestoreSuccess();
            this.setState(
                {
                    showServiceRestoreLightbox: false,
                    serviceId: null,
                    domain_name: null
                },
                () => {
                    openPayInvoice(service_restore_invoice_data.id);
                }
            );
        }

        if (hosting_list_more_status === 'success' && prevProps.hosting_list_more_status === 'loading') {
            const { current_page, last_page, total_all_records, total_records } = hosting_list_meta;

            this.setState({
                current_page,
                last_page: last_page,
                total_all_records,
                total_records
            });
        }
    }

    render() {
        const {
            isExpressServiceManager,
            cancelServiceRequest,
            resetResellerState,
            hosting_cancel_status,
            hosting_list_more_status,
            hosting_list_status,
            hosting_list_data,
            hosting_search_status,
            hosting_search_data,
            hosting_information_data,
            hosting_subaccount_username,
            service_restore_invoice_status,
            service_unsuspension_reason_status,
            login_account_list_data,
            resetHostingState,
            resetHostingAccount,
            resetHostingSecurity,
            resetHostingConfiguration,
            resetHostingAdmin,
            forceResetHostingInfo,
            hostingSearch,
            resetHostingSearch,
            history,
            vsm_requires_migration_data,
            unsuspendService
        } = this.props;

        const {
            current_page,
            last_page,
            showInvoiceLightbox,
            showServiceRestoreLightbox,
            showServiceUnsuspendLightbox,
            invoiceId,
            serviceId,
            currentKeyword,
            product,
            domain_name
        } = this.state;

        const {
            setShow,
            closePayInvoice,
            closePayInvoiceSuccess,
            closeServiceRestore,
            closeServiceRestoreSuccess,
            monitorKeyword,
            closeServiceUnsuspendLightbox,
            openPayInvoice,
            openServiceRestore,
            openServiceUnsuspend
        } = this;

        const getLoginUrl = (id, type, mount) => {
            let apiLoginMethod;
            switch (type) {
                case 'cpanel':
                default:
                    apiLoginMethod = HostingAPI.hosting.GET.login.cpanel({ id });
                    break;
                case 'plesk':
                    apiLoginMethod = HostingAPI.hosting.GET.login.plesk(id);
                    break;
                case 'whm':
                    apiLoginMethod = HostingAPI.hosting.GET.login.whm(id);
                    break;
            }
            if (apiLoginMethod) {
                const { closePopup, goToTargetUrl } = createPopup();

                apiLoginMethod
                    .then((response) => {
                        const data = getDataFromSuccessResponse(response);
                        goToTargetUrl(data.attributes.url);
                    })
                    .catch((error) => {
                        pushNotification(getErrorFromFailResponse(error));
                        closePopup();
                    });
            }
        };

        /**
         *
         * @param {string} status - Status of the domain [Suspended, Active, ...]
         * @param {string} note
         * @param {number} id - Service ID
         * @param {Object} product - Product data
         * @param {Object} invoice - Invoice
         * @param {string} domain - Domain
         * @param {boolean} staff_manual_suspend - True if staff has manually suspended service
         */
        const renderButton = (serviceData, product, invoice) => {
            if (!serviceData.attributes) return [];

            const { id, attributes } = serviceData;

            let loginButton;
            if (invoice && invoice.id && textLowerCase(attributes.domain_status) === 'pending') {
                return {
                    label: 'Pay Invoice',
                    type: 'onClick',
                    className: 'singleAction',
                    size: 'medium',
                    onClick: (e) => {
                        e.preventDefault();
                        openPayInvoice(invoice.id);
                    }
                };
            }

            if (product.is_reseller) {
                loginButton = {
                    label: 'WHM',
                    type: 'onClick',
                    className: '',
                    color: 'warning',
                    size: 'medium',
                    onClick: (e) => {
                        e.preventDefault();
                        getLoginUrl(id, 'whm');
                    },
                    list: [
                        {
                            label: 'List Accounts',
                            type: 'onClick',
                            onClick: (e) => {
                                e.preventDefault();
                                resetHostingAccount();
                                resetHostingSecurity();
                                resetHostingConfiguration();
                                resetHostingAdmin();
                                resetResellerState();
                                clearSubAccountData();
                                forceResetHostingInfo();
                                history.push(`/my-services/hosting/${id}/reseller#subaccounts`);
                            }
                        }
                    ]
                };
            } else if (product.server_type && product.server_type.startsWith('plesk')) {
                loginButton = {
                    label: 'Login',
                    type: 'onClick',
                    className: 'loginService__button',
                    color: 'warning',
                    size: 'medium',
                    onClick: (e) => {
                        e.preventDefault();
                        getLoginUrl(id, 'plesk');
                    }
                };
            } else {
                loginButton = {
                    label: 'cPanel',
                    type: 'onClick',
                    className: 'cPanelService__button',
                    color: 'warning',
                    size: 'medium',
                    onClick: (e) => {
                        e.preventDefault();
                        getLoginUrl(id, 'cpanel');
                    }
                };
            }

            const defaultRenderButtons = {
                CANCEL: 'CANCEL'
            };
            /**
             * Renders a default panel button
             * @param {string} _button  - use defaultRenderButtons ENUM
             * @returns {import('react').ReactComponentElement} - React Element
             */
            function getDefaultRenderButton(_button) {
                switch (_button) {
                    case defaultRenderButtons.CANCEL:
                        return (
                            <Cancel
                                button={{
                                    type: 'outline',
                                    label: `Cancel Service`,
                                    size: 'medium',
                                    className: 'cancelService__button--long'
                                }}
                                cancel={{
                                    id: id,
                                    service: `webHosting`,
                                    status: attributes.domain_status,
                                    keep: {
                                        title: 'Keep Hosting Service',
                                        desc: `Are you sure you'd like to cancel this request and keep your service?`,
                                        action: (e) => {
                                            e.preventDefault();
                                            cancelServiceRequest(id, {
                                                list: getHostingList,
                                                history: () => history.push('/my-services/hosting')
                                            });
                                        }
                                    },
                                    loading: hosting_cancel_status
                                }}
                                title="Cancel Service"
                                desc="Once you cancel this service, all files, emails, databases and configurations will be deleted. To keep your data, make a full backup before cancelling your service."
                                label="Cancel Hosting Service"
                            />
                        );

                    default:
                        return <p>Unknown render button: {_button}</p>;
                }
            }

            const manageButton = {
                label: 'Manage',
                type: 'onClick',
                className: 'serviceManage__button',
                size: 'medium',
                onClick: (e) => {
                    e.preventDefault();
                    forceResetHostingInfo();
                    if (hosting_subaccount_username) resetResellerState();
                    if (hosting_information_data && hosting_information_data.id !== id)
                        resetHostingState(history, `/my-services/hosting/${id}/account#overview`);
                    else history.push(`/my-services/hosting/${id}/account#overview`);
                }
            };

            const actions = renderForBrands(['ventra']) ? [loginButton, manageButton] : [manageButton];

            switch (textLowerCase(attributes.domain_status)) {
                case 'active':
                case 'expiring':
                    return servicesMethods.table.handlePromoActionsButtons({
                        rewardTier: login_account_list_data[0]?.attributes?.reward_tier,
                        isExpressServiceManager,
                        serviceData,
                        actions,
                        category: 'webHosting',
                        history
                    });

                case 'expired':
                    return {
                        label: 'Renew',
                        type: 'onClick',
                        className: '',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            history.push(`/my-services/hosting/${id}/account#renew`);
                        }
                    };

                case 'pending cancellation':
                    return [
                        <Cancel
                            button={{ type: 'outline', label: `Cancel Service`, size: 'medium', className: 'cancelService__button--long' }}
                            key="cancel"
                            cancel={{
                                id: id,
                                service: `webHosting`,
                                status: attributes.domain_status,
                                keep: {
                                    title: 'Keep Hosting Service',
                                    desc: `Are you sure you'd like to cancel this request and keep your service?`,
                                    action: (e) => {
                                        e.preventDefault();
                                        cancelServiceRequest(id, {
                                            list: getHostingList,
                                            history: () => history.push('/my-services/hosting')
                                        });
                                    }
                                },
                                loading: hosting_cancel_status
                            }}
                            title="Cancel Service"
                            desc="Once you cancel this service, all files, emails, databases and configurations will be deleted. To keep your data, make a full backup before cancelling your service."
                            label="Cancel Hosting Service"
                        />
                    ];

                case 'suspended':
                    // start suspended -> invoice
                    if (invoice && !attributes.staff_manual_suspend) {
                        return [
                            getDefaultRenderButton(defaultRenderButtons.CANCEL),
                            {
                                label: 'Pay Invoice',
                                type: 'onClick',
                                className: 'singleAction',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    openPayInvoice(invoice.id);
                                }
                            }
                        ];
                    }
                    // End suspended -> invoice

                    // Start suspended -> manual suspension
                    if (attributes.staff_manual_suspend) {
                        return [
                            getDefaultRenderButton(defaultRenderButtons.CANCEL),
                            {
                                label: 'Unsuspend',
                                type: 'onClick',
                                className: 'singleAction unsuspendService__button',
                                size: 'medium',
                                onClick: (e) => {
                                    e.preventDefault();
                                    openServiceUnsuspend(id, product, attributes.domain);
                                }
                            }
                        ];
                    }
                    // End suspended -> manual suspension
                    return [getDefaultRenderButton(defaultRenderButtons.CANCEL)];

                case 'pending':
                    return [
                        <div className="contact__link" key="contact_link">
                            <div className="contact__note">{attributes.note} </div>
                            <Anchor
                                onClick={() => {
                                    history.push(`/support/tickets/submit`);
                                }}
                            >
                                Contact us for assistance
                            </Anchor>
                        </div>
                    ];

                case 'cancelled':
                case 'terminated':
                    if (invoice && invoice.id) {
                        return {
                            label: 'Pay Invoice',
                            type: 'onClick',
                            className: 'singleAction',
                            size: 'medium',
                            onClick: (e) => {
                                e.preventDefault();
                                openPayInvoice(invoice.id);
                            }
                        };
                    }
                    return {
                        label: 'Restore',
                        type: 'onClick',
                        className: 'singleAction',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            openServiceRestore(id, attributes.domain);
                        }
                    };

                default:
                    return false;
            }
        };

        const renderRenewal = (date, billingCycle) => {
            const renew = getDaysBetween(toLuxonDate(date, 'yyyy-MM-dd TT'));
            const renewFormat = toLuxonDate(date, 'yyyy-MM-dd TT').toFormat('ccc LLL dd y');

            if (billingCycle && billingCycle.name && billingCycle.name === 'One Time') {
                return '';
            }

            if (renew === undefined) {
                return '';
            }

            if (renew < 0 && renew !== -1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Overdue by {renew.toString().substring(1)} days</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renew === 0) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Today</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renew === 1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Tomorrow</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            } else if (renew === -1) {
                return (
                    <div className="hover__tooltip">
                        <span className="renew__text warn">Overdue Yesterday</span>
                        <span className="hover__tooltip--text">{renewFormat}</span>
                    </div>
                );
            }

            return (
                <div className="hover__tooltip">
                    <span className={`renew__text ${renew < 90 ? 'warning' : ''}`}>In {renew} days</span>
                    <span className="hover__tooltip--text">{renewFormat}</span>
                </div>
            );
        };

        const renderServiceName = (domain, product) => {
            return (
                <div className="sharedTable__column--title">
                    <div className="primary">{domain ? truncateText(domain, 35, '...') : 'Not Available'}</div>
                    <div className="secondary">{product && product.attributes ? product.attributes.name : 'Not Available'}</div>
                </div>
            );
        };

        const isLastPage = () => !!(current_page && last_page && current_page === last_page);

        const handleTableMatrix = (data) => {
            if (!Array.isArray(data)) return false;

            return data.map((service) => {
                const product = getIncludedObjBasedOnType(service.included, 'product');
                const invoice = getIncludedObjBasedOnType(service.included, 'invoice');

                const matrixItem = {
                    domain: renderServiceName(service.attributes.domain, product),
                    status: <StatusTag status={textLowerCase(service.attributes.domain_status)} className="table__solidtag" />,
                    actions: renderButton(service, product.attributes, invoice)
                };

                if (textLowerCase(service.attributes.domain_status) === 'pending') matrixItem.className = 'extended';
                else matrixItem.renews = renderRenewal(service.attributes.next_due_date, service.attributes.billing_cycle);

                return matrixItem;
            });
        };

        function renderUnsuspendLightbox() {
            if (!showServiceUnsuspendLightbox) return '';

            return (
                <OverlayLightbox
                    title="Unsuspend Web Hosting Service"
                    loading={service_unsuspension_reason_status}
                    onOpen={showServiceUnsuspendLightbox}
                    onClose={closeServiceUnsuspendLightbox}
                >
                    <UnsuspensionForm
                        closeServiceUnsuspendLightbox={closeServiceUnsuspendLightbox}
                        primary_domain={domain_name}
                        service_name={product.name}
                        onSubmit={() => {
                            return unsuspendService(serviceId);
                        }}
                    />
                </OverlayLightbox>
            );
        }

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const hostingMatrix = handleTableMatrix(servicesMethods.table.handleMatrixCondition(currentKeyword, hosting_list_data, hosting_search_data));

        const handleTableRender = () => {
            let conditionalTableProps = {
                embedded: true
            };

            if (!isExpressServiceManager) {
                conditionalTableProps = servicesMethods.table.getSharedConditionalProps({
                    title: `Manage Web Hosting`,
                    search: {
                        data: hosting_search_data,
                        placeholder: `Search for a web hosting service`,
                        status: hosting_search_status,
                        cancel: cancelSearchHosting,
                        search: hostingSearch,
                        reset: resetHostingSearch,
                        keyword: monitorKeyword
                    },
                    paginationStatus: hosting_list_more_status,
                    setShow,
                    isLastPage,
                    matrix: hostingMatrix
                });
            }

            if (isExpressServiceManager && hostingMatrix && hostingMatrix.length > 5) {
                conditionalTableProps = {
                    showAll: {
                        label: 'show all hosting',
                        status: hosting_list_status,
                        conditions: (hostingMatrix && hostingMatrix.length > 5) || !isLastPage(),
                        onClick: () => history.push('/my-services/hosting')
                    },
                    embedded: true
                };
            }

            function renderNoServiceTitle() {
                if (isExpressServiceManager || !renderForBrands(['ventra'])) return `You have no hosting services on your account.`;
                else if (currentKeyword) return 'No hosting services matched your search.';
                return `You have no hosting services on your account. Why don't you purchase some from our website!`;
            }

            return (
                <Table
                    className="service__table--hosting"
                    sort={{
                        function: getHostingList
                    }}
                    header={[
                        {
                            title: `Primary Domain / Plan`,
                            sort: `name`,
                            className: `domain`
                        },
                        {
                            title: `Status`,
                            className: `status`
                        },
                        {
                            title: `Renews`,
                            className: `renews`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={servicesMethods.table.handleLoading(hosting_list_status, hosting_search_status)}
                    matrix={isExpressServiceManager ? hostingMatrix && hostingMatrix.slice(0, 5) : hostingMatrix}
                    error={
                        <div className="noServices">
                            <div className="noServices__title">{renderNoServiceTitle()}</div>
                            {!currentKeyword && renderForBrands(['ventra']) && (
                                <SolidButton className="noServices__action" type="anchor" href={getPurchaseLink('Web Hosting')}>
                                    Purchase Web Hosting
                                </SolidButton>
                            )}
                        </div>
                    }
                    stacked={true}
                    dynamicActions={true}
                    {...conditionalTableProps}
                />
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        if (isExpressServiceManager) {
            // Return this if this component called in the Express Service Manager
            return (
                <Fragment>
                    {handleTableRender()}
                    {showInvoiceLightbox ? (
                        <OverlayLightbox
                            invoiceid={invoiceId}
                            title={`Pay Invoice #` + invoiceId}
                            onOpen={showInvoiceLightbox}
                            onClose={closePayInvoice}
                            onSuccessClose={closePayInvoiceSuccess}
                        />
                    ) : (
                        ''
                    )}
                    {showServiceRestoreLightbox ? (
                        <OverlayLightbox
                            title="Service Restoration"
                            loading={service_restore_invoice_status}
                            serviceid={serviceId}
                            onOpen={showServiceRestoreLightbox}
                            onClose={closeServiceRestore}
                            onSuccessClose={closeServiceRestoreSuccess}
                        />
                    ) : (
                        ''
                    )}
                    {renderUnsuspendLightbox()}
                </Fragment>
            );
        }

        return (
            <div className="ServiceOverview">
                {ventraSynMigrationBanners && vsm_requires_migration_data?.requires_migration && <VentraSynBanner />}

                {handleTableRender()}
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={`Pay Invoice #${invoiceId}`}
                        invoiceid={invoiceId}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}
                {showServiceRestoreLightbox ? (
                    <OverlayLightbox
                        title="Service Restoration"
                        loading={service_restore_invoice_status}
                        serviceid={serviceId}
                        onOpen={showServiceRestoreLightbox}
                        onClose={closeServiceRestore}
                        onSuccessClose={closeServiceRestoreSuccess}
                    />
                ) : (
                    ''
                )}
                {renderUnsuspendLightbox()}

                <RenderForBrands brands={['ventra']}>
                    <HostingBanner />
                </RenderForBrands>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    hosting_list_more_status: state.services.hosting_list_more_status,
    hosting_list_status: state.services.hosting_list_status,
    hosting_list_data: state.services.hosting_list_data,
    hosting_list_meta: state.services.hosting_list_meta,
    hosting_search_status: state.services.hosting_search_status,
    hosting_search_data: state.services.hosting_search_data,
    hosting_cancel_status: state.cancel.hosting_cancel_status,
    hosting_information_data: state.hosting.hosting_information_data,
    hosting_subaccount_username: state.hosting.hosting_subaccount_username,
    service_restore_invoice_data: state.services.service_restore_invoice_data,
    service_restore_invoice_status: state.services.service_restore_invoice_status,
    service_unsuspension_reason_status: state.services.service_unsuspension_reason_status,
    service_unsuspend_status: state.services.service_unsuspend_status,
    vsm_requires_migration_data: state.vsm.vsm_requires_migration_data,
    login_account_list_data: state.login.login_account_list_data
});

const mapDispatchToProps = {
    resetResellerState,
    resetHostingState,
    resetHostingAccount,
    resetHostingSecurity,
    resetHostingConfiguration,
    resetHostingAdmin,
    forceResetHostingInfo,
    cancelHosting,
    cancelServiceRequest,
    hostingSearch,
    resetHostingSearch,
    serviceUnsuspensionReason,
    unsuspendService,
    checkRequiresMigration
};

HostingOverview = connect(mapStateToProps, mapDispatchToProps)(HostingOverview);

HostingOverview = withRouter(HostingOverview);

export default HostingOverview;
