import { Input } from 'components/Form/Input';
import { CreditCardIcon } from 'containers/billing/forms/creditCardForm/CreditCardIcon';
import { useFormContext } from 'react-hook-form';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import './_numberInput.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function CreditCardNumberInput() {
    /***** CONSTS *****/
    const { watch } = useFormContext();
    const value = watch(CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name);

    /***** FUNCTIONS *****/
    function formatCardNumber(value: string) {
        //Remove non-numerics
        let numeralsOnly = value.replace(/[^0-9]/g, '');
        if (numeralsOnly.length > 16) {
            numeralsOnly = numeralsOnly.slice(0, numeralsOnly.length - 1);
        }
        const withSpaces = numeralsOnly.replace(/(\d{4})(?=\d)/g, '$1 ');
        return withSpaces;
    }

    /***** RENDER *****/
    return (
        <div className="CreditCardNumberInput">
            <Input.HookForm
                label="Card Number"
                name={CREDIT_CARD_FIELD_DATA.CARD_NUMBER.name}
                intrinsicProps={{ autoComplete: CREDIT_CARD_FIELD_DATA.CARD_NUMBER.autoComplete }}
                onChangeTransform={formatCardNumber}
            />
            <CreditCardIcon input={value} />
        </div>
    );
}
