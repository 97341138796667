import { checkIsAccountHolder } from 'containers/account/methods';
import _ from 'lodash';
import type React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { useUserData } from 'utilities/hooks/redux/useUserData';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type EnableTwoFactorCheck = React.FC<EnableTwoFactorCheckProps>;
type EnableTwoFactorCheckProps = {
    markSelfAsRequired: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Runs the logic to see whether the user is required to complete enable two factor, and marks itself as "required" if so
 */
export const EnableTwoFactorCheck: EnableTwoFactorCheck = ({ markSelfAsRequired }) => {
    /***** HOOKS *****/
    const { login_authentication_status, login_authentication_data } = useSelector((state: any) => ({
        login_authentication_status: state.login.login_authentication_status,
        login_authentication_data: state.login.login_authentication_data
    }));

    const app_user_data = useUserData();

    const isVPN = useIsVPN();

    /***** EFFECTS *****/
    useEffect(() => {
        if (isVPN) return;

        if (
            checkIsAccountHolder(app_user_data) &&
            login_authentication_status === 'success' &&
            login_authentication_data &&
            !login_authentication_data?.attributes?.two_factor_required
        ) {
            const remindLaterDate2fa = UserPreferences.getItem('REMIND_LATER_2FA_CURRENT_DATE');
            const is2faPromptDismissed = UserPreferences.getItem('IS_2FA_PROMPT_DISMISSED');

            const is2faDateNumber = _.isNumber(remindLaterDate2fa);
            const been_longer_than_7_days = is2faDateNumber ? Date.now() - remindLaterDate2fa > 1000 * 3600 * 24 * 7 : false;

            if ((!is2faDateNumber && !is2faPromptDismissed) || (been_longer_than_7_days && !is2faPromptDismissed)) {
                markSelfAsRequired();
            }
        }
    }, []);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
