export const LOGIN_INITIAL_STATE = 'login/LOGIN_INITIAL_STATE';
export const LOGIN_LOADING_UPDATE = 'login/LOGIN_LOADING_UPDATE';
export const LOGIN_AUTHENTICATION_VPN_REQUEST = 'login/LOGIN_AUTHENTICATION_VPN_REQUEST';
export const LOGIN_AUTHENTICATION_REQUEST = 'login/LOGIN_AUTHENTICATION_REQUEST';
export const LOGIN_AUTHENTICATION_SUCCESS = 'login/LOGIN_AUTHENTICATION_SUCCESS';
export const LOGIN_AUTHENTICATION_ERROR = 'login/LOGIN_AUTHENTICATION_ERROR';
export const LOGIN_2FA_CREATE = 'login/LOGIN_2FA_CREATE';
export const LOGIN_2FA_REQUEST = 'login/LOGIN_2FA_REQUEST';
export const LOGIN_2FA_SUCCESS = 'login/LOGIN_2FA_SUCCESS';
export const LOGIN_2FA_ERROR = 'login/LOGIN_2FA_ERROR';
export const LOGIN_2FA_AUTHENTICATION_REQUEST = 'login/LOGIN_2FA_AUTHENTICATION_REQUEST';
export const LOGIN_2FA_AUTHENTICATION_SUCCESS = 'login/LOGIN_2FA_AUTHENTICATION_SUCCESS';
export const LOGIN_2FA_AUTHENTICATION_ERROR = 'login/LOGIN_2FA_AUTHENTICATION_ERROR';
export const LOGIN_2FA_RECOVERY_REQUEST = 'login/LOGIN_2FA_RECOVERY_REQUEST';
export const LOGIN_2FA_RECOVERY_SUCCESS = 'login/LOGIN_2FA_RECOVERY_SUCCESS';
export const LOGIN_2FA_RECOVERY_ERROR = 'login/LOGIN_2FA_RECOVERY_ERROR';
export const LOGIN_BACKUP_REQUEST = 'login/LOGIN_BACKUP_REQUEST';
export const LOGIN_BACKUP_SUCCESS = 'login/LOGIN_BACKUP_SUCCESS';
export const LOGIN_BACKUP_ERROR = 'login/LOGIN_BACKUP_ERROR';
export const LOGIN_SECURITY_QUESTIONS_REQUEST = 'login/LOGIN_SECURITY_QUESTIONS_REQUEST';
export const LOGIN_SECURITY_QUESTIONS_SUCCESS = 'login/LOGIN_SECURITY_QUESTIONS_SUCCESS';
export const LOGIN_SECURITY_QUESTIONS_ERROR = 'login/LOGIN_SECURITY_QUESTIONS_ERROR';
export const LOGIN_RECOVERY_MANUAL_REQUEST = 'login/LOGIN_RECOVERY_MANUAL_REQUEST';
export const LOGIN_RECOVERY_MANUAL_SUCCESS = 'login/LOGIN_RECOVERY_MANUAL_SUCCESS';
export const LOGIN_RECOVERY_MANUAL_ERROR = 'login/LOGIN_RECOVERY_MANUAL_ERROR';
export const LOGIN_FORGOT_PASSWORD_REQUEST = 'login/LOGIN_FORGOT_PASSWORD_REQUEST';
export const LOGIN_FORGOT_PASSWORD_SUCCESS = 'login/LOGIN_FORGOT_PASSWORD_SUCCESS';
export const LOGIN_FORGOT_PASSWORD_ERROR = 'login/LOGIN_FORGOT_PASSWORD_ERROR';
export const LOGIN_FORGOT_EMAIL_REQUEST = 'login/LOGIN_FORGOT_EMAIL_REQUEST';
export const LOGIN_FORGOT_EMAIL_SUCCESS = 'login/LOGIN_FORGOT_EMAIL_SUCCESS';
export const LOGIN_FORGOT_EMAIL_ERROR = 'login/LOGIN_FORGOT_EMAIL_ERROR';
export const LOGIN_RESET_PASSWORD_REQUEST = 'login/LOGIN_RESET_PASSWORD_REQUEST';
export const LOGIN_RESET_PASSWORD_SUCCESS = 'login/LOGIN_RESET_PASSWORD_SUCCESS';
export const LOGIN_RESET_PASSWORD_ERROR = 'login/LOGIN_RESET_PASSWORD_ERROR';
export const LOGIN_ACCOUNT_LIST_REQUEST = 'login/LOGIN_ACCOUNT_LIST_REQUEST';
export const LOGIN_ACCOUNT_LIST_SUCCESS = 'login/LOGIN_ACCOUNT_LIST_SUCCESS';
export const LOGIN_ACCOUNT_LIST_ERROR = 'login/LOGIN_ACCOUNT_LIST_ERROR';
export const LOGIN_SECURITY_INFORMATION_REQUEST = 'login/LOGIN_SECURITY_INFORMATION_REQUEST';
export const LOGIN_SECURITY_INFORMATION_SUCCESS = 'login/LOGIN_SECURITY_INFORMATION_SUCCESS';
export const LOGIN_SECURITY_INFORMATION_ERROR = 'login/LOGIN_SECURITY_INFORMATION_ERROR';
export const SET_URL_PARAMS = 'login/SET_URL_PARAMS';
export const REMOVE_URL_PARAMS = 'login/REMOVE_URL_PARAMS';
