import logo from 'assets/images/promo/cyberMonday/cyber-monday-logo.svg';
import logoBackground from 'assets/images/promo/cyberMonday/cyber-monday-shards.png';
import title from 'assets/images/promo/cyberMonday/cyber-monday-title.svg';
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { promoBannerDismissKeys } from 'containers/dashboard/Components/consts';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_cyberMondayBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _CyberMondayBanner = () => {
    /***** HOOKS *****/
    const isDesktop = useAppViewport(['lg', 'xl']);
    const isMobile = useAppViewport(['xs']);

    /***** RENDER *****/
    return (
        <div className="cyberMondayBanner">
            <img src={logoBackground} alt="smashed whole in the screen" className="cyberMondayBanner__logoBackground" />
            <Grid columns={isDesktop ? '.7fr 1.4fr' : '1fr'} gap={isMobile ? 15 : 2} className="cyberMondayBanner__grid">
                <img src={logo} alt="Yellow and black logo" className="cyberMondayBanner__logo" />
                <Flex direction={isMobile ? 'column' : 'row'} items="center" justify="between" fullWidth className="cyberMondayBanner__content">
                    <img src={title} alt="70% off site wide" className="cyberMondayBanner__titleImage" />
                    <Flex direction="column" items="end" fullWidth>
                        <SolidButton
                            type="anchor"
                            target="_blank"
                            href="https://ventraip.com.au/blog/black-friday-cyber-monday-sale-2024/?utm_source=ccp&utm_medium=banner&utm_campaign=cybermonday2024#promotional-deals"
                            className="cyberMondayBanner__button"
                            aria-label="Learn more about the Cyber Monday sale"
                        >
                            <Text bold>LEARN MORE</Text>
                        </SolidButton>
                        <Anchor
                            href="https://ventraip.com.au/blog/black-friday-cyber-monday-sale-2024/?utm_source=ccp&utm_medium=banner&utm_campaign=cybermonday2024#promotional-deals"
                            target="_blank"
                            className="cyberMondayBanner__tcsText"
                        >
                            <Text underline bold>
                                *T's&C's apply.
                            </Text>
                        </Anchor>
                    </Flex>
                </Flex>
            </Grid>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const CyberMondayBanner = Object.assign(_CyberMondayBanner, {
    DISMISS_KEY: promoBannerDismissKeys.CYBER_MONDAY_DISMISS_KEY
});
