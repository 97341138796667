import classNames from 'classnames';
import { _FlexChild } from 'components/Utils/Flex/_Child';
import type { FlexNamespace } from 'components/Utils/Flex/types';
import { PropInjector } from 'components/Utils/PropInjector';
import { isBoolean } from 'lodash';
import React from 'react';
import './_Flex.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _Flex = React.forwardRef<HTMLDivElement, FlexNamespace.Props>(
    ({ className, gap = 2, children, direction, justify, wrap, items, alignChildren, fullWidth, fullHeight, relative, inject, style }, ref) => {
        /***** FUNCTIONS *****/
        const createClass = <TProp extends any>(prefix: string, prop: TProp, fallback: any) =>
            `${prefix}-${isBoolean(prop) || prop === '' ? fallback : direction}`;

        /***** RENDER HELPERS *****/
        const flexClasses = classNames(className, 'Flex', `Flex--gap-${gap}`, {
            [createClass('Flex--direction', direction, 'row')]: direction,
            [`Flex--align-${items}`]: items,
            [`Flex--justify-${justify}`]: justify,
            [`Flex--wrap-${wrap === true ? 'wrap' : wrap}`]: wrap,
            [`Flex--align-children-${alignChildren}`]: alignChildren,
            'Flex--fullWidth': fullWidth,
            'Flex--fullHeight': fullHeight,
            'Flex--relative': relative,
            'Flex--inject': inject
        });

        /***** RENDER *****/
        return (
            <PropInjector inject={inject} injectableProps={{ className: flexClasses, style }} injectable={children}>
                <div ref={ref} className={flexClasses} style={style}>
                    {children}
                </div>
            </PropInjector>
        );
    }
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const Flex = Object.assign(_Flex, {
    Child: _FlexChild
});

export { Flex };
