/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Border } from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import './_ShowAll.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   isExpressServiceManager: boolean;
 *   isLoading: boolean;
 *   totalRows: number;
 *   onClick: () => void;
 *   numberOfRows: number;
 * }} props
 */
export const ShowAll = ({ isExpressServiceManager, isLoading, totalRows, numberOfRows, onClick }) => {
    /***** RENDER *****/
    if (isLoading) return null;
    if (!totalRows) return null;
    if (isExpressServiceManager) return null;
    if (totalRows <= numberOfRows) return null;
    if (!numberOfRows && totalRows <= 10) return null;

    return (
        <Border top>
            <button onClick={onClick} className="expressServiceManager__showAll">
                <Text primary align--center uppercase size--s medium>
                    Show All
                </Text>
            </button>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
