/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _SuperUserDVD } from 'components/StaffMenu/SuperUser/components/dvd';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & {
 *      iframeRef?: React.RefObject<HTMLIFrameElement>;
 * }>}
 */
const NXIframe = (props) => {
    const { height, width, title, iframeRef, ...iframeProps } = props;

    /***** RENDER *****/
    return (
        <>
            <iframe height={height} width={width} title={title} ref={iframeRef} {...iframeProps} />
            <_SuperUserDVD />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { NXIframe };
