import type { AlternateBrandComponentTokens, AlternateBrandPrimitiveTokens, AlternateBrandSemanticTokens } from './types';
import type { __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ as ventraTokens } from './ventra';

type IntaserveSemantic = AlternateBrandSemanticTokens<typeof ventraTokens.semantic, typeof intaservePrimitive>;
type IntaserveComponent = AlternateBrandComponentTokens<typeof ventraTokens.component, typeof intaservePrimitive, typeof intaserveSemantic>;

export const intaservePrimitive = {
    'P_color_primary': '#1256fa',
    'P_color_primary_hover': '#104bd5',
    'P_color_secondary': '#e0dfe6',
    'P_color_secondary_hover': '#e0dfe6',
    'P_color_tertiary': '#f1f1fb',
    'P_color_tertiary_hover': '#e3e3f7',
    'P_color_confirm': '#00cc66',
    'P_color_confirm_hover': '#00cc66',
    'P_color_info': '#1256fa',
    'P_color_info_hover': '#1256fa',
    'P_color_notice': '#f76d36',
    'P_color_notice_hover': '#f76d36',
    'P_color_warn': '#f73660',
    'P_color_warn_hover': '#f73660',
    'P_color_white': '#FFFFFF',
    'P_color_black': '#000000',
    'P_color_grey': '#919ba7',
    'P_border-radius_default': '10px',
    // Above here are "compulsory", below are additional

    'P_color_lilac_600': '#d8d8eb',
    'P_color_lilac_700': '#837f9b',
    'P_color_lilac_800': '#605d7f',
    'P_color_navy_500': '#12143e',
    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_150': '#e6e6f1',
    'P_color_grey_275': '#d7dfe3',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_500': '#d8d7e0',
    'P_color_grey_550': '#d0d0df',
    'P_color_grey_600': '#a0a1b6',
    'P_color_grey_800': '#686868',
    'P_color_transparent': 'transparent',
    'P_color_skyBlue_350': '#e5f1ff',
    'P_color_blue_100': '#e3ecff',
    'P_color_blue_200': '#adc1f0',
    'P_color_blue_300': '#779df5',
    'P_color_orange_100': '#fdf1da',
    'P_color_orange_400': '#fd9727',
    'P_color_pink_400': '#e72564',
    'P_color_purple_400': '#9a30ae',
    'P_color_aqua_400': '#159587',
    'P_color_green_400': '#8cc252',
    'P_color_violet_400': '#663fb4',

    'P_border-width_1': '1px',
    'P_border-width_3': '3px',

    'P_border-radius_2': '2px',
    'P_border-radius_4': '4px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_7': '7px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',

    'P_margin_20': '20px',

    'P_box-shadow_none': 'none',

    'P_padding_30': '30px'
} satisfies AlternateBrandPrimitiveTokens;

export const intaserveSemantic = {
    'S_color_background_primary_base': 'P_color_primary',
    'S_color_background_primary_hover': 'P_color_primary_hover',
    'S_color_background_secondary_base': 'P_color_secondary',
    'S_color_background_secondary_hover': 'P_color_secondary_hover',
    'S_color_background_secondary_light': 'P_color_white',
    'S_color_background_secondary-alt_base': 'P_color_secondary',
    'S_color_background_secondary-alt_hover': 'P_color_secondary',
    'S_color_background_tertiary_base': 'P_color_tertiary',
    'S_color_background_tertiary_hover': 'P_color_tertiary_hover',
    'S_color_background_confirm_base': 'P_color_confirm',
    'S_color_background_confirm_hover': 'P_color_confirm_hover',
    'S_color_background_info_base': 'P_color_info',
    'S_color_background_info_hover': 'P_color_info_hover',
    'S_color_background_notice_base': 'P_color_notice',
    'S_color_background_notice_hover': 'P_color_notice_hover',
    'S_color_background_warn_base': 'P_color_warn',
    'S_color_background_warn_hover': 'P_color_warn_hover',
    'S_color_background_default_base': 'P_color_white',
    'S_color_background_inverse_base': 'P_color_black',
    'S_color_background_black_base': 'P_color_black',
    'S_color_background_grey_base': 'P_color_grey',
    'S_color_text_primary_base': 'P_color_navy_500',
    'S_color_text_primary_hover': 'P_color_navy_500',
    'S_color_text_secondary_base': 'P_color_lilac_800',
    'S_color_text_secondary_hover': 'P_color_lilac_800',
    'S_color_text_confirm_base': 'P_color_confirm',
    'S_color_text_confirm_hover': 'P_color_confirm_hover',
    'S_color_text_info_base': 'P_color_info',
    'S_color_text_info_hover': 'P_color_info',
    'S_color_text_notice_base': 'P_color_notice',
    'S_color_text_notice_hover': 'P_color_notice_hover',
    'S_color_text_warn_base': 'P_color_warn',
    'S_color_text_warn_hover': 'P_color_warn_hover',
    'S_color_text_default_base': 'P_color_navy_500',
    'S_color_text_inverse_base': 'P_color_white',
    'S_color_text_inverse_light': 'P_color_secondary',
    'S_color_text_grey_base': 'P_color_grey',
    'S_color_icon_primary_base': 'P_color_primary',
    'S_color_icon_secondary_base': 'P_color_lilac_800',
    'S_color_icon_confirm_base': 'P_color_confirm',
    'S_color_icon_notice_base': 'P_color_notice',
    'S_color_icon_info_base': 'P_color_info',
    'S_color_icon_warn_base': 'P_color_warn',
    'S_color_icon_default_base': 'P_color_white',
    'S_color_icon_inverse_base': 'P_color_black',
    'S_color_icon_grey_base': 'P_color_grey',
    'S_color_border_primary_base': 'P_color_primary',
    'S_color_border_secondary_base': 'P_color_secondary',
    'S_color_border_secondary-alt_base': 'P_color_secondary',
    'S_color_border_confirm_base': 'P_color_confirm',
    'S_color_border_info_base': 'P_color_info',
    'S_color_border_notice_base': 'P_color_notice',
    'S_color_border_warn_base': 'P_color_warn',
    'S_color_border_default_base': 'P_color_grey_500',
    'S_color_border_grey_base': 'P_color_grey',
    'S_color_border_black_base': 'P_color_black',
    'S_color_border_white_base': 'P_color_white',
    'S_color_border_transparent': 'P_color_transparent',
    'S_border-radius_default': 'P_border-radius_default',
    'S_border-radius_rounded': 'P_border-radius_50'
} satisfies IntaserveSemantic;

export const intaserveComponent: IntaserveComponent = {
    Subnav: {
        'C_Subnav_color_background_base': 'S_color_background_tertiary_base',
        'C_Subnav_color_background_hover': 'S_color_background_tertiary_hover',
        'C_Subnav_color_background_active': 'S_color_background_tertiary_base',
        'C_Subnav_color_background_activeMobile': 'S_color_background_tertiary_base',
        'C_Subnav_color_text_base': 'S_color_text_secondary_base',
        'C_Subnav_color_text_hover': 'S_color_text_secondary_base',
        'C_Subnav_color_text_active': 'P_color_primary',
        'C_Subnav_color_border': 'S_color_border_transparent',
        'C_Subnav__option--activeBefore_color_background': 'P_color_transparent',
        'C_Subnav__option_border-bottom-width_selected': 'P_border-width_3',
        'C_Subnav__option_color_border_selected': 'S_color_border_primary_base'
    },
    Title: {
        'C_Title_color_background': 'P_color_white',
        'C_Title_color_border': 'S_color_border_default_base',
        'C_Title_border-width': 'P_border-width_1',
        'C_Title_borderRadius': 'P_border-radius_7',
        'C_Title_border-top-radius': 'P_border-radius_7',
        'C_Title_margin-top': 'P_margin_20',
        'C_Title_backButtonContainer_color_background': 'P_color_transparent',
        'C_Title_backButton_color_background': 'S_color_background_tertiary_base',
        'C_Title_backButton_color_text': 'S_color_text_primary_base',
        'C_Title_backButton_color_border': 'P_color_transparent'
    },
    OutlineButton: {
        'C_BaseButton_border-radius_default': 'P_border-radius_5',
        'C_OutlineButton_color_border_primary': 'P_color_navy_500',
        'C_OutlineButton_color_background_primary_hover': 'P_color_navy_500',
        'C_OutlineButton_color_text_primary_base': 'S_color_text_primary_base',
        'C_OutlineButton_color_text_primary_hover': 'S_color_text_inverse_base',
        'C_OutlineButton_color_border_secondary': 'P_color_lilac_700',
        'C_OutlineButton_color_background_secondary_hover': 'P_color_lilac_700',
        'C_OutlineButton_color_text_secondary_base': 'P_color_lilac_700',
        'C_OutlineButton_color_text_secondary_hover': 'S_color_text_inverse_base'
    },
    SolidButton: {
        C_SolidButton_color_background_secondary: 'S_color_background_secondary_base',
        C_SolidButton_color_background_secondary_hover: 'S_color_background_secondary_hover',
        C_SolidButton_color_text_secondary_base: 'S_color_text_primary_base',
        C_SolidButton_color_text_secondary_hover: 'S_color_text_primary_base'
    },
    InactiveButton: {
        C_InactiveButton_color_text: 'P_color_grey_600',
        C_InactiveButton_color_background: 'P_color_grey_150',
        C_InactiveButton_color_border: 'P_color_grey_550'
    },
    Sidebar: {
        'C_Sidebar_color_border': 'S_color_border_default_base',
        'C_Sidebar_color_background': 'P_color_transparent',
        'C_Sidebar__header_color_background': 'S_color_background_tertiary_base',
        'C_Sidebar__header_color_text': 'S_color_text_default_base',
        'C_Sidebar__heading_color_text': 'S_color_text_default_base',
        'C_Sidebar__listItem_color_background_hover': 'S_color_background_tertiary_base',
        'C_Sidebar__listItem_color_background_active': 'P_color_navy_500',
        'C_Sidebar__listItem_color_text_base': 'S_color_text_secondary_base',
        'C_Sidebar__listItem_color_text_hover': 'S_color_text_secondary_base',
        'C_Sidebar__listItem_color_text_active': 'S_color_text_inverse_base',
        'C_Sidebar__listItem--selected_color': 'P_color_transparent',
        'C_Sidebar__listItemInner_border-radius': 'P_border-radius_4'
    },
    MobileSidebar: {
        'C_MobileSidebar_margin-top': 'P_margin_20'
    },
    NXBox: {
        'C_NXBox_border-radius_default': 'S_border-radius_default',
        'C_NXBox_border-radius_defaultMobile': 'P_border-radius_15',
        'C_NXBox_color_border': 'P_color_transparent',
        'C_NXBox__subheading_color_text': 'S_color_text_primary_base',
        'C_NXBoxSplit__section_color_border': 'P_color_transparent',
        'C_NXBoxBottom__container_color_background': 'P_color_white',
        'C_NXBoxBottom_padding': 'P_padding_30',
        'C_NXBoxBottom_border-radius_default': 'P_border-radius_7',
        'C_NXBoxInfoPair__title_color_text': 'P_color_lilac_700'
    },
    NXTable: {
        'C_NXTable__subHeading_color_background': 'S_color_background_tertiary_hover',
        'C_NXTable_color_background': 'S_color_background_tertiary_base',
        'C_NXTable__row_color_text': 'S_color_text_primary_base',
        'C_NXTable__row_color_border': 'S_color_border_default_base',
        'C_NXTable__row_border-width': 'P_border-width_1',
        'C_NXTableRow_color_background': 'S_color_background_tertiary_base',
        'C_NXTableRow__alternator_color_background': 'S_color_background_tertiary_base',
        'C_NXTableRow__alternator_color_background_mobile': 'S_color_background_tertiary_base',
        'C_NXTableColumnHeading_color_text': 'P_color_lilac_700',
        'C_NXTableNoServices_color_background': 'S_color_background_tertiary_hover'
    },
    SelectDropdown: {
        'C_Select__listItem--selected_color_background': 'S_color_background_tertiary_base',
        'C_Select__listItem--focus_color_background': 'S_color_background_tertiary_hover',
        'C_SelectDropdown_color_background': 'S_color_background_secondary_base',
        'C_SelectDropdown_color_text': 'S_color_text_default_base',
        'C_SelectDropdown_color_border': 'S_color_border_default_base'
    },
    Input: {
        'C_Input_border-radius': 'P_border-radius_5',
        'C_Input_color_background': 'P_color_white',
        'C_Input_color_text': 'S_color_text_primary_base',
        'C_Input_color_border': 'S_color_border_default_base',
        'C_Input_border-width': 'P_border-width_1',
        'C_Input__disabled_color_text': 'P_color_grey_400'
    },
    Accordion: {
        C_Accordion_color_background: 'S_color_background_default_base',
        C_Accordion_color_background_hover: 'S_color_background_tertiary_hover',
        C_Accordion_color_background_active: 'S_color_background_primary_base',
        C_Accordion_color_text: 'S_color_text_default_base'
    },
    PricingTable: {
        C_PricingTable_color_background: 'P_color_transparent'
    },
    twoFARadioButton: {
        C_2FARadioButton_color_background: 'S_color_background_default_base',
        C_2FARadioButton_color_background_hover: 'S_color_background_tertiary_base',
        C_2FARadioButton_color_background_selected: 'S_color_background_default_base',
        C_2FARadioButton_color_border_selected: 'S_color_background_primary_base'
    },
    Anchor: {
        C_Anchor_color_text_base: 'S_color_text_info_base',
        C_Anchor_color_text_hover: 'S_color_text_info_hover'
    },
    Checkbox: {
        'C_Checkbox_border-radius_default': 'P_border-radius_2',
        'C_Checkbox_color_border': 'P_color_grey_80'
    },
    StyledLoader: {
        C_StyledLoader_color_background: 'S_color_background_tertiary_hover'
    },
    Tabs: {
        C_Tabs_color_background: 'S_color_background_default_base'
    },
    SolidTag: {
        'C_BaseTag_border-radius': 'P_border-radius_5',
        'C_SolidTag_color_text': 'P_color_white'
    },
    Tooltip: {
        'C_Tooltip__content_color_background': 'P_color_grey_800',
        'C_Tooltip_border-radius': 'P_border-radius_6'
    },
    Search: {
        'C_Search_color_background': 'P_color_white',
        'C_Search_border-radius': 'P_border-radius_5'
    },
    RenderLogoOrBrandFont: {
        'C_RenderLogoOrBrandFont_border-radius': 'P_border-radius_6'
    },
    HighlightBadge: {
        C_HighlightBadge_color_outline: 'P_color_primary'
    },
    accounts: {
        'C_accounts__indicator_color_background': 'P_color_blue_100',
        'C_accounts__dropdown--name_color_text': 'S_color_text_default_base'
    },
    descriptionText: {
        C_descriptionText_color_text: 'S_color_text_secondary_base'
    },
    rangeSlider: {
        C_rangeslider_color_background: 'P_color_blue_100'
    },
    changeResourcesForm: {
        C_changeResourcesFormAllocations__names_color_background: 'P_color_grey_800',
        C_changeResourcesFormAllocations__currentValues_color_background: 'P_color_grey_100',
        C_changeResourcesFormAllocations__currentValuesHeading_color_background: 'P_color_grey_300',
        C_changeResourcesFormAllocations__newValues_color_background: 'P_color_blue_200',
        C_changeResourcesFormAllocations__newValuesHeading_color_background: 'P_color_blue_300'
    },
    hostingDetails: {
        C_hostingDetails__tabSelectorOption_color_background_hover: 'S_color_background_tertiary_hover',
        C_hostingDetails__tabSelectorOption_color_background_selected: 'P_color_lilac_800',
        C_hostingDetails__tabSelectorOption_color_text_selected: 'P_color_white'
    },
    accountLoginHistory: {
        C_accountLoginHistory__tableHeader_color_text: 'S_color_text_default_base'
    },
    invoiceView: {
        'C_invoiceView__tag_color_background_secondary': 'P_color_grey_80',
        'C_invoiceView__info_color_background_mobile': 'P_color_transparent',
        'C_invoiceView__tag_border-radius': 'S_border-radius_default'
    },
    invoicePay: {
        'C_invoicePay__items_box-shadow': 'P_box-shadow_none'
    },
    knowledgeBase: {
        C_knowledgeBase_color_background: 'S_color_background_tertiary_base',
        C_knowledgeBase__supportCategory_color_background_hover: 'P_color_skyBlue_350',
        C_knowledgeBase__otherMethods_color_background: 'S_color_background_default_base',
        C_knowledgeBase__otherMethods_color_text: 'S_color_text_default_base'
    },
    ticketList: {
        C_ticketList__openTicket_color_background: 'S_color_background_tertiary_base'
    },
    ticketViewMessage: {
        'C_ticketViewMessage__body--customer_color_background': 'P_color_orange_100',
        'C_ticketViewMessage__body--worker_color_background': 'S_color_background_tertiary_base'
    },
    ticketForm: {
        C_ticketForm__waitTime_color_background: 'P_color_white'
    },
    paymentMethods: {
        C_paymentMethods__methodSelected_color_background: 'S_color_background_tertiary_hover'
    },
    registrantInfo: {
        C_registrantInfo_color_background_secondary: 'P_color_grey_50'
    },
    supportLink: {
        C_supportLink_color_text: 'P_color_info_hover'
    },
    dnsTag: {
        C_dnsTypeTag_color_background_pink: 'P_color_pink_400',
        C_dnsTypeTag_color_background_purple: 'P_color_purple_400',
        C_dnsTypeTag_color_background_violet: 'P_color_violet_400',
        C_dnsTypeTag_color_background_aqua: 'P_color_aqua_400',
        C_dnsTypeTag_color_background_green: 'P_color_green_400',
        C_dnsTypeTag_color_background_orange: 'P_color_orange_400',
        C_dnsAppendTag_color_background: 'P_color_grey_275'
    },
    expressServiceManager: {
        C_expressServiceManager__option_color_background: 'S_color_background_tertiary_hover',
        C_expressServiceManager__option_color_background_hover: 'P_color_lilac_600',
        C_expressServiceManager__option_color_background_active: 'P_color_navy_500',
        C_expressServiceManager__option_color_background_activeMobile: 'P_color_navy_500',
        C_expressServiceManager__option_color_background_activeBefore: 'P_color_navy_500',
        C_expressServiceManager__option_color_text: 'S_color_text_secondary_base',
        C_expressServiceManager__option_color_text_hover: 'S_color_text_secondary_base',
        C_expressServiceManager__option_color_text_active: 'S_color_text_inverse_base',
        C_expressServiceManager_color_border: 'P_color_transparent'
    },
    loginAccountSelect: {
        C_loginAccountSelect_color_background_hover: 'S_color_background_tertiary_hover'
    },
    pageOrganiser: {
        C_PageOrganiser__content_border_color: 'P_color_grey_275',
        C_PageOrganiser__tag_text_color: 'P_color_primary'
    },
    addPageRoute: {
        C_AddPageRoute__previewWrapper_color_background: 'P_color_grey_275'
    },
    richTextEditor: {
        'C_RichTextEditor__ToolbarButton_border-radius': 'P_border-radius_5',
        'C_RichTextEditor__ToolbarButton__after_border-radius': 'P_border-radius_5'
    },
    multiPageBetaTestingNotice: {
        'C_MultiPageBetaTestingNotice_border-radius': 'P_border-radius_6'
    }
};

const intaserve = {
    primitive: intaservePrimitive,
    semantic: intaserveSemantic,
    component: intaserveComponent
};

export const __DO_NOT_USE_IN_PRODUCTION_OR_YOU_WILL_BE_FIRED__INTASERVE__CONFIG__ = intaserve;
