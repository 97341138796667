import RequestLoader from 'components/Loaders/Request';
import { dotStoreOnlineAndXYZAvailabilityRequestParams } from 'components/Promo/FreeDomain/DotStoreBanner';
import { freeDotOnlinePromoCode, freeDotSitePromoCode, freeDotStorePromoCode, freeDotXyzPromoCode } from 'components/Promo/promoCodes';
import Text from 'components/Utils/Text';
import { promotions } from 'config/config';
import { checkPromoCodeEligibility, checkYearPromoCode } from 'config/containers/promotions/methods';
import { createDomainAvailabilityQueryKey, postDomainAvailability } from 'containers/domain/action';
import { useVipRewards } from 'containers/vipRewards/hooks';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUserData } from 'utilities/hooks/redux/useUserData';
import { activePromotion } from 'utilities/methods/commonActions';
import { RewardCard } from '../rewardCard';
import './_rewardCards.scss';

const promoCodes = {
    freeDotOnline2024: freeDotOnlinePromoCode,
    freeDotStore2024: freeDotStorePromoCode,
    freeDotXyz2024: freeDotXyzPromoCode,
    freeDotSite2024: freeDotSitePromoCode
};

const eligibilityPromoKeys = Object.keys(promoCodes);

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PromotionKey = keyof typeof promotions;
type Promotion = {
    start: string;
    end: string | null;
    finish?: string | null;
};
type EligibilityPromo = keyof typeof promoCodes;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function RewardCards({ title }: { title: string }) {
    /***** HOOKS *****/
    const promotion_promo_code_status_request_data = useSelector((state: any) => state.promotion.promotion_promo_code_status_request_data);
    const domain_availability_data = useSelector((state: any) => state.domain.domain_availability_data);
    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);
    const domainAvailabilityStatus: string = domain_availability_data[domainAvailabilityQueryKey]?.status;
    const domainAvailabilityData = domain_availability_data[domainAvailabilityQueryKey]?.data;

    const app_user_data = useUserData();
    const { isAtOrAboveTier } = useVipRewards();

    /***** EFFECTS *****/
    useEffect(() => {
        let shouldFetchTldPricing = false;

        if (activePromotion('freeDotXyz2024')) {
            checkYearPromoCode(freeDotXyzPromoCode, '.xyz');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotOnline2024')) {
            checkYearPromoCode(freeDotOnlinePromoCode, '.online');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotStore2024')) {
            checkYearPromoCode(freeDotStorePromoCode, '.store');
            shouldFetchTldPricing = true;
        }

        if (activePromotion('freeDotSite2024')) {
            checkYearPromoCode(freeDotSitePromoCode, '.site');
            shouldFetchTldPricing = true;
        }

        if (shouldFetchTldPricing) {
            if (!domain_availability_data[domainAvailabilityQueryKey]?.data) {
                postDomainAvailability(dotStoreOnlineAndXYZAvailabilityRequestParams, domainAvailabilityQueryKey);
            }
        }
    }, []);

    /***** RENDER HELPERS *****/
    const requiresActivePromotion = (card: string) => {
        // Return true for 'referral', otherwise check activePromotion(card)
        if (card === 'referral') {
            return true;
        }
        return activePromotion(card as PromotionKey);
    };

    //This should be the only place you need to add new promos to (other than making the cards themselves, and config.js)
    const eligibilityRules = {
        referral: () => isAtOrAboveTier('silver'),
        helloFreshNZ: () => app_user_data?.country === 'NZ',
        helloFreshAU: () => app_user_data?.country === 'AU',
        nrlGiveaway: () => isAtOrAboveTier('VIP'),
        nrlGiveaway2: () => isAtOrAboveTier('VIP'),
        coldplay: () => isAtOrAboveTier('VIP'),
        brooksAU: () => isAtOrAboveTier('VIP'),
        drakeTour: () => isAtOrAboveTier('VIP'),
        bryanAdamsTour: () => isAtOrAboveTier('VIP')
    };

    //Check each RewardCard to see if its eligible.
    const cardsToShow = Object.keys(RewardCard).filter((card) => {
        const cardLower = _.lowerFirst(card);

        if (!requiresActivePromotion(cardLower)) {
            return false;
        }

        //Only check this if its in the promoCodes list
        if (eligibilityPromoKeys.includes(cardLower)) {
            // Check if the promo is active for promo code cards and if it's eligible
            return checkPromoCodeEligibility(promoCodes[cardLower as EligibilityPromo], promotion_promo_code_status_request_data);
        }

        return eligibilityRules[cardLower as keyof typeof eligibilityRules] ? eligibilityRules[cardLower as keyof typeof eligibilityRules]() : false;
    });

    const now = new Date();
    const sortedCards = cardsToShow.sort((a, b) => {
        const aLower = _.lowerFirst(a);
        const bLower = _.lowerFirst(b);
        const promoDatesA = promotions[aLower as PromotionKey] as Promotion;
        const promoDatesB = promotions[bLower as PromotionKey] as Promotion;

        // If a promotion is not found, treat it as  inactive and push it to the end
        if (!promoDatesA && !promoDatesB) return 0;
        if (!promoDatesA) return 1;
        if (!promoDatesB) return -1;

        const aStart = new Date(promoDatesA.start);
        const bStart = new Date(promoDatesB.start);

        const aEnd = promoDatesA?.end ? new Date(promoDatesA.end) : Infinity;
        const bEnd = promoDatesB?.end ? new Date(promoDatesB.end) : Infinity;

        const aFinish = promoDatesA?.finish ? new Date(promoDatesA.finish) : null;
        const bFinish = promoDatesB?.finish ? new Date(promoDatesB.finish) : null;

        const aIsActive = aFinish === null || aFinish > now;
        const bIsActive = bFinish === null || bFinish > now;

        if (aIsActive && bIsActive) {
            return (aEnd === Infinity ? 1 : 0) - (bEnd === Infinity ? 1 : 0) || Number(bStart) - Number(aStart);
        }

        if (aIsActive && !bIsActive) {
            return -1;
        }
        if (!aIsActive && bIsActive) {
            return 1;
        }

        return (bFinish || bEnd) - (aFinish || aEnd);
    });

    const getIsLoading = () => {
        if (
            promotion_promo_code_status_request_data?.[freeDotOnlinePromoCode]?.status === 'loading' ||
            promotion_promo_code_status_request_data?.[freeDotStorePromoCode]?.status === 'loading' ||
            promotion_promo_code_status_request_data?.[freeDotXyzPromoCode]?.status === 'loading' ||
            domainAvailabilityStatus === 'loading'
        ) {
            return true;
        }

        return false;
    };

    const isLoading = getIsLoading();

    const renderCards = () => {
        if (isLoading) {
            return <RequestLoader />;
        }

        if (!sortedCards.length) {
            return (
                <Text secondary size--s>
                    Unfortunately there are no extra rewards available at this time. Please check back later.
                </Text>
            );
        }

        return React.Children.toArray(
            sortedCards.map((cardName) => {
                const CardComponent = RewardCard[cardName];

                // Check if the component needs availabilityData and pass it accordingly
                if (eligibilityPromoKeys.map((key) => key.toLowerCase()).includes(cardName.toLowerCase())) {
                    return CardComponent ? <CardComponent availabilityData={domainAvailabilityData} /> : '';
                }

                // Render without props for other components
                return CardComponent ? <CardComponent /> : '';
            })
        );
    };

    /***** RENDER *****/
    return (
        <div className="rewardCards">
            <div className="rewardCards__cardContainer">
                <Text primary size--xl semiBold>
                    <h3>{title}</h3>
                </Text>
                {renderCards()}
            </div>
        </div>
    );
}
