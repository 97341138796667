/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import ReactIs from 'react-is';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TextContext from 'components/Utils/Text/_Text/textContext';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { applyChildrenProps } from 'components/NXTable/methods';
import { createAppliedStylingClasses } from 'components/Utils/methods';
import { useTheme } from 'utilities/hooks/useTheme';
import { filterTextProps } from './utils';
/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__Text.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { TextNamespace } from 'components/Utils/Text/_Text/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Text: React.FC<TextNamespace.Props> = ({ children, className, style, span, as: component, ...props }) => {
    const { decoration, 'size--custom': manualSize, color } = props;

    /***** HOOKS *****/
    const { parentParagraph } = React.useContext(TextContext);
    const themeStyles = useTheme({
        '--Text-color': color
    });

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keysToRemove: ['children', 'className', 'style', 'span'],
        componentName: 'Text',
        delimiter: '--'
    });

    // IMPROVE: Add automatic inference of span when inside a un-nestable component, like p can't be inside p or span but span can be inside p.
    const textClassNames = classNames('Text', className, appliedStylingClasses, (children as any)?.props?.className, {
        [`Text--decoration-${decoration}`]: decoration
    });

    const finalProps = {
        className: textClassNames,
        style: {
            ...style,
            ...themeStyles,
            ...(typeof manualSize === 'number' && { fontSize: manualSize })
        }
    };

    const isValidElementType = ReactIs.isValidElementType(children);
    const typeofChild = ReactIs.typeOf(children);
    const typeIsElement = typeofChild === ReactIs.Element;
    const isChildThisComponent = (children as any)?.type?.name === '_Text';

    /***** RENDER *****/
    if (component) {
        return React.createElement(component, { ...filterTextProps(props), ...finalProps }, children);
    }

    if (span || parentParagraph) {
        return <span {...finalProps}>{children}</span>;
    }

    if (isChildThisComponent || (!typeIsElement && (!isValidElementType || !typeofChild))) {
        return (
            <TextContext.Provider value={{ parentParagraph: true }}>
                <p {...finalProps}>{children}</p>
            </TextContext.Provider>
        );
    }

    return applyChildrenProps(children, finalProps);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
