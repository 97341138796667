/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Box from 'components/Box';
import { SolidButton } from 'components/Buttons/SolidButton';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';

/*   ACTIONS
 *****************************************************/
import { getSslList, getSslProducts, purchaseSsl, resetSslPurchase } from '../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { withBrandStore } from 'config/hooks/useBrandStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import comodoimage from 'assets/images/ssl/comodo.png';
import upgradeBackgroundImage from 'assets/images/ssl/ssl_banner_background.jpeg';
import upgradeImage from 'assets/images/ssl/ssl_banner_icon.svg';
import trustwaveimage from 'assets/images/ssl/trustwave.png';

/**********************************************************************************************************
 *   SSL PURCHASE COMPONENT
 **********************************************************************************************************/
class SSLPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProductsDropdown: false,
            selectedProduct: null,
            selectedProductPrice: null,
            showInvoiceLightbox: false,
            invoiceId: null,
            productList: {}
        };
        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.toggleShowProducts = this.toggleShowProducts.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.submitProduct = this.submitProduct.bind(this);
        this.purchaseScrollRef = createRef();
    }

    /************** OPEN/CLOSE INVOICE LIGHTBOX **************/
    openPayInvoice(id) {
        this.setState({
            showInvoiceLightbox: true,
            invoiceId: id
        });
    }

    closePayInvoice() {
        const { getSslList } = this.props;

        this.setState(
            {
                showInvoiceLightbox: false,
                invoiceId: null
            },
            () => {
                resetSslPurchase();
                getSslList();
            }
        );
    }

    /************** SHOW PRODUCTS LIGHTBOX **************/
    toggleShowProducts() {
        const { getSslProducts } = this.props;
        const { showProductsDropdown } = this.state;

        this.setState(
            {
                showProductsDropdown: !showProductsDropdown
            },
            () => {
                if (!showProductsDropdown) {
                    resetSslPurchase();
                    getSslProducts();
                }
            }
        );
    }

    /************** FORM METHODS **************/
    selectProduct(id, price) {
        this.setState({
            selectedProduct: id,
            selectedProductPrice: price
        });
    }

    submitProduct() {
        const { selectedProduct } = this.state;
        const { purchaseSsl } = this.props;

        const values = {
            product_id: selectedProduct
        };
        purchaseSsl(values);
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { ssl_products_data, getSslProducts, location } = this.props;
        const { toggleShowProducts } = this;

        if (!ssl_products_data && getSslProducts) {
            getSslProducts();
        }

        if (ssl_products_data) {
            this.setState({
                productList: ssl_products_data
            });
        }

        const query = new URLSearchParams(location.search);
        const purchase = query.get('purchase');

        if (purchase) {
            toggleShowProducts();
            scroll.scrollTo(this.purchaseScrollRef.getBoundingClientRect().top);
        }
    }

    componentDidUpdate(prevProps) {
        const { ssl_purchase_status, ssl_purchase_data, ssl_products_status, ssl_products_data } = this.props;
        const { openPayInvoice } = this;

        if (ssl_products_status === 'success' && prevProps.ssl_products_status === 'loading') {
            this.setState({
                productList: ssl_products_data
            });
        }

        if (ssl_purchase_status === 'success' && prevProps.ssl_purchase_status === 'loading' && ssl_purchase_data) {
            openPayInvoice(ssl_purchase_data.id);
        }
    }

    render() {
        const { ssl_products_status, ssl_purchase_status, application } = this.props;
        const { showProductsDropdown, selectedProduct, selectedProductPrice, showInvoiceLightbox, invoiceId, productList } = this.state;
        const { selectProduct, submitProduct, toggleShowProducts, closePayInvoice } = this;

        const renderProducts = () => {
            if (productList && productList.length > 0) {
                return Object.keys(productList).map((value) => {
                    const { id, attributes } = productList[value];
                    const { name, description, price } = attributes;

                    let image;

                    if (name.startsWith('Trustwave')) {
                        image = 'trustwave';
                    } else {
                        image = 'comodo';
                    }

                    if (value <= 0 && selectedProduct === null) {
                        selectProduct(id, price);
                    }

                    return (
                        <button
                            key={`ssl-` + id}
                            className={`sslProducts__product ${selectedProduct === id ? 'selected' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                selectProduct(id, price);
                            }}
                        >
                            <div className="product__container">
                                <input
                                    className="item__radio"
                                    value={id}
                                    type="radio"
                                    id={id}
                                    name="selectedSslProduct"
                                    checked={selectedProduct === id}
                                    onChange={(e) => this.setState({ selectedProduct: e.target.value })}
                                />
                                {image === 'trustwave' ? (
                                    <img className="trustwave" src={trustwaveimage} alt="Trustwave" />
                                ) : (
                                    <img className="comodo" src={comodoimage} alt="Comodo" />
                                )}
                                <div className="product__title">
                                    <div className="label">{name}</div>
                                    <div className="desc">{description}</div>
                                </div>
                            </div>
                            <div className="product__price">${price} AUD</div>
                        </button>
                    );
                });
            }

            return;
        };

        const renderProductsList = () => {
            return (
                <div className="sslProducts__list">
                    <div className="list__container">{renderProducts()}</div>
                    <div className="list__details">
                        <div className="title">Total Amount Due</div>
                        <div className="price">${selectedProductPrice} AUD</div>
                    </div>
                    <div className="list__action">
                        <SolidButton
                            type="onClick"
                            onClick={(e) => {
                                e.preventDefault();
                                submitProduct();
                            }}
                        >
                            Purchase SSL Certificate
                        </SolidButton>
                    </div>
                </div>
            );
        };

        const handlePurchaseLoading = () => {
            if (ssl_products_status === 'loading' || ssl_purchase_status === 'loading') {
                return 'loading';
            } else if (ssl_products_status === 'error') {
                return 'error';
            }
            return 'success';
        };

        return (
            <Fragment>
                <div
                    ref={(el) => {
                        this.purchaseScrollRef = el;
                    }}
                >
                    <ServiceOverviewBanner src={upgradeBackgroundImage}>
                        <ServiceOverviewBanner.ContentWrapper>
                            <ServiceOverviewBanner.Heading>Buy SSL Certificates</ServiceOverviewBanner.Heading>
                            <ServiceOverviewBanner.Description>
                                Secure your website by purchasing and installing an SSL certificate directly within {application}.
                            </ServiceOverviewBanner.Description>
                            <ServiceOverviewBanner.Button type="onClick" onClick={toggleShowProducts}>
                                View Pricing
                            </ServiceOverviewBanner.Button>
                        </ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Hosting" />
                    </ServiceOverviewBanner>
                </div>
                <Box
                    className="sslAddCertificate__banner--popup"
                    premounted={true}
                    dropdown={{
                        title: 'Select your SSL Certificate',
                        render: renderProductsList(),
                        condition: showProductsDropdown,
                        close: toggleShowProducts,
                        status: handlePurchaseLoading()
                    }}
                />
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        invoiceid={invoiceId}
                        title={`Pay Invoice #` + invoiceId}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoice}
                    />
                ) : (
                    ''
                )}
            </Fragment>
        );
    }
}

/**********************************************************************************************************
 *   SSL PURCHASE COMPONENT END
 **********************************************************************************************************/
const mapStateToPurchaseProps = (state) => {
    return {
        ssl_products_status: state.services.ssl_products_status,
        ssl_products_error: state.services.ssl_products_error,
        ssl_products_data: state.services.ssl_products_data,
        ssl_purchase_status: state.services.ssl_purchase_status,
        ssl_purchase_error: state.services.ssl_purchase_error,
        ssl_purchase_data: state.services.ssl_purchase_data
    };
};

const mapDispatchToPurchaseProps = (dispatch) =>
    bindActionCreators(
        {
            purchaseSsl,
            resetSslPurchase,
            getSslProducts,
            getSslList
        },
        dispatch
    );

export default withBrandStore(withRouter(connect(mapStateToPurchaseProps, mapDispatchToPurchaseProps)(SSLPurchase)));
