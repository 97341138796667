import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import { isCreditCard } from 'validator';
import { z } from 'zod';

const { CARD_CVV, CARD_HOLDER_NAME, CARD_MONTH, CARD_NUMBER, CARD_YEAR } = CREDIT_CARD_FIELD_DATA;

export const schema = z.object({
    [CARD_HOLDER_NAME.name]: z.string().min(1, { message: 'Card Holder Name must contain at least 1 character' }),
    [CARD_NUMBER.name]: z.string().refine(isCreditCard, { message: 'Must be a valid credit card number' }),
    [CARD_MONTH.name]: z
        .number({ coerce: true, message: 'Required' })
        .min(1, { message: 'Month must be between 1 and 12' })
        .max(12, { message: 'Month must be between 1 and 12' }),
    [CARD_YEAR.name]: z
        .number({ coerce: true, message: 'Required' })
        .min(1, { message: 'Year must be between 1 and 99' })
        .max(99, { message: 'Year must be between 1 and 99' }),
    [CARD_CVV.name]: z
        .number({ coerce: true, required_error: 'CVV is required', invalid_type_error: 'CVV must be a number' })
        .refine((value) => String(value).length === 3 || String(value).length === 4, 'CVV must be 3 or 4 digits')
});

export type Schema = z.infer<typeof schema>;
