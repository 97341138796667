import type { VentraComponentTokens, VentraPrimitiveTokens, VentraSemanticTokens } from './types';

type VentraSematic = VentraSemanticTokens<typeof ventraPrimitive>;
type VentraComponent = VentraComponentTokens<typeof ventraPrimitive, typeof ventraSemantic>;

const ventraPrimitive = {
    'P_color_primary': '#c32888',
    'P_color_primary_hover': '#b52578',
    'P_color_secondary': '#6c7a89',
    'P_color_secondary_hover': '#677482',
    'P_color_tertiary': '#ffffff',
    'P_color_tertiary_hover': '#ffffff',
    'P_color_confirm': '#2bce78',
    'P_color_confirm_hover': '#31b971',
    'P_color_info': '#54a9ef',
    'P_color_info_hover': '#5dadf0',
    'P_color_notice': '#fcae57',
    'P_color_notice_hover': '#f69122',
    'P_color_warn': '#fe7274',
    'P_color_warn_hover': '#eb5557',
    'P_color_white': '#ffffff',
    'P_color_black': '#343636',
    'P_color_grey': '#919ba7',
    'P_border-radius_default': '2px',
    // Above here are "compulsory", below are additional

    'P_color_transparent': 'transparent',

    'P_color_grey_20': '#fbfcfc',
    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_60': '#f2f2f2',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_125': '#f0f1f3',
    'P_color_grey_150': '#eff2f4',
    'P_color_grey_200': '#d7d7d7',
    'P_color_grey_220': '#cfd8dc',
    'P_color_grey_230': '#bcc4c7',
    'P_color_grey_250': '#c8d3d9',
    'P_color_grey_275': '#d7dfe3',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_450': '#919ba7',
    'P_color_grey_550': '#6e7076',
    'P_color_grey_580': '#55575c',
    'P_color_grey_700': '#5c6875',
    'P_color_grey_800': '#49535d',
    'P_color_grey_900': '#49535d',

    'P_color_orange_700': '#fd9727',

    'P_color_purple_50': '#fdf7fa',
    'P_color_purple_100': '#fff4f9',
    'P_color_purple_200': '#f3d4e7',
    'P_color_purple_300': '#eab4d5',
    'P_color_purple_350': '#c42a87',
    'P_color_purple_400': '#9a30ae',
    'P_color_blue-black': '#2e3a4a',

    'P_color_skyBlue_350': '#e5f1ff',
    'P_color_skyBlue_500': '#2c98f0',

    'P_color_aqua_400': '#159587',
    'P_color_pink_400': '#e72564',
    'P_color_green_400': '#8cc252',
    'P_color_violet_400': '#663fb4',
    'P_color_lightBlue_200': '#f0f7ff',

    'P_border-width_0': '0px',

    'P_border-radius_0': '0px',
    'P_border-radius_3': '3px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',

    'P_padding_0': '0px',

    'P_margin_0': '0px',

    'P_box-shadow_white': '-6px 6px 12px #e7e4e3, 6px -6px 12px #fffcfb'
} satisfies VentraPrimitiveTokens;

const ventraSemantic = {
    'S_color_background_primary_base': 'P_color_primary',
    'S_color_background_primary_hover': 'P_color_primary_hover',
    'S_color_background_secondary_base': 'P_color_secondary',
    'S_color_background_secondary_hover': 'P_color_secondary_hover',
    'S_color_background_secondary_light': 'P_color_grey_50',
    'S_color_background_secondary-alt_base': 'P_color_secondary',
    'S_color_background_secondary-alt_hover': 'P_color_secondary',
    'S_color_background_tertiary_base': 'P_color_tertiary',
    'S_color_background_tertiary_hover': 'P_color_tertiary_hover',
    'S_color_background_confirm_base': 'P_color_confirm',
    'S_color_background_confirm_hover': 'P_color_confirm_hover',
    'S_color_background_info_base': 'P_color_info',
    'S_color_background_info_hover': 'P_color_info_hover',
    'S_color_background_notice_base': 'P_color_notice',
    'S_color_background_notice_hover': 'P_color_notice_hover',
    'S_color_background_warn_base': 'P_color_warn',
    'S_color_background_warn_hover': 'P_color_warn_hover',
    'S_color_background_default_base': 'P_color_white',
    'S_color_background_inverse_base': 'P_color_black',
    'S_color_background_black_base': 'P_color_black',
    'S_color_background_grey_base': 'P_color_grey',

    'S_color_text_primary_base': 'P_color_primary',
    'S_color_text_primary_hover': 'P_color_primary_hover',
    'S_color_text_secondary_base': 'P_color_secondary',
    'S_color_text_secondary_hover': 'P_color_secondary_hover',
    'S_color_text_confirm_base': 'P_color_confirm',
    'S_color_text_confirm_hover': 'P_color_confirm_hover',
    'S_color_text_info_base': 'P_color_info',
    'S_color_text_info_hover': 'P_color_info',
    'S_color_text_notice_base': 'P_color_notice',
    'S_color_text_notice_hover': 'P_color_notice_hover',
    'S_color_text_warn_base': 'P_color_warn',
    'S_color_text_warn_hover': 'P_color_warn_hover',
    'S_color_text_default_base': 'P_color_black',
    'S_color_text_inverse_base': 'P_color_white',
    'S_color_text_inverse_light': 'P_color_grey_200',
    'S_color_text_grey_base': 'P_color_grey',

    'S_color_icon_primary_base': 'P_color_primary',
    'S_color_icon_secondary_base': 'P_color_secondary',
    'S_color_icon_confirm_base': 'P_color_confirm',
    'S_color_icon_notice_base': 'P_color_notice',
    'S_color_icon_info_base': 'P_color_info',
    'S_color_icon_warn_base': 'P_color_warn',
    'S_color_icon_default_base': 'P_color_white',
    'S_color_icon_inverse_base': 'P_color_black',
    'S_color_icon_grey_base': 'P_color_grey',

    'S_color_border_primary_base': 'P_color_primary',
    'S_color_border_secondary_base': 'P_color_secondary',
    'S_color_border_secondary-alt_base': 'P_color_grey_230',
    'S_color_border_confirm_base': 'P_color_confirm',
    'S_color_border_info_base': 'P_color_info',
    'S_color_border_notice_base': 'P_color_notice',
    'S_color_border_warn_base': 'P_color_warn',
    'S_color_border_default_base': 'P_color_grey_200',
    'S_color_border_grey_base': 'P_color_grey',
    'S_color_border_black_base': 'P_color_black',
    'S_color_border_white_base': 'P_color_white',
    'S_color_border_transparent': 'P_color_transparent',

    'S_border-radius_default': 'P_border-radius_default',
    'S_border-radius_rounded': 'P_border-radius_50'
} satisfies VentraSematic;

const ventraComponent = {
    Subnav: {
        'C_Subnav_color_background_base': 'S_color_background_secondary_base',
        'C_Subnav_color_background_hover': 'S_color_background_secondary_base',
        'C_Subnav_color_background_active': 'S_color_background_secondary_hover',
        'C_Subnav_color_background_activeMobile': 'P_color_grey_700',
        'C_Subnav_color_text_base': 'S_color_text_inverse_light',
        'C_Subnav_color_text_hover': 'S_color_text_inverse_base',
        'C_Subnav_color_text_active': 'S_color_text_inverse_base',
        'C_Subnav_color_border': 'P_color_transparent',
        'C_Subnav__option--activeBefore_color_background': 'S_color_background_secondary_hover',
        'C_Subnav__option_border-bottom-width_selected': 'P_border-width_0',
        'C_Subnav__option_color_border_selected': 'P_color_transparent'
    },
    Title: {
        'C_Title_color_background': 'P_color_grey_50',
        'C_Title_color_border': 'P_color_transparent',
        'C_Title_border-width': 'P_border-width_0',
        'C_Title_borderRadius': 'S_border-radius_default',
        'C_Title_border-top-radius': 'P_border-radius_0',
        'C_Title_margin-top': 'P_margin_0',
        'C_Title_backButtonContainer_color_background': 'P_color_grey_150',
        'C_Title_backButton_color_background': 'P_color_transparent',
        'C_Title_backButton_color_text': 'S_color_text_secondary_base',
        'C_Title_backButton_color_border': 'S_color_text_secondary_base'
    },
    OutlineButton: {
        'C_BaseButton_border-radius_default': 'S_border-radius_default',
        'C_OutlineButton_color_border_primary': 'S_color_border_primary_base',
        'C_OutlineButton_color_background_primary_hover': 'S_color_background_primary_hover',
        'C_OutlineButton_color_text_primary_base': 'S_color_text_primary_base',
        'C_OutlineButton_color_text_primary_hover': 'S_color_text_inverse_base',
        'C_OutlineButton_color_border_secondary': 'S_color_border_secondary_base',
        'C_OutlineButton_color_background_secondary_hover': 'P_color_grey_550',
        'C_OutlineButton_color_text_secondary_base': 'P_color_grey_550',
        'C_OutlineButton_color_text_secondary_hover': 'S_color_text_inverse_base'
    },
    SolidButton: {
        C_SolidButton_color_background_secondary: 'P_color_grey_550',
        C_SolidButton_color_background_secondary_hover: 'P_color_grey_580',
        C_SolidButton_color_text_secondary_base: 'S_color_text_inverse_base',
        C_SolidButton_color_text_secondary_hover: 'S_color_text_inverse_base'
    },
    InactiveButton: {
        C_InactiveButton_color_text: 'P_color_grey',
        C_InactiveButton_color_background: 'P_color_grey_100',
        C_InactiveButton_color_border: 'S_color_border_default_base'
    },
    Sidebar: {
        'C_Sidebar_color_border': 'S_color_border_default_base',
        'C_Sidebar_color_background': 'P_color_transparent',
        'C_Sidebar__header_color_background': 'S_color_background_secondary_base',
        'C_Sidebar__header_color_text': 'S_color_text_inverse_base',
        'C_Sidebar__heading_color_text': 'S_color_text_default_base',
        'C_Sidebar__listItem_color_background_hover': 'P_color_transparent',
        'C_Sidebar__listItem_color_background_active': 'P_color_transparent',
        'C_Sidebar__listItem_color_text_base': 'S_color_text_secondary_base',
        'C_Sidebar__listItem_color_text_hover': 'S_color_text_primary_base',
        'C_Sidebar__listItem_color_text_active': 'S_color_text_primary_base',
        'C_Sidebar__listItem--selected_color': 'S_color_text_primary_base',
        'C_Sidebar__listItemInner_border-radius': 'P_border-radius_default'
    },
    MobileSidebar: {
        'C_MobileSidebar_margin-top': 'P_margin_0'
    },
    NXBox: {
        'C_NXBox_border-radius_default': 'P_border-radius_3',
        'C_NXBox_border-radius_defaultMobile': 'P_border-radius_15',
        'C_NXBox_color_border': 'S_color_border_default_base',
        'C_NXBox__subheading_color_text': 'S_color_text_secondary_base',
        'C_NXBoxSplit__section_color_border': 'S_color_border_default_base',
        'C_NXBoxBottom__container_color_background': 'P_color_grey_50',
        'C_NXBoxBottom_padding': 'P_padding_0',
        'C_NXBoxBottom_border-radius_default': 'S_border-radius_default',
        'C_NXBoxInfoPair__title_color_text': 'S_color_text_secondary_base'
    },
    NXTable: {
        'C_NXTable__subHeading_color_background': 'P_color_grey_125',
        'C_NXTable_color_background': 'S_color_background_default_base',
        'C_NXTable__row_color_text': 'S_color_text_secondary_base',
        'C_NXTable__row_color_border': 'P_color_transparent',
        'C_NXTable__row_border-width': 'P_border-width_0',
        'C_NXTableRow_color_background': 'S_color_background_default_base',
        'C_NXTableRow__alternator_color_background': 'P_color_grey_50',
        'C_NXTableRow__alternator_color_background_mobile': 'P_color_grey_100',
        'C_NXTableColumnHeading_color_text': 'S_color_text_secondary_base',
        'C_NXTableNoServices_color_background': 'P_color_transparent'
    },
    SelectDropdown: {
        'C_Select__listItem--selected_color_background': 'P_color_grey_50',
        'C_Select__listItem--focus_color_background': 'P_color_grey_100',
        'C_SelectDropdown_color_background': 'P_color_grey_50',
        'C_SelectDropdown_color_text': 'S_color_text_secondary_base',
        'C_SelectDropdown_color_border': 'P_color_grey_60'
    },
    Input: {
        'C_Input_border-radius': 'S_border-radius_default',
        'C_Input_color_background': 'P_color_grey_80',
        'C_Input_color_text': 'S_color_text_default_base',
        'C_Input_color_border': 'P_color_transparent',
        'C_Input_border-width': 'P_border-width_0',
        'C_Input__disabled_color_text': 'P_color_grey_400'
    },
    Accordion: {
        C_Accordion_color_background: 'S_color_background_default_base',
        C_Accordion_color_background_hover: 'S_color_background_secondary_light',
        C_Accordion_color_background_active: 'P_color_skyBlue_500',
        C_Accordion_color_text: 'S_color_text_default_base'
    },
    Anchor: {
        C_Anchor_color_text_base: 'P_color_skyBlue_500',
        C_Anchor_color_text_hover: 'P_color_skyBlue_500'
    },
    PricingTable: {
        C_PricingTable_color_background: 'P_color_grey_50'
    },
    twoFARadioButton: {
        C_2FARadioButton_color_background: 'S_color_background_default_base',
        C_2FARadioButton_color_background_hover: 'P_color_grey_100',
        C_2FARadioButton_color_background_selected: 'S_color_background_default_base',
        C_2FARadioButton_color_border_selected: 'P_color_info_hover'
    },
    Checkbox: {
        'C_Checkbox_border-radius_default': 'P_border-radius_default',
        'C_Checkbox_color_border': 'P_color_grey_80'
    },
    StyledLoader: {
        C_StyledLoader_color_background: 'S_color_background_secondary_light'
    },
    Tabs: {
        C_Tabs_color_background: 'P_color_grey_20'
    },
    SolidTag: {
        'C_BaseTag_border-radius': 'P_border-radius_default',
        'C_SolidTag_color_text': 'P_color_white'
    },
    Tooltip: {
        'C_Tooltip__content_color_background': 'S_color_background_secondary_base',
        'C_Tooltip_border-radius': 'P_border-radius_6'
    },
    Search: {
        'C_Search_color_background': 'P_color_white',
        'C_Search_border-radius': 'P_border-radius_5'
    },
    RenderLogoOrBrandFont: {
        'C_RenderLogoOrBrandFont_border-radius': 'P_border-radius_6'
    },
    HighlightBadge: {
        C_HighlightBadge_color_outline: 'P_color_purple_300'
    },
    accounts: {
        'C_accounts__indicator_color_background': 'P_color_purple_100',
        'C_accounts__dropdown--name_color_text': 'P_color_blue-black'
    },
    descriptionText: {
        C_descriptionText_color_text: 'S_color_text_secondary_base'
    },
    rangeSlider: {
        C_rangeslider_color_background: 'P_color_purple_200'
    },
    changeResourcesForm: {
        C_changeResourcesFormAllocations__names_color_background: 'S_color_background_secondary_base',
        C_changeResourcesFormAllocations__currentValues_color_background: 'P_color_grey_100',
        C_changeResourcesFormAllocations__currentValuesHeading_color_background: 'P_color_grey_250',
        C_changeResourcesFormAllocations__newValues_color_background: 'P_color_purple_200',
        C_changeResourcesFormAllocations__newValuesHeading_color_background: 'P_color_purple_300'
    },
    hostingDetails: {
        C_hostingDetails__tabSelectorOption_color_background_hover: 'P_color_grey_50',
        C_hostingDetails__tabSelectorOption_color_background_selected: 'S_color_background_secondary_base',
        C_hostingDetails__tabSelectorOption_color_text_selected: 'P_color_white'
    },
    accountLoginHistory: {
        C_accountLoginHistory__tableHeader_color_text: 'S_color_text_secondary_base'
    },
    invoiceView: {
        'C_invoiceView__tag_color_background_secondary': 'P_color_grey_50',
        'C_invoiceView__info_color_background_mobile': 'P_color_grey_50',
        'C_invoiceView__tag_border-radius': 'P_border-radius_5'
    },
    invoicePay: {
        'C_invoicePay__items_box-shadow': 'P_box-shadow_white'
    },
    knowledgeBase: {
        C_knowledgeBase_color_background: 'S_color_background_secondary_light',
        C_knowledgeBase__supportCategory_color_background_hover: 'P_color_skyBlue_350',
        C_knowledgeBase__otherMethods_color_background: 'S_color_background_secondary_base',
        C_knowledgeBase__otherMethods_color_text: 'S_color_text_inverse_base'
    },
    ticketList: {
        C_ticketList__openTicket_color_background: 'P_color_purple_50'
    },
    ticketViewMessage: {
        'C_ticketViewMessage__body--customer_color_background': 'P_color_lightBlue_200',
        'C_ticketViewMessage__body--worker_color_background': 'P_color_purple_50'
    },
    ticketForm: {
        C_ticketForm__waitTime_color_background: 'P_color_grey_100'
    },
    paymentMethods: {
        C_paymentMethods__methodSelected_color_background: 'S_color_background_secondary_light'
    },
    registrantInfo: {
        C_registrantInfo_color_background_secondary: 'P_color_grey_50'
    },
    supportLink: {
        C_supportLink_color_text: 'P_color_info_hover'
    },
    dnsTag: {
        C_dnsTypeTag_color_background_pink: 'P_color_pink_400',
        C_dnsTypeTag_color_background_purple: 'P_color_primary',
        C_dnsTypeTag_color_background_violet: 'P_color_violet_400',
        C_dnsTypeTag_color_background_aqua: 'P_color_aqua_400',
        C_dnsTypeTag_color_background_green: 'P_color_green_400',
        C_dnsTypeTag_color_background_orange: 'P_color_orange_700',
        C_dnsAppendTag_color_background: 'P_color_grey_275'
    },
    expressServiceManager: {
        C_expressServiceManager__option_color_background: 'S_color_background_secondary_base',
        C_expressServiceManager__option_color_background_hover: 'S_color_background_secondary_hover',
        C_expressServiceManager__option_color_background_active: 'P_color_grey_900',
        C_expressServiceManager__option_color_background_activeMobile: 'S_color_background_secondary_base',
        C_expressServiceManager__option_color_background_activeBefore: 'P_color_grey_900',
        C_expressServiceManager__option_color_text: 'S_color_text_inverse_light',
        C_expressServiceManager__option_color_text_hover: 'S_color_text_inverse_base',
        C_expressServiceManager__option_color_text_active: 'S_color_text_inverse_base',
        C_expressServiceManager_color_border: 'P_color_transparent'
    },
    loginAccountSelect: {
        C_loginAccountSelect_color_background_hover: 'S_color_background_secondary_light'
    },
    pageOrganiser: {
        C_PageOrganiser__content_border_color: 'P_color_grey_220',
        C_PageOrganiser__tag_text_color: 'P_color_purple_350'
    },
    addPageRoute: {
        C_AddPageRoute__previewWrapper_color_background: 'P_color_grey_125'
    },
    richTextEditor: {
        'C_RichTextEditor__ToolbarButton_border-radius': 'P_border-radius_5',
        'C_RichTextEditor__ToolbarButton__after_border-radius': 'P_border-radius_3'
    },
    multiPageBetaTestingNotice: {
        'C_MultiPageBetaTestingNotice_border-radius': 'P_border-radius_6'
    }
} satisfies VentraComponent;

const ventra = {
    primitive: ventraPrimitive,
    semantic: ventraSemantic,
    component: ventraComponent
};

export const __DO_NOT_USE_IN_PRODUCTION_BUILD_OR_YOU_WILL_BE_FIRED__VENTRA__CONFIG__ = ventra;
