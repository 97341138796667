import iphone from 'assets/images/promo/survey2025/iPhone.png';
import pixel from 'assets/images/promo/survey2025/pixel.png';
import win from 'assets/images/promo/survey2025/win.svg';
import Anchor from 'components/Anchor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import React from 'react';
import { useUserData } from 'utilities/hooks/redux/useUserData';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_surveyBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SurveyBanner = () => {
    /***** HOOKS *****/
    const appViewport = useAppViewport();
    const { account_number } = useUserData();

    /***** CONSTS *****/
    const isSmall = appViewport === 'xs' || appViewport === 'sm';

    /***** RENDER HELPERS *****/
    function renderText() {
        return (
            <Flex direction="column" gap={0} justify="center" fullWidth={isSmall} className="surveyBanner__text">
                <Text className="surveyBanner__textSubheading">{`VentraIP's Annual`}</Text>
                <div className="surveyBanner__textHeading" />
                <Text className="surveyBanner__textContent" semiBold>
                    Your chance to <img src={win} alt="win" /> an iPhone 16 or{' '}
                    <Text className="surveyBanner__textContentBlock" semiBold>
                        Pixel 9
                    </Text>
                </Text>
            </Flex>
        );
    }

    function renderPhones() {
        return (
            <Flex fullWidth fullHeight justify="between" items="center" className="surveyBanner__phones">
                <img src={iphone} alt="iPhone 16" />
                <Text>OR</Text>
                <img src={pixel} alt="Google Pixel 9" />
            </Flex>
        );
    }

    function renderBtn() {
        return (
            <Anchor className="surveyBanner__enterBtn" target="_blank" href={`https://www.surveymonkey.com/r/JRN8NT5?s=vipc&id=${account_number}`}>
                <Text uppercase bold size--l align--center>
                    Enter Now
                </Text>
            </Anchor>
        );
    }

    const layouts: Record<TViewport, React.ReactElement> = {
        xl: (
            <>
                {renderText()}
                {renderPhones()}
                {renderBtn()}
            </>
        ),
        lg: (
            <>
                {renderText()}
                {renderPhones()}
                {renderBtn()}
            </>
        ),
        md: (
            <>
                <Flex direction="column">
                    {renderText()}
                    {renderBtn()}
                </Flex>
                {renderPhones()}
            </>
        ),
        sm: (
            <>
                <Flex fullWidth items="center" justify="between">
                    {renderText()}
                    {renderBtn()}
                </Flex>
                {renderPhones()}
            </>
        ),
        xs: (
            <>
                {renderText()}
                {renderBtn()}
                {renderPhones()}
            </>
        )
    };

    /***** RENDER *****/
    return (
        <div className="surveyBanner">
            <Flex fullWidth fullHeight justify="between" items="center" direction={isSmall ? 'column' : 'row'} gap={isSmall ? 0 : 3}>
                {layouts[appViewport]}
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
SurveyBanner.SURVEY_DISMISS_KEY = 'SURVEY_DISMISS_KEY';
