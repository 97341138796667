import { handleRecursiveAttributesCaveatController } from 'containers/katana/queries/methods/handleRecursiveAttributesCaveatController';
import { mergeWithAttributeObjectPicker } from 'containers/katana/queries/methods/mergeWithAttributeObjectPicker';
import { recursivelyTransformPropertyAttachmentData } from 'containers/katana/queries/methods/recursivelyTransformPropertyAttachmentData';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    serviceID: KatanaNamespace.ServiceID;
    sectionDefinitionID?: KatanaNamespace.SectionDefinitionID;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

export function useHandleDynamicSectionMethods({ serviceID, sectionDefinitionID }: Params) {
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(serviceID);
    const sectionDefinitionData = get_katana_section_definitions_data?.[sectionDefinitionID];

    function handleModifyEntireAttributeObject(attributes: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ModifiedTypes.PartialData) {
        if (!_.has(attributes, 'properties')) {
            return attributes;
        }

        const caveatsModifiedAttributes = handleRecursiveAttributesCaveatController({ sectionDefinitionData, attributes });
        // Convert the values so that we're only sending the attachment id
        const finalPropertiesObject = recursivelyTransformPropertyAttachmentData({
            sectionDefinitionData,
            properties: attributes.properties
        });
        const updatedAttributes = _.mergeWith(
            _.cloneDeep(caveatsModifiedAttributes),
            { properties: finalPropertiesObject },
            mergeWithAttributeObjectPicker
        );
        return updatedAttributes;
    }

    /***** HOOK RESULTS *****/
    return {
        handleModifyEntireAttributeObject
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
