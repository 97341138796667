/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import Title from 'components/Title';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const GroupTitle = () => {
    /***** SELECTORS *****/
    const email_domain_group_details_data = useSelector((state: any) => state.email.email_domain_group_details_data);

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={email_domain_group_details_data?.attributes?.domain || ''}
            serviceType={email_domain_group_details_data?.type}
            action={
                <OutlineButton className="TitleShared__dropdown medium" color="primary" to="/support/support-centre/email-hosting">
                    Email Hosting Help
                </OutlineButton>
            }
        />
    );
};
