/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { KatanaColours, KatanaSectionIDHardCodedMetaData } from 'containers/katana/consts';
import type { KatanaNamespace } from 'containers/katana/types';

export function getKatanaSectionIDColour(sectionDefinitionID: KatanaNamespace.SectionDefinitionID) {
    return KatanaSectionIDHardCodedMetaData[sectionDefinitionID]?.color ?? KatanaColours.GRAY;
}

/**
 * Utility function for creating a katanaServiceId. This does not perform any validation on the value, but does ensure
 * that the value is cast to a number and appropriately tagged as a KatanaNamespace.ServiceID.
 *
 * This should be treated similar to writing `as KatanaNamespace.ServiceID` on a number, but provides a single entry point
 * for creating tagged serviceIds that do not directly come from a query/mutation response.
 */
export const createKatanaServiceId = (serviceID: string | number): KatanaNamespace.ServiceID => {
    return +serviceID as KatanaNamespace.ServiceID;
};

/**
 * Utility function for creating a katana page id. This does not perform any validation on the value, but does ensure
 * that the value is cast to a number and appropriately tagged as a KatanaNamespace.PageID.
 *
 * This should be treated similar to writing `as KatanaNamespace.PageID` on a number, but provides a single entry point
 * for creating tagged pageIds that do not directly come from a query/mutation response.
 */
export const createKatanaPageID = (pageID: string | number): KatanaNamespace.PageID => {
    return +pageID as KatanaNamespace.PageID;
};

export const createKatanaSectionID = (serviceID: string | number): KatanaNamespace.SectionID => {
    return +serviceID as KatanaNamespace.SectionID;
};
