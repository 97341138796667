/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { logoutApp } from 'components/Header/action';
import { requestAccounts, selectAccount } from 'containers/login/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { useClickAway } from 'utilities/hooks/useClickAway';
import { getLoggedInAccountData } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_accounts.scss';

const backgrounds = [
    'linear-gradient(90deg, #0a9fe5, #10c5ef)',
    'linear-gradient(90deg, #5956d7, #c544fb)',
    'linear-gradient(90deg, #0dd41a, #86fc6f)',
    'linear-gradient(90deg, #c32888, #a01e54)',
    'linear-gradient(90deg, #f4ad26, #ca3c78)',
    'linear-gradient(90deg, #0575e6, #0054a8)',
    'linear-gradient(90deg, #f6d365, #fda085)',
    'linear-gradient(90deg, #84fab0, #8fd3f4)'
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Accounts = ({ login_account_list_data, login_account_list_status, app_check_token_data, logoutApp, selectAccount, history, ...props }) => {
    /***** STATE *****/
    const [showAccountsDropdown, setShowAccountsDropdown] = useState(false);
    const [loggedInAccount, setLoggedInAccount] = useState(null);
    const [accountError, setAccountError] = useState(false);

    /***** HOOKS *****/
    const accountRef = useRef();
    useClickAway([accountRef], () => setShowAccountsDropdown(false));

    /***** FUNCTIONS *****/
    function toggleAccountsDropdown() {
        setShowAccountsDropdown(!showAccountsDropdown);
    }

    /**
     * This method only gets called when the `login_account_list_data` and `app_check_token_data` are both available.
     */
    function updateLoggedInAccount() {
        const account = getLoggedInAccountData(login_account_list_data, app_check_token_data);
        if (!account) {
            pushNotification({
                status: 401,
                details: 'Looks like we encountered an Account issue. Please try again later or contact our support team.'
            });
            setAccountError(true);
            return;
        }

        setLoggedInAccount({
            id: account.id,
            role: account.attributes.role,
            firstname: account.attributes.firstname,
            lastname: account.attributes.lastname,
            company: account.attributes.company,
            email: account.attributes.email,
            accountNumber: account.attributes.account_number
        });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (login_account_list_status !== 'success' || !login_account_list_data || !app_check_token_data) return;
        updateLoggedInAccount();
    }, [login_account_list_status, login_account_list_data, app_check_token_data]);

    useEffect(() => {
        if (!login_account_list_data) {
            requestAccounts();
        }
    }, []);

    /***** RENDER HELPERS *****/
    const accountList = () => {
        if (login_account_list_data && login_account_list_data.length > 0) {
            return login_account_list_data
                .filter((account) => account.id !== loggedInAccount.id)
                .map((account, index) => {
                    const { id } = account;
                    const { firstname, lastname, company, role } = account.attributes;

                    return (
                        <button
                            type="button"
                            key={index}
                            className="accounts__user"
                            onClick={() => {
                                selectAccount(id, history, '/dashboard');
                            }}
                        >
                            <div className="accounts__user--section">
                                <Avatar fullname={`${firstname} ${lastname}`} size={39} fontSize={14} background={backgrounds[index + 1]} />
                                <div className={`accounts__info ${company ? 'justify' : ''} ${role !== 'account holder' ? 'full' : ''}`}>
                                    <div className="accounts__info--company">{company}</div>
                                    <div className="accounts__info--name">{`${firstname} ${lastname}`}</div>
                                </div>
                                {role === 'account holder' && <SolidTag>Your account</SolidTag>}
                            </div>
                            {role === 'account holder' && (
                                <OutlineButton
                                    className="accounts__user--profile"
                                    type="onClick"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        selectAccount(id, history, '/account/general#overview');
                                    }}
                                >
                                    My Profile
                                </OutlineButton>
                            )}
                        </button>
                    );
                });
        }
    };

    const renderDropdownContents = () => {
        return (
            <div className="accounts__container">
                <div className="accounts__indicator">
                    {loggedInAccount.role === 'account holder' ? 'You are logged in as' : 'You are currently managing services for'}
                </div>

                <div className="accounts__login">
                    <Avatar
                        fullname={`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}
                        size={65}
                        fontSize={22}
                        background={backgrounds[0]}
                    />

                    {loggedInAccount.company ? <div className="accounts__login--company">{loggedInAccount.company}</div> : ''}

                    <div className={`accounts__login--name ${loggedInAccount.company ? '' : 'individual'}`}>
                        {`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}
                    </div>

                    {!loggedInAccount.company ? <div className="accounts__login--email">{loggedInAccount.email}</div> : ''}

                    <div className="accounts__login--number">#{loggedInAccount.accountNumber}</div>

                    {loggedInAccount.role === 'account holder' ? (
                        <OutlineButton
                            type="onClick"
                            className="accounts__login--profile"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleAccountsDropdown();
                                history.push('/account/general#overview');
                            }}
                        >
                            My Profile
                        </OutlineButton>
                    ) : (
                        ''
                    )}
                </div>

                <div className="accounts__users">{accountList()}</div>

                <button className="accounts__logout" type="button" onClick={logoutApp}>
                    <i className="icon icon-power" />
                    Logout
                </button>
            </div>
        );
    };

    const renderLoaderOrError = () => {
        if (accountError) {
            return (
                <div>
                    <Text lead--1 medium warn>
                        Account Error
                    </Text>
                    <Text warn size--s>
                        Something went wrong
                    </Text>
                </div>
            );
        }
        return <RequestLoader />;
    };

    /***** RENDER *****/
    return (
        <>
            <Avatar
                fullname={loggedInAccount ? `${loggedInAccount.firstname} ${loggedInAccount.lastname}` : '  '}
                size={33}
                fontSize={12}
                background={backgrounds[0]}
            />
            <button
                type="button"
                ref={accountRef}
                className="accounts__dropdown"
                onClick={(e) => {
                    toggleAccountsDropdown();
                }}
                onKeyDown={(e) => {
                    // Open menu with spacebar
                    if (e.key === ' ' && !showAccountsDropdown) {
                        e.preventDefault();
                        toggleAccountsDropdown();
                    }
                    // Close menu with escape key
                    if (['Escape', 'Esc'].includes(e.key) && showAccountsDropdown) {
                        e.preventDefault();
                        toggleAccountsDropdown();
                    }
                }}
            >
                {loggedInAccount ? (
                    <>
                        <div className={`accounts__dropdown--credential ${loggedInAccount.company ? 'justify' : ''}`}>
                            <div className="accounts__dropdown--company">{loggedInAccount.company}</div>
                            <div className="accounts__dropdown--name">{`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}</div>
                        </div>

                        <PhosphorIcons.Chevron state={showAccountsDropdown ? 'up' : 'down'} secondary size={16} className="HeaderLink__chevron" />

                        {showAccountsDropdown ? renderDropdownContents() : ''}
                    </>
                ) : (
                    renderLoaderOrError()
                )}
            </button>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => ({
    app_check_token_data: state.app.app_check_token_data,
    login_account_list_status: state.login.login_account_list_status,
    login_account_list_data: state.login.login_account_list_data
});

const mapDispatchToProps = {
    logoutApp,
    selectAccount
};

Accounts = withRouter(connect(mapStateToProps, mapDispatchToProps)(Accounts));

export default Accounts;
