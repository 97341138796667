import { createRoute } from '@tanstack/react-router';
import { AppRoute } from 'App';
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import { Page } from 'components/Page';
import { RenderForBrands } from 'config/brandRenderer/component';
import { ventraSynMigrationBanners } from 'config/config';
import Invoices from 'containers/billing/modules/invoices';
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { routerMiddleware } from 'router/utils/middleware';
import './_dashboard.scss';
import { Account } from './Components/account';
import Tickets from './Components/etickets';
import { ExpressServiceManager } from './Components/expressServiceManager';
import OverdueInvoices from './Components/overdueInvoices';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DashboardRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'dashboard',
    loader({ cause, context }) {
        if (cause === 'enter' && ventraSynMigrationBanners) {
            checkRequiresMigration()(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: Dashboard
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function Dashboard() {
    /***** SELECTORS *****/
    const app_user_status = useSelector((state: any) => state.app.app_user_status);

    /***** HOOKS *****/
    const promoDealsRef = useRef(null);

    /***** RENDER *****/
    switch (app_user_status) {
        case 'error':
            return <FetchPageError />;

        case 'success':
            return (
                <Page className="dashboard">
                    <RenderForBrands brands={['ventra']}>
                        <VentraSynBanner />
                    </RenderForBrands>
                    <Account promoDealsRef={promoDealsRef} />
                    <RenderForBrands brands={['ventra']}>
                        <OverdueInvoices />
                    </RenderForBrands>
                    <RenderForBrands brands={['ventra', 'intaserve']}>
                        {' '}
                        <ExpressServiceManager />
                    </RenderForBrands>
                    <RenderForBrands brands={['ventra']}>
                        <Tickets />
                    </RenderForBrands>
                    <Invoices />
                </Page>
            );

        case 'loading':
        default:
            return <RequestLoader />;
    }
}
