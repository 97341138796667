/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

import './_PictureInPictureControlWrapper.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _PictureInPictureControlsWrapper({ children }) {
    return (
        <Padding xy={4} paddingOnly inject>
            <Flex className="PictureInPictureControlsWrapper" wrap justify="center" items="center">
                {children}
            </Flex>
        </Padding>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureControlsWrapper };
