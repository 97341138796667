/**********************************************************************************************************
 *   GLOBAL IMPORTS
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/vipRewards';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   EXTERNAL ACTION TYPES
 **********************************************************************************************************/
import { LOGIN_ACCOUNT_LIST_SUCCESS } from 'containers/login/actionTypes';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const REWARDS_TIERS_LIST_REQUEST = 'rewards/REWARDS_TIERS_LIST_REQUEST';
export const REWARDS_TIERS_LIST_SUCCESS = 'rewards/REWARDS_TIERS_LIST_SUCCESS';
export const REWARDS_TIERS_LIST_ERROR = 'rewards/REWARDS_TIERS_LIST_ERROR';

export const REWARDS_SIGNUP_REQUEST = 'rewards/REWARDS_SIGNUP_REQUEST';
export const REWARDS_SIGNUP_SUCCESS = 'rewards/REWARDS_SIGNUP_SUCCESS';
export const REWARDS_SIGNUP_ERROR = 'rewards/REWARDS_SIGNUP_ERROR';

/**
 * Get a list of all VIPrewards tiers and associated data
 */
export function getRewardTiersList() {
    const { dispatch } = store;

    dispatch({
        type: REWARDS_TIERS_LIST_REQUEST
    });

    return API.vipRewards.GET.tiers()
        .then((response) => {
            dispatch({
                type: REWARDS_TIERS_LIST_SUCCESS,
                rewards_tiers_list_data: getDataFromSuccessResponse(response)
            });
        })
        .catch(() => {
            dispatch({
                type: REWARDS_TIERS_LIST_ERROR
            });
        });
}

/**
 * Sign up to the VIPrewards program
 */
export const vipRewardsSignup = (options) => {
    const { dispatch } = store;

    dispatch({ type: REWARDS_SIGNUP_REQUEST });

    API.vipRewards.POST.signUp()
        .then(async (response) => {
            const rewards_signup_data = getDataFromSuccessResponse(response);

            dispatch({ type: REWARDS_SIGNUP_SUCCESS });

            // Update the rewards tier on the account list
            const login_account_list_data = store.getState()?.login?.login_account_list_data;
            dispatch({
                type: LOGIN_ACCOUNT_LIST_SUCCESS,
                login_account_list_data: login_account_list_data?.map((accountData) => {
                    if (accountData.id !== options?.accountId) {
                        return accountData;
                    }

                    const newLoggedInAccountData = {
                        ...accountData,
                        attributes: {
                            ...accountData.attributes,
                            reward_tier:
                                typeof rewards_signup_data?.tier_assigned === 'string' ? rewards_signup_data.tier_assigned.toLowerCase() : null
                        }
                    };

                    return newLoggedInAccountData;
                })
            });

            options?.onSuccess?.();
        })
        .catch((error) => {
            pushNotification(getErrorFromFailResponse(error));
            dispatch({ type: REWARDS_SIGNUP_ERROR });
        });
};
