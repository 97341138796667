/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, getFormMeta, getFormValues, reduxForm, reset } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EmailAddressFields from './emailAddressFields';
import NameAndPasswordFields from './nameAndPasswordFields';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateId } from 'utilities/methods/commonActions';
import { RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/*   ACTIONS
 *****************************************************/
import { validateEmailAddressReset } from 'containers/email/action';
import { formatMailboxFormValues, getProductDiscountOrBasePrice } from 'containers/email/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PurchaseForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddressValidateKey: props.data?.emailAddressValidateKey || generateId()
        };

        this.handleMailboxAdd = this.handleMailboxAdd.bind(this);
        this.handleMailboxSave = this.handleMailboxSave.bind(this);
    }

    handleMailboxAdd() {
        const { form, functions, formValues, dispatch, meta } = this.props;
        const { emailAddressValidateKey } = this.state;

        functions.submit(formatMailboxFormValues({ ...formValues, emailAddressValidateKey, config: [meta.options.config] }));

        // Create a new validate key because its a new form at this point, and the data in the current form will become an existing mailbox
        this.setState(
            {
                emailAddressValidateKey: generateId()
            },
            () => dispatch(reset(form))
        );
    }

    handleMailboxSave() {
        const { functions, formValues, meta } = this.props;
        const { emailAddressValidateKey } = this.state;

        functions.save(formatMailboxFormValues({ ...formValues, emailAddressValidateKey, config: [meta.options.config] }));
    }

    handleSubtotalRender = (value) => {
        return getProductDiscountOrBasePrice(value) || '0.00';
    };

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { data, form, dispatch } = this.props;

        if (data) {
            delete data.emailAddressValidateKey;

            Object.entries(data).forEach((value) => {
                dispatch(change(form, value[0], value[1]));
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { formValues, validateEmailAddressReset, data } = this.props;
        const { emailAddressValidateKey } = this.state;

        if (!formValues) return;
        const { domain_name, username } = formValues;

        // If either domain name or username fields have changed, not including when they get changed in the did mount method (when editing a mailbox, the passed in email address has already been validated)
        if (
            (domain_name !== prevProps.formValues?.domain_name || username !== prevProps.formValues?.username) &&
            (domain_name !== data?.domain_name || username !== data?.username)
        ) {
            validateEmailAddressReset(emailAddressValidateKey);
        }
    }

    render() {
        const { formValues, data, meta, fieldsMeta, email_validate_data, form } = this.props;
        const { emailAddressValidateKey } = this.state;
        const { handleMailboxAdd, handleMailboxSave, handleSubtotalRender } = this;

        const renderRestOfForm = () => {
            const renderBillingCycleField = () =>
                meta?.billing ? (
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                label="Billing Cycle"
                                name="billing_cycle"
                                component={RenderSelectField}
                                validate={[requiredFieldValidation]}
                                type="select"
                                className="form__dropdown"
                                options={meta?.billing ? meta.billing : []}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                );

            return (
                <>
                    {renderBillingCycleField()}
                    <NameAndPasswordFields passwordInput={formValues?.password} />
                    {formValues?.billing_cycle && handleSubtotalRender(meta.options.pricing[formValues?.billing_cycle]) ? (
                        <div className="subtotal">
                            <div className="title">Subtotal</div>
                            <div className="price">
                                ${handleSubtotalRender(meta.options.pricing[formValues?.billing_cycle])} AUD /{formValues.billing_cycle}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <ReduxFormButton form={form} color="confirm" onClick={data ? handleMailboxSave : handleMailboxAdd}>
                        {data ? 'Save Mailbox' : 'Add Mailbox'}
                    </ReduxFormButton>
                </>
            );
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="addMailboxesForm">
                <EmailAddressFields
                    helpboxOpen={fieldsMeta?.username?.active || fieldsMeta?.domain_name?.active}
                    disableDomain={!!meta?.domain}
                    username={formValues?.username}
                    domain_name={formValues?.domain_name}
                    emailAddressValidateKey={emailAddressValidateKey}
                />
                {meta?.domain || email_validate_data[emailAddressValidateKey] ? renderRestOfForm() : ''}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
PurchaseForm = reduxForm({
    enableReinitialize: true
})(PurchaseForm);

const mapStateToProps = (state, props) => {
    const formValues = getFormValues(props.meta.form)(state);

    const initialValues = {};

    if (props.meta.domain) initialValues.domain_name = props.meta.domain;

    return {
        initialValues,
        form: props.meta.form,
        formValues,
        fieldsMeta: getFormMeta(props.meta.form)(state),
        email_validate_data: state.email.email_validate_data
    };
};

const mapDispatchToProps = {
    validateEmailAddressReset
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseForm);
