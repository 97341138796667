import { useStore } from '@tanstack/react-store';
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import Anchor from 'components/Anchor';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import Text from 'components/Utils/Text';
import { domainURL } from 'containers/domain/methods';
import { domainQuery } from 'containers/domain/queries/queryTree';
import type { DomainNamespace } from 'containers/domain/types';
import { _RecordTable as RecordTable } from 'containers/katana/components/launchWebsite/_RecordTable';
import { launchSiteDNSModePropagationTracker } from 'containers/katana/components/launchWebsite/consts';
import { DNSHostingChangeKatanaLaunch } from 'containers/katana/components/launchWebsite/dnsHostingChange';
import {
    katanaTrialStateSimplifiedLaunch,
    simplifiedFlowLoadingMessage
} from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import { ValidFormSubmitButtonHookForm } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/ValidFormSubmitButtonHookForm';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import _ from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { DNS_CONFIG_MODES } from 'utilities/consts';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import './_launchWebsiteButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConflictingRecordsForm = () => {
    /***** HOOKS *****/
    const { getValues, setValue, trigger } = useFormContext();
    const [simplifiedFlowMessage] = useTanstackStore(simplifiedFlowLoadingMessage);
    const { katanaServiceId, subpage } = useKatanaParams();

    /***** QUERIES *****/
    const {
        data: check_enable_site_data,
        isLoading: isCheckKatanaServiceDetailsLoading,
        isError: isCheckKatanaServiceDetailsError,
        isFetching: isCheckKatanaServiceDetailsFetching
    } = katanaQuery.serviceID.domainServiceDetails.useQuery(katanaServiceId);

    const { data: katana_service_data } = katanaQuery.serviceID.service.useQuery(katanaServiceId);
    const { has_trial_invoice } = katana_service_data?.attributes ?? {};
    const { data: dns_data, isLoading: isGetDNSLoading, isError: isGetDNSError } = katanaQuery.serviceID.dns.useQuery(katanaServiceId);

    const presetPreviewData = useMemo(
        () =>
            ({
                id: check_enable_site_data?.domain_id,
                preset: 'katana',
                attributes: {
                    replacements: {
                        hostname: check_enable_site_data?.hostname,
                        server_hostname: check_enable_site_data?.server_hostname
                    }
                }
            }) as const,
        [check_enable_site_data]
    );
    const { data: get_dns_preset_preview_data, isFetching: isGetDNSPresetPreviewFetching } =
        domainQuery.domainID.getDNSPresetPreview.useQuery(presetPreviewData);

    /***** FUNCTIONS *****/
    function markRemoveConflicting() {
        const remove_conflicting = getValues('remove_conflicting');
        if (!remove_conflicting) {
            setValue('remove_conflicting', true);
            trigger();
        }
    }

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsLoading || isCheckKatanaServiceDetailsFetching, message: 'Checking Service Details...' },
        { condition: isGetDNSLoading, message: 'Loading DNS Records...' },
        { condition: isGetDNSPresetPreviewFetching, message: 'Loading DNS Preset...' }
    ]);

    const hasConflictingRecords = dns_data ? !!dns_data.current_dns_records.length && !!dns_data.required_dns_records.length : false;
    const weHaveDomainAndNotHosted = Boolean(
        !_.includes([DNS_CONFIG_MODES.Forwarding, DNS_CONFIG_MODES['DNS Hosting']], check_enable_site_data?.domain_dns_config) &&
            check_enable_site_data?.domain_id
    );

    const isSimplifiedFlow = katanaTrialStateSimplifiedLaunch.state;

    type DNSDomainIDs = (DomainNamespace.DomainID | undefined)[];
    const hasConfiguredDNS = useStore(launchSiteDNSModePropagationTracker, (state) =>
        (state as DNSDomainIDs).includes(check_enable_site_data?.domain_id)
    );

    /***** RENDER *****/
    if (weHaveDomainAndNotHosted && !isSimplifiedFlow && check_enable_site_data?.domain_id && !hasConfiguredDNS) {
        return <DNSHostingChangeKatanaLaunch />;
    }

    if (dns_data?.is_dns_configured || isSimplifiedFlow || (!check_enable_site_data?.domain_id && has_trial_invoice)) {
        markRemoveConflicting();
        return (
            <div className="ConflictingRecordsForm">
                {isSimplifiedFlow && Boolean(simplifiedFlowMessage) && <RequestLoader message={simplifiedFlowMessage} />}
                <ValidFormSubmitButtonHookForm ignorePristine />
            </div>
        );
    }

    if (isCheckKatanaServiceDetailsError || isGetDNSError) {
        return (
            <>
                <DialogNotification type="error" tail={{ pos: 'top' }}>
                    <Text semiBold>
                        {check_enable_site_data?.domain_id ? (
                            <>
                                We had some trouble updating your DNS records. There could be a few reasons for this.{' '}
                                <Anchor color="white" underline to={domainURL('manage', 'dns', check_enable_site_data?.domain_id)}>
                                    <Text semiBold>Click here</Text>
                                </Anchor>
                                {` to manage your domain name's DNS records`}
                            </>
                        ) : (
                            'We had some trouble updating your DNS records.'
                        )}
                    </Text>
                </DialogNotification>
                <Text secondary align--center>
                    Not too sure what to do next?{' '}
                    <Anchor to={`/support/tickets/submit/technical-support?invoice=na&service=${check_enable_site_data?.domain_id ?? 'select'}`}>
                        Submit an eTicket to our Technical Support team
                    </Anchor>
                </Text>
            </>
        );
    }

    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <div className="ConflictingRecordsForm">
                <Text size--l>{check_enable_site_data?.domain_id ? 'Records Being Added' : 'Required Records'}</Text>
                <RecordTable records={check_enable_site_data?.domain_id ? get_dns_preset_preview_data?.add : dns_data?.required_dns_records} />
                {!check_enable_site_data?.domain_id && (
                    <AcknowledgementBox.Compact
                        notice={
                            <>
                                <Text uppercase bold>
                                    Please Note:{' '}
                                </Text>
                                {subpage === 'fix-dns'
                                    ? 'DNS changes can take up to 48 hours to propagate fully. During this time, your website may not be immediately accessible at your domain. Please allow some time for the changes to take effect.'
                                    : 'The records you are about to add may conflict with some of your existing records.'}
                            </>
                        }
                        title="Confirmation"
                    >
                        <CheckBoxList.Item.HookForm name="remove_conflicting">
                            <Text secondary size--s bold>
                                I want to remove any existing records that conflict with the new records being added to my DNS.
                            </Text>
                        </CheckBoxList.Item.HookForm>
                    </AcknowledgementBox.Compact>
                )}
                {/* Only display conflicting records if they exist */}
                {!!get_dns_preset_preview_data?.remove?.length && (
                    <>
                        <Text size--l>Conflicting Records</Text>
                        <RecordTable records={get_dns_preset_preview_data?.remove} />

                        <AcknowledgementBox.Compact
                            notice={
                                <>
                                    <Text uppercase bold>
                                        Please Note:{' '}
                                    </Text>
                                    {subpage === 'fix-dns'
                                        ? 'DNS changes can take up to 48 hours to propagate fully. During this time, your website may not be immediately accessible at your domain. Please allow some time for the changes to take effect.'
                                        : 'The records you are about to add may conflict with some of your existing records.'}
                                </>
                            }
                            title="Confirmation"
                        >
                            <CheckBoxList.Item.HookForm name="remove_conflicting">
                                <Text secondary size--s bold>
                                    I want to remove any existing records that conflict with the new records being added to my DNS.
                                </Text>
                            </CheckBoxList.Item.HookForm>
                        </AcknowledgementBox.Compact>
                    </>
                )}
                <ValidFormSubmitButtonHookForm ignorePristine force={!hasConflictingRecords ? 'active' : undefined} />
            </div>
        </RequestLoader.MultiLoader>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
