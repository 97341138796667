/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getFullPathname } from 'router/utils/getFullPathname';
import { parentConfigSchema, routeConfigSchema } from 'router/utils/schemas';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId, AnyRouteOptions, IndividualRouteConfig, RouteConfig } from 'router/types';
import { type PathObject } from 'router/utils';
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { brandStore } from 'config/hooks/useBrandStore';
import { intaserveRoutes } from 'router/config/intaserve';
import { routes as ventraRoutes } from 'router/config/ventra';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
/**
 * Returns the route config object for a given route. Will automatically determine the parent route if the path is not provided and therefore
 * determine the correct route config object.
 */
export function getRouteConfig<TOptions extends AnyRouteOptions | PathObject = PathObject>(options: TOptions) {
    const getResult = (routeObject: IndividualRouteConfig, path: AnyRouteId) => {
        return typeof routeObject === 'boolean' ? routeObject : { ...routeObject, pathname: path };
    };

    function getActiveBrandRouteConfig(): RouteConfig {
        switch (brandStore.state.activeBrand) {
            case 'intaserve':
                return intaserveRoutes;
            case 'ventra':
            default:
                return ventraRoutes;
        }
    }

    const activeBrandRouteConfig = getActiveBrandRouteConfig();

    try {
        // handle where the the path comes from a createRoute `this` object
        const parsedOptions = routeConfigSchema.parse(options);
        parentConfigSchema.parse(parsedOptions.getParentRoute());
        const path = getFullPathname(options);
        const routeObject = activeBrandRouteConfig[path];

        return getResult(routeObject, path);
    } catch (e) {
        // Handle manual object passed.
        const { path, hash } = options as NXUtils.ReplaceKey<PathObject, 'path', AnyRouteId, false>;

        const routeObject = activeBrandRouteConfig[path];
        const anyRouteObject: any = routeObject;
        const hashRouteObject = anyRouteObject?.hashes?.[hash!];

        // If the route isa a boolean, it cannot have a hash, so if we are trying to get a config
        // for a hash, then it's not possible and we should return a false value for the config.
        if (hash && typeof routeObject === 'boolean') {
            return getResult(false, path);
        }

        // If there is a hashes object, but this hash is not in it (or is false)
        if (hash && anyRouteObject?.hashes && !hashRouteObject) {
            return getResult(false, path);
        }

        return getResult(hashRouteObject ?? routeObject, path);
    }
}
