import logoBackground from 'assets/images/promo/cyberMonday/cyber-monday-shards.png';
import logo from 'assets/images/promo/cyberMonday/cyber-monday-viprewards-logo.svg';
import { SolidButton } from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useVipRewards } from 'containers/vipRewards/hooks';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_cyberMondayBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CyberMondayVipRewardsBanner = () => {
    /***** HOOKS *****/
    const isDesktop = useAppViewport(['lg', 'xl']);
    const isMobile = useAppViewport(['xs']);
    const { isAtOrAboveTier } = useVipRewards();

    /***** RENDER HELPERS *****/
    function renderButtonLink() {
        if (isAtOrAboveTier('vip')) {
            return 'https://vip.ventraip.com.au/my-services/hosting';
        }

        return 'https://vip.ventraip.com.au/vip-rewards';
    }

    /***** RENDER *****/
    return (
        <div className="cyberMondayBanner cyberMondayBanner__marginTop">
            <img src={logoBackground} alt="smashed whole in the screen" className="cyberMondayBanner__vipRewardsPageLogoBackground" />
            <Grid columns={isDesktop ? '.7fr 1.4fr' : '1fr'} gap={isMobile ? 15 : 0} className="cyberMondayBanner__grid">
                <img src={logo} alt="Yellow and black logo" className="cyberMondayBanner__vipRewardsPageLogo" />
                <Flex
                    direction={isMobile ? 'column' : 'row'}
                    items="center"
                    justify="between"
                    fullWidth
                    className="cyberMondayBanner__vipRewardsPageContent"
                >
                    <Flex direction="column" fullWidth gap={0} className="cyberMondayBanner__vipRewardsContentText">
                        <Text lead--1 bold className="cyberMondayBanner__vipRewardsPageContentTextHeading">
                            48-Hour Cyber {isMobile && <br />} Monday Deal
                        </Text>
                        <Text lead--none color="S_color_text_inverse_base" bold className="cyberMondayBanner__vipRewardsPageContentTextBody">
                            Renew Your Hosting Plan and Save 30%!{isMobile && <br />} Exclusive to VIPrewards Members.
                        </Text>
                    </Flex>
                    <SolidButton
                        type="anchor"
                        target="_blank"
                        href={renderButtonLink()}
                        className="cyberMondayBanner__vipRewardsButton"
                        aria-label="Learn more about the Cyber Monday sale"
                        width="full"
                    >
                        <Text size--m bold>
                            {isAtOrAboveTier('vip') ? 'RENEW MY PLAN' : 'SIGN UP TO VIPREWARDS'}
                        </Text>
                    </SolidButton>
                </Flex>
            </Grid>
        </div>
    );
};
//
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
