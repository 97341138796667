/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useSearch } from '@tanstack/react-router';
import { Store } from '@tanstack/react-store';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { values } from 'lodash';
import { useEffect, useLayoutEffect } from 'react';
import { useTanstackStoreKey } from 'utilities/hooks/tanstack-store/useStoreKey';

type ComponentDebugTrackerKeys =
    | 'RichTextEditor'
    | '_DevFormTypeChoiceWrapper'
    | '_DevKatanaPreviewFormDataExtractor'
    | '_DevKatanaSectionManager'
    | '_DevHookFormDebugger'
    | 'NAVIGATION_ITEMS_MANAGER';
export type ComponentDebugTracker = Partial<Record<ComponentDebugTrackerKeys, number>>;

/**********************************************************************************************************
 *   STORE START
 **********************************************************************************************************/
export const lastSuperActionAdded = new Store('');
export const componentDebugTracker = new Store<ComponentDebugTracker>({});
export const superUserSuperActionsState = new Store<Partial<Record<(typeof superActionKeys)[number], boolean>>>({});
export const newActionsStore = new Store<Partial<Record<SuperUserNamespace.ActionKeys, SuperUserNamespace.ActionKeys>>>({});

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useComponentDebugTracker = (key: keyof ComponentDebugTracker) => useTanstackStoreKey(componentDebugTracker, key);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useComponentDebugTrackerCount = (key: keyof ComponentDebugTracker) => {
    /***** HOOKS *****/
    const { update } = useComponentDebugTracker(key);

    /***** EFFECTS *****/
    useEffect(() => {
        update((count) => (count ? count + 1 : 1));
        return () => {
            update((count) => (count ? count - 1 : 0));
        };
    }, []);
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useSuperUserActionKey = (key: (typeof superActionKeys)[number], defaultState?: boolean) => {
    /***** HOOKS *****/
    const store = useTanstackStoreKey(superUserSuperActionsState, key);

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (defaultState) {
            store.set(defaultState);
        }
    }, []);

    /***** RESULTS *****/
    return store;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const SUPER_ACTIONS_KEYS = Object.freeze({
    KATANA_IMAX: 'KATANA_IMAX',
    KONAMI_CODE: 'KONAMI_CODE',
    LOGIN_VIEW_MANIPULATION: 'LOGIN_VIEW_MANIPULATION',
    RICH_TEXT_EDITOR_DEBUG: 'RICH_TEXT_EDITOR_DEBUG',
    HOOK_FORM_DATA_DEBUGGER: 'HOOK_FORM_DATA_DEBUGGER',
    KATANA_FORM_TYPE_CHOICE_WRAPPER: 'KATANA_FORM_TYPE_CHOICE_WRAPPER',
    KATANA_SECTION_MANAGER: 'KATANA_SECTION_MANAGER',
    CONTROLSHIFTDVD: 'CONTROLSHIFTDVD',
    BIGDADDY: 'BIGDADDY',
    SV_CHEATS_1: 'SV_CHEATS_1',
    KATANA_PREVIEW_IN_SERVICE_LIST: 'KATANA_PREVIEW_IN_SERVICE_LIST',
    TOGGLE_ROUTER_DEVTOOLS: 'TOGGLE_ROUTER_DEVTOOLS',
    TOGGLE_MODULE_REASON: 'TOGGLE_MODULE_REASON',
    NAVIGATION_ITEMS_MANAGER: 'NAVIGATION_ITEMS_MANAGER'
});

export const SUPER_ACTIONS: SuperUserNamespace.ActionDictionary = {
    [SUPER_ACTIONS_KEYS.KATANA_IMAX]: {
        menuContent: 'VIPsites IMAX',
        useDisplayCondition: () => {
            const location = useLocation();
            return location.pathname.includes('vip-sites');
        }
    },
    [SUPER_ACTIONS_KEYS.NAVIGATION_ITEMS_MANAGER]: {
        menuContent: 'Navigation Items Manager',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('NAVIGATION_ITEMS_MANAGER');
            return Boolean(count);
        }
    },
    TOGGLE_ROUTER_DEVTOOLS: {
        menuContent: 'Toggle Router DevTools',
        useDisplayCondition: () => import.meta.env.DEV
    },
    TOGGLE_MODULE_REASON: {
        menuContent: 'Toggle Module Reason',
        useDisplayCondition: () => {
            const { hash } = useLocation();
            const { module: selectedModule } = useSearch({ strict: false });
            return import.meta.env.DEV && (!!hash?.length || !!selectedModule);
        }
    },
    [SUPER_ACTIONS_KEYS.KATANA_PREVIEW_IN_SERVICE_LIST]: {
        menuContent: 'VIPsites Preview in Service List',
        useDisplayCondition: () => {
            const location = useLocation();
            return location.pathname.includes('vip-sites');
        }
    },
    [SUPER_ACTIONS_KEYS.LOGIN_VIEW_MANIPULATION]: {
        menuContent: 'Login View Manipulation',
        useDisplayCondition: () => {
            const location = useLocation();
            return location.pathname.includes('login');
        }
    },
    [SUPER_ACTIONS_KEYS.KATANA_SECTION_MANAGER]: {
        menuContent: 'VIPSites Section Manager',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('_DevKatanaSectionManager');
            return Boolean(count);
        }
    },
    [SUPER_ACTIONS_KEYS.RICH_TEXT_EDITOR_DEBUG]: {
        menuContent: 'Rich Text Editor Debug',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('RichTextEditor');
            return Boolean(count);
        }
    },
    [SUPER_ACTIONS_KEYS.HOOK_FORM_DATA_DEBUGGER]: {
        menuContent: 'Hook Form Data',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('_DevHookFormDebugger');
            return Boolean(count);
        }
    },
    [SUPER_ACTIONS_KEYS.KATANA_FORM_TYPE_CHOICE_WRAPPER]: {
        menuContent: 'Katana Form Type Choice Wrapper',
        useDisplayCondition: () => {
            const { value: count } = useComponentDebugTracker('_DevFormTypeChoiceWrapper');
            return Boolean(count);
        }
    },
    [SUPER_ACTIONS_KEYS.KONAMI_CODE]: {
        menuContent: 'Konami Code',
        cheatCode: 'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba'
    },
    [SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD]: {
        menuContent: 'DVD Logo',
        cheatCode: 'ControlShiftDVD'
    },

    [SUPER_ACTIONS_KEYS.BIGDADDY]: {
        menuContent: 'Big Daddy',
        cheatCode: 'bigdaddy',
        condition: () => lastSuperActionAdded.state === SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD
    },
    [SUPER_ACTIONS_KEYS.SV_CHEATS_1]: {
        menuContent: 'sv_cheats 1',
        cheatCode: 'svShift_cheats 1'
    }
};

export const superActionKeys = values(SUPER_ACTIONS_KEYS);
export const superActionNoCheats = superActionKeys.filter((actionKey) => !SUPER_ACTIONS[actionKey]?.cheatCode);
export const maxActionKeyLength = values(SUPER_ACTIONS)
    .filter(({ cheatCode }) => cheatCode)
    .reduce((max, { cheatCode }) => Math.max(max, cheatCode?.length ?? 0), 0);
