/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useEffect } from 'react';

const _newSectionOrganiserData = new Store<Record<KatanaNamespace.SectionID, true>>({});

/**
 * This hook checks if the provided id is in the new data and returns a boolean
 */
const useIsNewSectionData = (id: KatanaNamespace.SectionID | null) => useStore(_newSectionOrganiserData, (state) => id && Boolean(state[id]));

function addNewSectionOrganiserID(id: KatanaNamespace.SectionID) {
    _newSectionOrganiserData.setState((value) => ({ ...value, [id]: true }));
}
function removeNewSectionOrganiserID(id: KatanaNamespace.SectionID) {
    _newSectionOrganiserData.setState((value) => ({ ...value, [id]: false }));
}

/**
 * When the hook mounts it checks if the id is in the "newSectionTrackerData" writable, if it is, when the component unmounts, the value gets removed.
 */
function useNewSectionVisitedEffect(id: KatanaNamespace.SectionID | null) {
    /***** HOOKS *****/
    const isIdNew = useIsNewSectionData(id);

    /***** EFFECTS *****/
    useEffect(() => {
        if (isIdNew && id) {
            return () => {
                _newSectionOrganiserData.setState((data) => {
                    const cloned = _.clone(data);
                    delete cloned[id];
                    return cloned;
                });
            };
        }
    }, [isIdNew, id]);
}

export const newSectionTrackerData = Object.assign(_newSectionOrganiserData, {
    useIsNew: useIsNewSectionData,
    addNewID: addNewSectionOrganiserID,
    removeID: removeNewSectionOrganiserID,
    useVisited: useNewSectionVisitedEffect
});
