import KatanaLoaderImage from 'components/Loaders/App/katanaLoaderImage';
import { intaserveSvg } from 'components/Loaders/App/svgAnimations/intaserve';
import { ventraSvg } from 'components/Loaders/App/svgAnimations/ventra';
import type { TextNamespace } from 'components/Utils/Text/_Text/types';
import { serviceNav } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import React from 'react';
import './_AppLoader.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface AppLoaderProps {
    className?: string;
    message?: string;
    fullHeight?: boolean;
    backgroundColor?: TextNamespace.Props['color'];
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const AppLoader: React.FC<AppLoaderProps> = ({ className, message, fullHeight, backgroundColor }) => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    /***** RENDER HELPERS *****/
    const isVIPsitesPage = window.location.pathname.includes(serviceNav.VIPsites.link);

    function getAppLoaderSvg() {
        switch (activeBrand) {
            case 'intaserve':
                return <div style={{ width: 150, height: 150 }}>{intaserveSvg(backgroundColor || 'S_color_background_default_base')}</div>;
            case 'ventra':
                return <div style={{ width: 150, height: 150 }}>{ventraSvg}</div>;
        }
    }

    /***** RENDER *****/
    return (
        <div className={`appLoader${className ? ` ${className}` : ''}${fullHeight ? ' appLoader--fullHeight' : ''}`}>
            {isVIPsitesPage ? <KatanaLoaderImage /> : getAppLoaderSvg()}
            {message ? <div className="loader__text">{message}</div> : ''}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default AppLoader;
