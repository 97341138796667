import classNames from 'classnames';
import { tagColors } from 'components/Tags/OutlineTag';
import React from 'react';
import './_SolidTag.scss';

const solidTagColors = [
    ...tagColors,
    'record__pink',
    'record__purple',
    'record__violet',
    'record__blue',
    'record__aqua',
    'record__green',
    'record__orange',
    'record__red',
    'black',
    'legacy'
] as const;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SolidTagProps = {
    /**
     * Text content of the tag
     */
    children: React.ReactNode;

    /**
     * Color of the tag
     */
    color: (typeof solidTagColors)[number];

    /**
     * Additional class to give to the tag
     */
    className?: string;

    /**
     * Make the tag rounded
     */
    round: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SolidTag: React.FC<SolidTagProps> = ({ children, color, className, round }) => (
    <div className={classNames('SolidTag', color, className, { round })}>{children}</div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SolidTag;
