/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { createKatanaServiceId } from 'containers/katana/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesOverviewRoute = createRoute({
    getParentRoute: () => VIPSitesRoute,
    path: 'overview',
    async loader({ params }) {
        await katanaQuery.serviceID.service.prefetchQuery(createKatanaServiceId(params.katanaServiceId));
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./overview.lazy'), 'LazyVIPSitesOverviewRoute'));
