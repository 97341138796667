/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { AxiosError, Cancel } from 'axios';
import axios, { isAxiosError, isCancel } from 'axios';
import { promotions } from 'config/config';
import { has } from 'lodash';
import { DateTime } from 'luxon';
import { animateScroll as scroll } from 'react-scroll';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNewSidebarRefs } from 'components/Sidebar/action';

import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';

/*   ACTIONS
 *****************************************************/
import { APP_USER_RESET } from 'App/actionTypes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { AnyRouteId } from 'router/types';
import type { DefaultErrorResponse } from 'utilities/methods/types';
import { COMMON_COPY_SUCCESS, COMMON_TOOLTIP_CHANGE } from './declerations';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/
export const toolTipStateUpdate = (state) => {
    return (dispatch) => {
        dispatch({
            type: COMMON_TOOLTIP_CHANGE,
            common_tooltip_open: state
        });
    };
};

export const booleanValidation = (value) => {
    return value === true || value === false || value === undefined ? undefined : 'Must be true or false';
};

export const isString = (string) => {
    return typeof string === 'string' || string instanceof String;
};

export const isValid = (variable) => {
    return typeof variable !== 'undefined';
};

export const generateId = () => {
    return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);
};

export const pluralize = (item, output = { one: '', notOne: 's' }) => {
    return item > 1 || item === 0 ? output.notOne : output.one;
};

/**
 * @template T
 * @template Q
 * @param {Array<{ type: NonNullable<T> } & Q> | []} included
 * @param {T} type
 * @returns {{ type: T; } & Q | null | undefined}
 */
export const getIncludedObjBasedOnType = (included, type) => {
    if (included?.length) {
        return included.find((item) => {
            return item.type === type;
        });
    } else {
        return null;
    }
};

export const getObjBasedOnValue = (data, value) => {
    if (data && data.length > 0) {
        return Object.keys(data).filter((i) => data[i] === value);
    }
    return null;
};

export function getDataFromSuccessResponse(response: unknown, text?: any) {
    const { data } = response;

    const hasOwnData = has(data, 'data');
    if (has(data, 'message')) {
        if (hasOwnData) {
            return {
                status: 200,
                details: text ? text : data.message,
                ...data.data
            };
        }

        return {
            status: 200,
            details: text ? text : data.message
        };
    } else if (data && hasOwnData && text) {
        return {
            status: 200,
            details: text,
            ...data.data
        };
    } else if (data && hasOwnData) {
        return data.data;
    }

    return data;
}

export function getKeyFromData(data, key) {
    if (has(data, key)) {
        return data[key];
    }
    return null;
}

export function getMetaFromData(data) {
    return getKeyFromData(data, 'meta');
}

/**
 * @template {object} TData
 * @param {TData} data
 * @returns {TData['data']}
 */
export function getDataFromData(data) {
    return getKeyFromData(data, 'data');
}

export const getMetaFromSuccessResponse = (response) => {
    const { data } = response;
    return getMetaFromData(data);
};

export function getMessageFromErrorsData(data) {
    if (data?.errors?.length) {
        const errorType = typeof data?.errors?.[0];

        if (Array.isArray(data?.errors?.[0])) {
            return data?.errors?.[0]?.[0];
        }

        if (errorType === 'string') {
            return data?.errors?.[0];
        }

        return data?.errors?.[0]?.details ?? '';
    }

    return data?.errors ?? '';
}

/**
 * @param {*} error
 * @returns {{
 *   details: string;
 *   code?: string;
 *   status: number | string;
 * }}
 */
export const getErrorFromFailResponse = (error) => {
    const { response } = error;

    if (axios.isCancel(error)) {
        return {
            status: 'Failed',
            details: 'Response Cancelled'
        };
    }

    if (has(response, 'status') && response.status) {
        const { data } = response;
        const { code } = data;

        switch (response.status) {
            case 400:
            case 422:
                if (has(data, 'errors')) {
                    return {
                        details: getMessageFromErrorsData(data),
                        code,
                        status: data.status
                    };
                }

                if (has(data, 'message')) {
                    return {
                        status: data.status,
                        details: data.message
                    };
                }
                break;

            case 429:
                return {
                    status: response.status,
                    details: 'Please wait a minute and try again.'
                };

            case 401:
                switch (code) {
                    case 'ERR_ACTIVATION_TOKEN_ACTIVATED':
                        return {
                            status: response.status,
                            details: 'Your account has been activated.'
                        };

                    case 'ERR_ACTIVATION_APP_USER_RESET':
                        return {
                            status: response.status,
                            details: 'This link has expired. Please contact the owner account to re-invite and activate your account.'
                        };

                    default:
                        if (data.errors && data.errors.length > 0 && !data.errors[0].details) {
                            return {
                                code,
                                status: response.status,
                                details:
                                    'Your authentication details are invalid. Please re-check the confirmation code, token and try again. Alternatively, you can contact our support team if you believe this is incorrect.'
                            };
                        }
                        if (data.errors && data.errors.length > 0) {
                            return {
                                code,
                                ...data.errors[0]
                            };
                        }
                        return {
                            code,
                            ...data.errors
                        };
                }

            case 403:
                return {
                    status: response.status,
                    details:
                        'You do not have the required permission to access this information. Please contact our support team if you believe this is incorrect.',
                    code
                };

            default:
                return {
                    status: response.status,
                    details: `It looks like something went wrong. Please try again later or contact our support team.`,
                    code
                };
        }
    }
    return {
        status: 'N/A',
        details: `It looks like something went wrong. Please try again later or contact our support team.`
    };
};

export const getMetaFromErrorResponse = (error) => {
    const { response } = error;
    let returnedMeta = null;

    if (response && has(response, 'errors') && response.errors) {
        if (response.data.errors[0].meta) {
            returnedMeta = response.data.errors[0].meta;
        }
    }

    return returnedMeta;
};

export const handleGeneralSuccessResponse = (data, dispatch, fetchpagesuccessAction) => {
    if (data) {
        dispatch({
            type: fetchpagesuccessAction,
            fetch_page_data: data
        });
    }
};

export const handleGeneralErrorResponse = (error, dispatch, fetchpageerrorAction) => {
    let returnedError = null;
    if (error && error.response) {
        returnedError = error.response;
        if (returnedError.status === 401) {
            dispatch({
                type: APP_USER_RESET
            });
        } else {
            dispatch({
                type: fetchpageerrorAction,
                fetch_page_error: error
            });
        }
    } else {
        dispatch({
            type: fetchpageerrorAction,
            fetch_page_error: error
        });
    }
};

/*   DATE PROCESSING
 **********************************************************************************************************/

/************** Get current date **************/

// takes date string and format string
export const toLuxonDateDefaultFormat = 'yyyy-MM-dd TT';
export const toLuxonDate = (date, format = toLuxonDateDefaultFormat) => DateTime.fromFormat(date || '', format);

export const getCurrentDate = (usersLocalTime = false) => (usersLocalTime ? DateTime.local() : DateTime.local().setZone('Australia/Sydney'));

/************** Evaluate dates **************/

/**
 * Check if the date is between star and end date.
 * @param {DateTime} startDate
 * @param {DateTime} endDate
 * @param {DateTime} date
 * @returns {boolean}
 */
export const isBetweenDates = (startDate, endDate, date = getCurrentDate()) => date > startDate && date < endDate;

/**
 * Checks if the date is after the start date
 * @param {DateTime} startDate
 * @param {DateTime} date
 * @returns {boolean}
 */
export const isAfterDate = (startDate, date = getCurrentDate()) => date > startDate;

/**
 * Checks if the date is before the end date
 * @param {DateTime} endDate
 * @param {DateTime} date
 * @returns {boolean}
 */
export const isBeforeDate = (endDate, date = getCurrentDate()) => date < endDate;

export const promoDateFormat = 'MMM dd, yyyy TT';

/**
 * Check if the promotions config dates are between the current dates
 * @param {keyof typeof promotions | 'default'} which - Which promotion period you want to check
 * @returns {boolean}
 */
export const activePromotion = (which = 'default') => {
    const promoPeriod = promotions[which];

    if (!promoPeriod) {
        return false;
    }

    const dateNow = getCurrentDate();

    if (promoPeriod.start && promoPeriod.end)
        return isBetweenDates(toLuxonDate(promoPeriod.start, promoDateFormat), toLuxonDate(promoPeriod.end, promoDateFormat), dateNow);

    if (promoPeriod.start && !promoPeriod.end) return isAfterDate(toLuxonDate(promoPeriod.start, promoDateFormat), dateNow);

    if (!promoPeriod.start && promoPeriod.end) return isBeforeDate(toLuxonDate(promoPeriod.end, promoDateFormat), dateNow);

    return false;
};

export const isBusinessHours = () => {
    const now = getCurrentDate();
    return now.c.hour >= 9 && now.c.hour < 17 && ![6, 7].includes(now.weekday);
};

/************** Get durations / diffs **************/

// takes luxon instances
export const getDaysBetween = (date, start = getCurrentDate()) => {
    const days = date.diff(start, ['days']).toObject().days;
    return typeof days === 'number' ? Math.ceil(days) : undefined;
};

/************** Rendering in specific formats **************/

// takes a luxon instance
export const renderPrepaidForDuration = (date, start = getCurrentDate()) => {
    const diff = date.diff(start, ['years', 'months', 'days']).toObject();
    const { days, months, years } = diff;

    if (Object.keys(diff).length === 0) return 'Unavailable';

    const yearsStr = years ? `${years} Year${pluralize(years)} ` : '';
    const monthsStr = months || years ? `${years ? months + 1 : months} Month${pluralize(years ? months + 1 : months)} ` : '';
    const daysStr = days && !years ? `${Math.ceil(days)} Day${pluralize(Math.ceil(days))}` : '';

    return `${yearsStr}${monthsStr}${daysStr}`.trim();
};

// takes num of milliseconds
export const getHrsAndMinsFromMillis = (millis) => {
    const totalMins = millis / 1000 / 60;
    let hrs = Math.floor(totalMins / 60);
    const mins = Math.round(totalMins % 60);

    if (mins === 60) {
        hrs++;
        return `${hrs} hr${pluralize(hrs)}`;
    }

    if (mins === 0) {
        return hrs === 0 ? '0 mins' : `${hrs} hr${pluralize(hrs)}`;
    }

    return `${hrs > 0 ? `${hrs} hr${pluralize(hrs)}` : ''} ${`${mins} min${pluralize(mins)}`}`.trim();
};

// takes a luxon instance
export const getDaysHoursMins = (date) => {
    const { days, hours, minutes } = date.diff(getCurrentDate(), ['days', 'hours', 'minutes']).toObject();

    const roundedMins = Math.floor(minutes);

    return `${days ? `${days} day${pluralize(days)}, ` : ''}${hours ? `${hours} hour${pluralize(hours)} and ` : ''}${`${
        roundedMins || 0
    } minute${pluralize(roundedMins)}`}`;
};

/**
 * Adds date day ordinals `st, nd, rd, th`
 * @param {number} i
 * @returns {string}
 */
export function ordinalSuffixOf(i) {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
}

/**
 * formatting text {1} to 1st etc
 * for example `formatLuxonOrdinal(DateTime.now().toFormat('{d} MM yyyy'))` will become `{1} 10 2022` will become `1st 10 2022`
 * @param {string} outputString must have the ordinal value wrapped in {} i.e. {12}
 * @returns
 */
export function formatLuxonOrdinal(outputString) {
    if (!['{', '}'].every((char) => outputString.includes(char))) return outputString;
    const destructuredStringOpen = outputString.split('{');
    const destructuredStringClosed = destructuredStringOpen[1].split('}');
    const dateValue = Number(destructuredStringClosed[0]);
    const ordinalValue = ordinalSuffixOf(dateValue);
    return destructuredStringOpen[0] + ordinalValue + destructuredStringClosed[1];
}

/*   TEXT
 **********************************************************************************************************/
export const truncateText = (text, length, end) => {
    if (!text) {
        return text;
    }

    const [domain, ...ext] = text.split('.');
    return domain.length > length ? domain.substring(0, length - end.length) + `${end}.${ext.join('')}` : text;
};

export const truncateSimple = (text, length, end) => {
    return text && text.length > length ? text.substring(0, length) + end : text;
};

export const textLowerCase = (text) => {
    return text && (typeof text === 'string' || text instanceof String) ? text.toLowerCase() : text;
};

export const getDepartmentPretty = (urlEnding) => {
    switch (urlEnding) {
        case 'accounts-billing':
            return 'Accounts and Billing';
        case 'sales':
            return 'Sales';
        case 'customer-care':
        case 'feedback':
        case 'compliment':
        case 'complaint':
        case 'other':
            return 'Customer Care';
        case 'technical-support':
        default:
            return 'Technical Support';
    }
};

export const capitalize = (string) => (typeof string === 'string' && string.length > 0 ? string.split('')[0].toUpperCase() + string.slice(1) : '');

/*   COPY TO CLIPBOARD
 **********************************************************************************************************/
export const copyToClipboard = (text) => {
    return (dispatch) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                dispatch({
                    type: COMMON_COPY_SUCCESS
                });
                pushNotification({ details: 'Copied to clipboard!', status: 200 }, null, notificationScopes.GLOBAL);
            })
            .catch(() => {
                pushNotification({ details: 'Failed to copy', status: 400 }, null, notificationScopes.GLOBAL);
            });
    };
};

/*   TIMER
 **********************************************************************************************************/
export const Timer = (callback, delay) => {
    let timerId,
        start,
        remaining = delay;

    const pause = () => {
        window.clearTimeout(timerId);
        remaining -= Date.now() - start;
    };

    const cancel = () => {
        window.clearTimeout(timerId);
    };

    const resume = () => {
        start = Date.now();
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, remaining);
    };

    resume();

    return { cancel: cancel, pause: pause, resume: resume };
};

/*   SCROLLING
 **********************************************************************************************************/
export const scrollToTop = () => {
    scroll.scrollTo(0);
};

/**
 * Scrolls to a HTMLElement
 * @param {HTMLElement | undefined | null} ref
 */
export const scrollToRef = (ref) => {
    if (ref) {
        const position = window.scrollY + ref.getBoundingClientRect().top - 20;
        scroll.scrollTo(position);
    }
};

export const scrollOptions = {
    duration: 2000,
    delay: 0,
    smooth: 'easeInOutQuint',
    offset: 0,
    isDynamic: true
};

const firstComponentRefs: Array<`${AnyRouteId}#${string}`> = [
    '/account/general#overview',
    '/account/security#password',
    '/my-services/domains/$domainId/general#overview',
    '/my-services/domains/$domainId/manage#dns',
    '/my-services/domains/$domainId/security#id-protection',
    '/my-services/domains/$domainId/admin#move',
    '/my-services/hosting/$serviceId/account#overview',
    '/my-services/hosting/$serviceId/config#temp-url',
    '/my-services/hosting/$serviceId/security#autossl',
    '/my-services/hosting/$serviceId/admin#move',
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/admin#delete',
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general#mailboxes',
    '/my-services/email-hosting/$groupId/tools#alias',
    '/my-services/google/$workspaceId/manage#overview',
    '/my-services/google/$workspaceId/admin#account-details',
    '/my-services/microsoft-365/$microsoftId/manage#overview',
    '/my-services/microsoft-365/$microsoftId/admin#delete-subscriptions',
    '/my-services/vps/$vpsId/account#overview',
    '/my-services/vps/$vpsId/admin#format-vps',
    '/my-services/vps/$vpsId/manage#power-management',
    '/my-services/vps/$vpsId/addons#resource-addons',
    '/my-services/ssl/$sslId/manage#overview'
];

/**
 * Performs wizardry and converts the typesafe paths into regexes 🥹
 */
const firstComponentRegexes = firstComponentRefs
    .map((value) => value.replaceAll('/', '\\/'))
    .map((value) => value.replaceAll(/\/\$(.+)\//g, '/.+/'))
    .map((str) => new RegExp(str));

/**
 * @returns {Record<string, HTMLElement>}
 */
export function getSidebarState() {
    const getSiderefs = (state) => {
        // Grab the current sidebar references so we can compare and optimise for later
        return state.sidebar.sidebarRefs;
    };

    return getSiderefs(store.getState());
}

// Remove all sidebar refs (used when subpage unmounts)
export const resetScrollEvents = () => {
    return (dispatch) => {
        dispatch(pushNewSidebarRefs({}));
    };
};

/**
 * Can be called when the scrollable component is first ready. This will not scroll if the component is the first (instead scrolling to the
 * very top of the page. This functionality should be replaced to be based on the dom position and the highest one so we don't need to
 * manually store all of these routes and risk missing new ones)
 */
export const scrollToComponent = (ref: HTMLElement, path: string) => {
    const sidebarRefs = getSidebarState();
    const rect = ref.getBoundingClientRect();
    const hasRefsAndBound = sidebarRefs && ref && rect;
    const isFirstComponent = firstComponentRegexes.some((regex) => regex.test(path));

    if (isFirstComponent) {
        scroll.scrollTo(0, scrollOptions);
        return;
    }

    if (hasRefsAndBound) {
        scroll.scrollTo(rect.top + window.scrollY - 15, scrollOptions);
        return;
    }
};

/*   STANDARD JS FUNCTIONS
 **********************************************************************************************************/
export const flatten = (objectOrArray) => {
    const nestElement = (prev, value, key) =>
        value && typeof value === 'object' ? { ...prev, ...flatten(value) } : { ...prev, ...{ [`${key}`]: value } };

    return Array.isArray(objectOrArray)
        ? objectOrArray.reduce(nestElement, {})
        : Object.keys(objectOrArray).reduce((prev, element) => nestElement(prev, objectOrArray[element], element), {});
};

/*   CHECK BROWSER
 **********************************************************************************************************/
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/*   EXPORT ARRAY TO CSV
 **********************************************************************************************************/
export const exportToCSV = (data, columns, filename) => {
    const COLUMN_SEPARATOR = ',';
    const ROW_SEPARATOR = '\r\n';
    const UNICODE_BOM = '\uFEFF';

    const wrapValue = (value) => `"${value}"`;
    const escapeValue = (value) => (value || '').replace(/"/, '""');

    const toHeaderRow = (columns) => Object.keys(columns).map(escapeValue).map(wrapValue).join(COLUMN_SEPARATOR);

    const toRow = (columns, item) =>
        Object.values(columns)
            .map((field) => (typeof field === 'function' ? field(item) : item[field]))
            .map((value) => (value === null ? '' : value))
            .map(String)
            .map(escapeValue)
            .map(wrapValue)
            .join(COLUMN_SEPARATOR);

    const rows = [];

    rows.push(toHeaderRow(columns));

    for (const item of data) {
        rows.push(toRow(columns, item));
    }

    const csv = UNICODE_BOM + rows.join(ROW_SEPARATOR);
    const type = isSafari() ? 'application/csv' : 'text/csv';
    const uri = `data:${type};charset=utf-8;header=present,${encodeURIComponent(csv)}`;

    const link = document.createElement('a');
    link.setAttribute('href', uri);
    link.setAttribute('download', filename);
    link.addEventListener('click', () => link.parentNode.removeChild(link));
    document.body.appendChild(link);

    link.click();
};

/*   FORMAT INVOICE DESCRIPTION
 **********************************************************************************************************/
/**
 * ! DEPRECATED, USE `FormattedDescription` component instead!
 */
export const formatDescription = (description) => {
    if (description) {
        let desc = '';
        if (/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi.test(description.toLowerCase())) {
            desc = description.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi, '<strong>$&</strong>');
        } else {
            if (/[^!@][a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+/g.test(description)) {
                desc = description.replace(/[^!@][a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+/g, '<strong>$&</strong>');
            } else {
                desc = description;
            }
        }
        return desc;
    } else {
        return description;
    }
};

/*   CREATE FILE TO DOWNLOAD
 **********************************************************************************************************/
export const createDownloadFile = (data, filename, isAlreadyBlob) => {
    const url = window.URL.createObjectURL(isAlreadyBlob ? data : new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

/*   Number processing
 **********************************************************************************************************/
export const addCommasToNumber = (num) => {
    const arr = num.toString().split('');
    const beforeDecimal = [...arr];
    let afterDecimal = '';
    if (beforeDecimal.includes('.')) afterDecimal = beforeDecimal.splice(beforeDecimal.indexOf('.'));
    const newArr = [...beforeDecimal];
    if (beforeDecimal.length > 3) {
        for (let i = beforeDecimal.length - 3; i > 0; i -= 3) {
            newArr.splice(i, 0, ',');
        }
    }
    return [...newArr, ...afterDecimal].join('');
};

export const convertToNumber = (value) => {
    switch (typeof value) {
        case 'number':
            return value;
        case 'string':
            if (value.toLowerCase() === 'unlimited') return Infinity;
            if (!isNaN(value)) return Number(value);
            return 0;
        default:
            return 0;
    }
};

export const roundUpToNearestCent = (num) => (Math.ceil(num * 100) / 100).toFixed(2);

export const isLastPage = (meta) => {
    if (!meta) return true;
    const { current_page, last_page } = meta;
    return current_page && last_page && current_page === last_page;
};

/*   Processing usage stats
 **********************************************************************************************************/
export const processUsageStats = {
    cpu: {
        max: () => 100,
        maxTitle: () => '100%',
        value: (cpuUsage) => convertToNumber(cpuUsage),
        valueTitle: (cpuUsage) => `${convertToNumber(cpuUsage)}%`
    },

    inode: {
        max: (inodeLimit) => convertToNumber(inodeLimit),
        maxTitle: (inodeLimit) => (convertToNumber(inodeLimit) === Infinity ? '∞' : addCommasToNumber(convertToNumber(inodeLimit))),
        value: (inodeUsage) => convertToNumber(inodeUsage),
        valueTitle: (inodeUsage) => (convertToNumber(inodeUsage) === Infinity ? '∞' : addCommasToNumber(convertToNumber(inodeUsage)))
    },

    memory: {
        max: (memoryLimit) => convertToNumber(memoryLimit),
        maxTitle: (memoryLimit) => (convertToNumber(memoryLimit) === Infinity ? '∞' : `${Number(convertToNumber(memoryLimit).toFixed(3))} GB`),
        value: (memoryUsage) => convertToNumber(memoryUsage),
        valueTitle: (memoryUsage) => (convertToNumber(memoryUsage) === Infinity ? '∞' : `${Number(convertToNumber(memoryUsage).toFixed(3))} GB`)
    },

    disk: {
        max: (diskLimit) => convertToNumber(diskLimit),
        maxTitle: (diskLimit) => (convertToNumber(diskLimit) === Infinity ? '∞' : `${Number(convertToNumber(diskLimit).toFixed(3))} GB`),
        value: (diskUsage) => convertToNumber(diskUsage),
        valueTitle: (diskUsage) => (convertToNumber(diskUsage) === Infinity ? '∞' : `${Number(convertToNumber(diskUsage).toFixed(3))} GB`)
    }
};

export function expiryTextFormatter(expiry, tense = 'future') {
    const expiryString = expiry.toString();
    if (expiryString === '0') {
        return 'today.';
    }

    switch (tense) {
        case 'future':
        default:
            return `in ${expiryString.substring(1)} days.`;
        case 'past':
            return `${expiryString.substring(1)} days ago.`;
    }
}

/*   URL QUERY STRING HELPER
 **********************************************************************************************************/
export const urlQueryBuilder = (params) => {
    if (!params) {
        return '';
    }

    return Object.entries(params)
        .filter(([, value]) => value)
        .reduce((prev, [currentKey, currentValue], currentIndex) => prev + `${currentIndex !== 0 ? '&' : ''}${currentKey}=${currentValue}`, '?');
};

/**
 *
 * @param {string} [url]
 * @param {string} [target='_blank']
 * @param {string} [windowFeatures]
 * Handle creation / destruction of popupWindow
 * @returns {{
 *   popupWindow: any,
 *   setPopupUrl: any,
 *   closePopup: any,
 *   goToTargetUrl: any
 * }}
 */
export function createPopup(url?: string, target: string = '_blank', windowFeatures?: string) {
    const _globalState = store.getState();
    const { iOS } = _globalState.app;

    // if the browser is iOS we don't want to do window.open, instead we want to replace the location
    const _popupWindow = !iOS ? window.open(url, target, windowFeatures) : null;

    /**
     * Set the url of the popup, with fallback for iOS
     * @param {string} _url
     */
    function _setPopupUrl(_url) {
        if (iOS) {
            window.location.replace(_url);
        } else {
            _popupWindow.location.replace(_url);
        }
    }

    /**
     * Handles popup close action.
     */
    function _closePopup() {
        // Return of it's ios or a popupWindow has not been created. can't close unopened window..
        if (iOS || !_popupWindow) return;
        _popupWindow.close();
    }

    /**
     * Perform navigation to the given URL, handles errors.
     * @param {string} target_url
     */
    function _goToTargetUrl(target_url) {
        if ((!iOS && _popupWindow === null && target_url) || !target_url) {
            _closePopup();

            let errorMessage;
            if (_popupWindow === null && target_url) {
                errorMessage = 'Cannot open login window! Please enable popups for vip.ventraip.com.au and then try again.';
            } else if (!target_url) {
                errorMessage = "Something's gone wrong. Please try again later or contact our support team.";
            }

            if (errorMessage) {
                pushNotification({
                    data: {
                        errors: [
                            {
                                details: errorMessage
                            }
                        ]
                    }
                });
            }
        } else {
            _setPopupUrl(target_url);
        }
    }

    return {
        popupWindow: _popupWindow,
        setPopupUrl: _setPopupUrl,
        closePopup: _closePopup,
        goToTargetUrl: _goToTargetUrl
    };
}

export function iconHideShowEyeClassNameObject(displayState) {
    return {
        'icon-eye-closed': !displayState,
        'icon-eye-open': displayState
    };
}

const accessibilityConfirmKeys = [' ', 'Enter'];
export function accessibilityClick(e) {
    return accessibilityConfirmKeys.includes(e.key);
}

/**
 * return the copy of an object where the object key is converted into value and object value is converted into the key.
 * It means the [key, value] of the object is reverse.
 * @param {Object} obj
 * @returns {Object}
 */
export function objectInvert(obj) {
    const inverseObject = {};
    for (const key in obj) {
        inverseObject[obj[key]] = key;
    }
    return inverseObject;
}

/**
 * Returns the string as it's unique characters only
 * @param {string} str
 * @returns
 */
export function stringUniqueCharacters(str) {
    return String.prototype.concat.call(...new Set(str));
}

/**
 * Check's multiple Redux statuses for a common state.
 * Error takes first priority, then loading, then success.
 * otherwise it's null
 * Usage:
 * `checkMultipleReduxStatuses(some_redux_status, another_redux_status, etc...)
 */
export function checkMultipleReduxStatuses(...args) {
    if (args.includes('error')) return 'error';
    if (args.includes('loading')) return 'loading';
    if (args.every((status) => status === 'success')) return 'success';
    return null;
}

/**
 * @type {Map<any, { lastErrorTime: number, timeout?: NodeJS.Timeout }>}
 */
const lastErrors = new Map();
const errorDuplicationTimeout = 2500;
/**
 * Query functions
 */
/**
 * Default handling of error response by showing notification
 */
export function handleDefaultErrorNotification(error: DefaultErrorResponse | Cancel | AxiosError) {
    if (isCancel(error)) {
        return;
    }

    if (lastErrors.has(error)) {
        const lastError = lastErrors.get(error);
        if (lastError) {
            const { lastErrorTime, timeout } = lastError;
            const diff = Date.now() - lastErrorTime;
            if (diff < errorDuplicationTimeout) {
                clearTimeout(timeout);

                const lastErrorTimeout = setTimeout(() => {
                    lastErrors.delete(error);
                }, errorDuplicationTimeout);

                lastErrors.set(error, { lastErrorTime: Date.now(), timeout: lastErrorTimeout });
                return;
            }
        }
    } else {
        lastErrors.set(error, { lastErrorTime: Date.now() });
    }

    if (isAxiosError(error)) {
        pushNotification({ message: error.message, status: error.status || 500 });
        return;
    }

    const message = error?.message || getMessageFromErrorsData(error);
    pushNotification({ ...error, message });
}

/**
 * Default handling of success response by showing notification
 */
export function handleDefaultSuccessNotification(response) {
    pushNotification(response);
}

/**
 * @deprecated use `_.clone` instead
 * @param {Object} _object
 * @returns {Object}
 */
export function cloneObject(_object) {
    return JSON.parse(JSON.stringify(_object));
}

/**
 * Changes all the words separated by white space to have the first letter be capitalised
 */
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
