import helloFreshImage from 'assets/images/promo/helloFreshAUOffer/helloFreshAU.jpg';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';
import { useState } from 'react';
import '../_rewardCard.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HelloFreshAUOfferCard = () => {
    /***** STATE *****/
    const [isModalOpen, setIsModalOpen] = useState(false);

    /***** RENDER HELPERS *****/
    function renderLightBox() {
        return (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} ariaLabel="Contest Entry Confirmation">
                <Padding top={5} x={5}>
                    <Flex direction="column" justify="center" items="center" gap={4}>
                        <Text size--h2 medium lead--none>
                            Claim your discount
                        </Text>
                        <Text color="S_color_text_secondary_base" size--m medium lead--none>
                            Save up to $210 with HelloFresh
                        </Text>
                        <SolidButton
                            type="anchor"
                            target="_blank"
                            href="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=hello-fresh-au&mtm_medium=viprewards&siteId=8&redirect=https://www.hellofresh.com.au/pages/inspiration-delivered?c=VENTRAIP"
                            className="helloFreshAULightbox__button"
                        >
                            Claim Now
                        </SolidButton>
                    </Flex>
                    <Padding top={5} bottom={2}>
                        <Flex justify="center">
                            <button onClick={() => setIsModalOpen(false)}>
                                <Text color="S_color_text_info_base" lead--none size--s lead--0 align--center className="helloFreshAULightbox__link">
                                    Go Back
                                </Text>
                            </button>
                        </Flex>
                    </Padding>
                    <Flex justify="center">
                        <Padding top={2}>
                            <Text size--xs color="S_color_text_secondary_base" align--center className="helloFreshAULightbox__tcsText">
                                *Discount excludes upgrades and add-ons. By using this voucher, you will be signed up to an ongoing weekly
                                subscription. Not valid in conjunction with any other offer. Full T&Cs available online. Discount is only valid for
                                new customers and select past customers who have cancelled their subscription more than 6months ago. Subject to the
                                voucher being utilised within 63 days of your subscription.
                            </Text>
                        </Padding>
                    </Flex>
                </Padding>
            </Modal>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <RewardCard>
                <RewardCard.Image tag="SPECIAL OFFER">
                    <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                        <img src={helloFreshImage} alt="Hello fresh box sitting on a table" width={278} />
                    </div>
                </RewardCard.Image>
                <RewardCard.Body
                    title="Save up to $210 with HelloFresh"
                    description="HelloFresh delivers fresh, seasonal ingredients and delicious recipes to your door every week, so you can cook wholesome and satisfying meals everyone will love."
                >
                    <Padding top={5}>
                        <SolidButton color="primary" type="onClick" onClick={() => setIsModalOpen(true)}>
                            Claim Now
                        </SolidButton>
                    </Padding>
                </RewardCard.Body>
            </RewardCard>
            {/* renders on promo tile button click */}
            {renderLightBox()}
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
