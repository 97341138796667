import { renderForBrands } from 'config/brandRenderer/helper';
import type { BrandName } from 'config/hooks/useBrandStore';
import type { ReactNode } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RenderForBrandsProps = {
    children: ReactNode;
    brands: BrandName[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function RenderForBrands({ children, brands }: RenderForBrandsProps) {
    /***** RENDER *****/
    return renderForBrands(brands) ? children : null;
}
