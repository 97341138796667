// The background color thing is just a hack until I can figure out how to properly do "mask", to make the curved lines take whatever background is behind the gradient square.
export const intaserveSvg = (backgroundColor: string) => (
    <svg width="100%" height="100%" viewBox="0 0 660 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="130" y="130" width="400" height="400" rx="10" fill="url(#paint0_linear_17_25)" />
        <path d="M130 48.89C281.304 281.651 290.249 410.35 170.082 637.558" stroke={`var(--${backgroundColor})`} strokeWidth="35" />
        <path d="M31 429.893C293.24 338.785 420.332 360.946 612.117 532.066" stroke={`var(--${backgroundColor})`} strokeWidth="35" />
        <path d="M167 111.535C431.638 195.424 520.217 289.217 571.515 541.074" stroke={`var(--${backgroundColor})`} strokeWidth="35" />
        <defs>
            <linearGradient id="paint0_linear_17_25" x1="130" y1="530" x2="530" y2="130" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1256FA" />
                <stop offset="1" stopColor="#36F7CD" />
                <animateTransform
                    attributeName="gradientTransform"
                    attributeType="XML"
                    type="rotate"
                    from="0 150 100"
                    to="360 150 100"
                    dur="2s"
                    repeatCount="indefinite"
                />
            </linearGradient>
        </defs>
    </svg>
);
